import React from 'react'

import PropTypes from 'prop-types'
import {
  Box,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  CloseButton,
} from '@chakra-ui/react'
import useSWR from 'swr'

import {
  DataTable,
  InvoiceStatusLabel,
  ThemeProvider,
  Payment,
} from '../../components'
import {retrieveInvoicePaymentInfo} from '../../../api'
import {
  toLocalDate,
  toCurrency,
  toEpochMillisecond,
} from '../../../utils/formatter'
import {InvoicesIcon} from '../../icons'

import RailsFlashMessages from '../../components/RailsFlashMessages'

import withSWR from '../../contexts/withSWR'
import {AuthProvider} from '../../contexts/authentication'
import SideNavLayout from '../../layouts/SideNavLayout'

const columns = [
  {header: 'Supplier', accessorKey: 'supplierName'},
  {header: 'Invoice', accessorKey: 'invoice'},
  {header: 'Total', accessorKey: 'total'},
  {header: 'Due Amount', accessorKey: 'dueAmount'},
  {
    header: 'Due date',
    accessorKey: 'dueDate',
    // eslint-disable-next-line react/prop-types
    cell: ({getValue}) => (
      <Text>{toLocalDate(toEpochMillisecond(getValue()))}</Text>
    ),
  },
  {
    header: 'Status',
    accessorKey: 'statusLabel',
    cell: InvoiceStatusLabel,
  },
]

const CustomerInvoiceScreen = ({authenticityToken, flashMessages}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [invoiceDrawerContent, setInvoiceDrawerContent] = React.useState({})
  const [displaySuccessPayment, setDisplaySuccessPayment] =
    React.useState(false)

  const toggleDrawerClose = React.useCallback(() => {
    setIsOpen(!isOpen)
    setInvoiceDrawerContent({})
    setDisplaySuccessPayment(false)
  }, [isOpen])

  // call api to fetch payment information.
  const onClickInvoiceRow = (value) => {
    const {xeroInvoiceUUID, statusLabel} = value
    if (xeroInvoiceUUID) {
      retrieveInvoicePaymentInfo({xeroInvoiceUUID}).then((data) => {
        if (data.status === 'not_allowed') {
          return
        }

        const drawerContent = {
          xeroInvoiceUUID,
          statusLabel,
          amount: toCurrency(data.invoice.amount.cents),
          dueAmount: toCurrency(data.invoice.dueAmount.cents),
          appliedCreditNoteAmount: toCurrency(
            data.invoice.appliedCreditNoteAmount.cents,
          ),
          invoicePaymentsAmount: toCurrency(
            data.invoice.invoicePaymentsAmount.cents,
          ),
          number: data.invoice.number,
          dueDateFormatted: toLocalDate(
            toEpochMillisecond(data.invoice.dueDate),
          ),
          supplierName: data.invoice.supplierName,
        }
        setInvoiceDrawerContent(drawerContent)
        setIsOpen(true)
      })
    }
  }

  const onSuccessCC = () => {
    setDisplaySuccessPayment(true)
  }

  const onSuccessDD = (result) => {
    if (result && result.success) {
      setDisplaySuccessPayment(true)
    }
  }

  const {data, error} = useSWR('/api/customers/invoices')

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/customer_logins/sign_in'
  }

  const mappedData =
    data && Array.isArray(data.invoices)
      ? data.invoices.map((invoice) => ({
          xeroInvoiceUUID: invoice.xero_invoice_uuid,
          id: invoice.id,
          supplierName: invoice.supplier,
          invoice: invoice.number,
          total: toCurrency(invoice.total.cents),
          dueAmount: toCurrency(invoice.due_amount.cents),
          dueDate: invoice.due_date,
          statusLabel: invoice.status_label,
        }))
      : []

  const generalLinks = [
    {
      disabled: false,
      active: true,
      label: 'Invoices',
      icon: <InvoicesIcon />,
      href: '/customer-invoices',
    },
  ]

  return (
    <AuthProvider authenticityToken={authenticityToken}>
      <ThemeProvider>
        <SideNavLayout
          authenticityToken={authenticityToken}
          generalLinks={generalLinks}
          signOutAction="/customer_logins/sign_out"
        >
          <RailsFlashMessages flashMessages={flashMessages} />
          <Box display="flex" flexDirection="column" height="100vh">
            <Text
              as="h2"
              fontSize="24px"
              fontWeight="bold"
              ml="md"
              pt="sm"
              mb="xs"
            >
              Invoices
            </Text>

            <Box flexGrow="1" overflow="scroll" whiteSpace="nowrap">
              {error &&
                error.message !== 'Unauthorized' &&
                error.name !== 'APINetworkError' && (
                  <Text ml="md">Error in fetching invoices</Text>
                )}
              <DataTable
                columns={columns}
                data={mappedData}
                onClickRow={onClickInvoiceRow}
                enableSorting
              />
            </Box>
          </Box>
          <Drawer
            isOpen={isOpen}
            placement="right"
            size="md"
            onClose={toggleDrawerClose}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid"
                  borderColor="neutral.glitter"
                  py="xs"
                >
                  <Text flexGrow="1" fontSize="md">
                    {invoiceDrawerContent.number}
                  </Text>
                  <CloseButton
                    onClick={toggleDrawerClose}
                    fontSize="10px"
                    flexGrow="0"
                    color="neutral.black"
                    backgroundColor="transparent"
                    _hover={{backgroundColor: 'neutral.smoke'}}
                    _focus={{boxShadow: '0 0 0 3px rgba(73,70,233,0.25)'}}
                  />
                </Box>
              </DrawerHeader>
              <DrawerBody overflowY="scroll">
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid"
                  borderColor="neutral.glitter"
                  pb="sm"
                  mb="sm"
                >
                  <Text>{invoiceDrawerContent.supplierName}</Text>
                  <Text fontWeight="bold">{invoiceDrawerContent.amount}</Text>
                </Box>
                {invoiceDrawerContent.statusLabel !== 'paid' &&
                  invoiceDrawerContent.dueAmount !==
                    invoiceDrawerContent.amount && (
                    <>
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom="1px solid"
                        borderColor="neutral.glitter"
                        pb="sm"
                        mb="sm"
                      >
                        <Text>Credit Notes</Text>
                        <Text fontWeight="bold">
                          ({invoiceDrawerContent.appliedCreditNoteAmount})
                        </Text>
                      </Box>
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom="1px solid"
                        borderColor="neutral.glitter"
                        pb="sm"
                        mb="sm"
                      >
                        <Text>Less Payments</Text>
                        <Text fontWeight="bold">
                          ({invoiceDrawerContent.invoicePaymentsAmount})
                        </Text>
                      </Box>
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom="1px solid"
                        borderColor="neutral.glitter"
                        pb="sm"
                        mb="sm"
                      >
                        <Text>Due Amount</Text>
                        <Text fontWeight="bold">
                          {invoiceDrawerContent.dueAmount}
                        </Text>
                      </Box>
                    </>
                  )}
                <Box mb="lg" width="100%">
                  <Text fontWeight="bold">Due date</Text>
                  <Text>{invoiceDrawerContent.dueDateFormatted}</Text>
                </Box>
                <Text
                  fontWeight="bold"
                  pb="sm"
                  borderBottom="1px solid"
                  borderColor="neutral.glitter"
                >
                  {invoiceDrawerContent.statusLabel === 'Paid' &&
                    'Invoice Paid'}
                  {invoiceDrawerContent.statusLabel === 'Pending' &&
                    'Invoice Payment Pending'}
                  {invoiceDrawerContent.statusLabel === 'Unpaid' && 'Pay now'}
                  {invoiceDrawerContent.statusLabel === 'Overdue' && 'Pay now'}
                </Text>
                {displaySuccessPayment &&
                  invoiceDrawerContent.statusLabel !== 'Paid' && (
                    <Text mt="xs">Payment was successful</Text>
                  )}

                {!displaySuccessPayment &&
                  invoiceDrawerContent.statusLabel !== 'Pending' &&
                  invoiceDrawerContent.statusLabel !== 'Paid' &&
                  invoiceDrawerContent.xeroInvoiceUUID && (
                    <Payment
                      onSuccessCC={onSuccessCC}
                      onSuccessDD={onSuccessDD}
                      xeroInvoiceUUID={invoiceDrawerContent.xeroInvoiceUUID}
                      authenticityToken={authenticityToken}
                    />
                  )}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </SideNavLayout>
      </ThemeProvider>
    </AuthProvider>
  )
}

CustomerInvoiceScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  flashMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default withSWR(CustomerInvoiceScreen)
