import APINetworkError from './APINetworkError'

const defaultHeaders = ({authenticityToken}) => {
  return {
    'X-CSRF-Token': authenticityToken,
    'Content-Type': 'application/json',
  }
}

const getHeaders = () => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }
}

const postHeaders = ({authenticityToken, body}) => {
  return {
    method: 'POST',
    headers: defaultHeaders({authenticityToken}),
    body: JSON.stringify(body),
  }
}

const fetcher = async (...args) => {
  try {
    const res = await fetch(...args)

    const result = await res.json()

    if (res.status >= 400) {
      const err = new Error(
        result.error ? result.error.message : res.statusText,
      )
      err.status = res.status
      throw err
    }
    return result
  } catch (e) {
    // This would normally throw TypeError which mostly relates to
    // - CORS (less likely to occur)
    // - Network request abortion during page transition (big possibility when using SWR)
    // Hence, adding custom error implementation for distinct error handling
    if (e.name === 'SyntaxError') {
      throw new APINetworkError('An error occurred.')
    } else {
      throw new APINetworkError(e.message)
    }
  }
}

export {fetcher, defaultHeaders, postHeaders, getHeaders}
