import React from 'react'
import {Flex, Text} from '@chakra-ui/react'
import Skeleton from '../Skeleton'
import VerticalStepper from '../VerticalStepper'
import useStepperData from './useStepperData'

const CashinStepper = () => {
  const {isLoading, cashinSteps, currentStep} = useStepperData()

  if (isLoading) {
    return (
      <Skeleton
        data-testid="cashin-stepper-skeleton-loader"
        minHeight="200px"
      />
    )
  }

  return (
    <Flex bg="transparent" direction="column" id="cashinStepper">
      <Text as="h6" textStyle="body-intro-medium" fontWeight="bold" pb="sm">
        {`${cashinSteps.length} steps to setup `}&#127881;
      </Text>

      <VerticalStepper steps={cashinSteps} currentStep={currentStep} />
    </Flex>
  )
}

export default CashinStepper
