import React from 'react'
import useSWR from 'swr'
import {Box, Text, Link, useDisclosure} from '@chakra-ui/react'
import {useRails} from '../../../../contexts/rails'
import {fetcher} from '../../../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../../../api/serializer'
import ReimbursementRequiredInvoicesModal from '../../../../components/ReimbursementRequiredInvoicesModal'
import {Alert} from '../../../../components'

const ReimbursementRequiredBanner = () => {
  const {organisation} = useRails()
  const {data, isLoading} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/reimbursement_required`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )
  const reimbursementRequiredDisclosure = useDisclosure()

  if (isLoading || data.reimbursementRequiredAmount.getAmount() === 0) {
    return null
  }

  return (
    <>
      <ReimbursementRequiredInvoicesModal
        {...reimbursementRequiredDisclosure}
      />

      <Alert
        data-testid="reimbursement-required-banner"
        isDismissable
        message={
          <Box>
            <Text fontWeight="bold">
              You owe Marmalade {data.reimbursementRequiredAmount.toFormat()}
            </Text>

            <Text mb="xs">
              You have outstanding funds that require settlement. This amount
              will be deducted from the next invoice(s) you Cash-in.
            </Text>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              as="button"
              onClick={reimbursementRequiredDisclosure.onOpen}
              fontWeight="bold"
              textDecoration="underline"
            >
              View invoices
            </Link>
          </Box>
        }
      />
    </>
  )
}

export default ReimbursementRequiredBanner
