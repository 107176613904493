import {toast} from 'react-toastify'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Box,
  Text,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Button,
  CloseButton,
} from '@chakra-ui/react'

import {createInvoiceIssue, createPayerArrangment} from '../../../api'
import {ToastMessage} from '../Toast'
import PayerArrangementDetailsForm from './PayerArrangementDetailsForm'

import {useAuthProvider} from '../../contexts/authentication'
import {RadioCard, RadioCardGroup} from '../RadioCard'

const Step = {
  success: 'success',
  initial: 'initial',
  details: 'details',
}

const InvoiceIssueType = {
  dispute: 'dispute',
  fictitious_invoice: 'fictitious_invoice',
  invoice_change: 'invoice_change',
  customer_promised_to_pay: 'customer_promised_to_pay',
  marmalade_can_contact: 'marmalade_can_contact',
  supplier_accepted_liability_payable: 'supplier_accepted_liability_payable',
}

export const PayerArrangementType = {
  customer_promised_to_pay: 'customer_promised_to_pay',
  marmalade_can_contact: 'marmalade_can_contact',
}

const AddOverdueDetailsModal = ({invoice, isOpen, onClose, beforeClose}) => {
  const {authenticityToken} = useAuthProvider()

  const [step, setStep] = React.useState(Step.initial)
  const [selectedType, setSelectedType] = React.useState()

  const handleClose = () => {
    if (beforeClose) beforeClose()
    onClose()
    setStep(Step.initial)
  }

  const handleError = () => {
    toast.error(
      <ToastMessage>
        Unable to add details for <strong>{invoice.number}</strong>. Please try
        again later.
      </ToastMessage>,
    )
  }

  const addInvoiceIssue = async (type) => {
    try {
      await createInvoiceIssue({
        type,
        invoiceId: invoice.id,
        authenticityToken,
      })

      setStep(Step.success)
    } catch {
      handleError()
    }
  }

  const addPayerArrangment = async (values) => {
    try {
      await createPayerArrangment({
        ...values,
        invoiceId: invoice.id,
        authenticityToken,
      })

      setStep(Step.success)
    } catch {
      handleError()
    }
  }

  const handleClick = () => {
    if (Object.values(PayerArrangementType).includes(selectedType)) {
      setStep(Step.details)
    } else {
      addInvoiceIssue(selectedType)
    }
  }

  return (
    <Modal isOpen={isOpen} size="xl" onClose={handleClose} preserveScrollBarGap>
      <ModalOverlay zIndex="modal" />
      <ModalContent borderRadius="lg" py="sm">
        <ModalHeader fontSize="lg">
          <Stack isInline justifyContent="space-between">
            <>What should we know about {invoice.number}?</>
            <CloseButton onClick={handleClose} />
          </Stack>
        </ModalHeader>

        <ModalBody>
          <Stack spacing="sm">
            <HStack
              spacing="lg"
              pb="sm"
              borderBottom="1px"
              borderColor="neutral.glitter"
            >
              <Box>
                <Text fontWeight="bold">Customer</Text>
                <Text color="neutral.60">{invoice.contactName}</Text>
              </Box>

              <Box>
                <Text fontWeight="bold">Due amount</Text>
                <Text color="neutral.60">{invoice.dueAmount}</Text>
              </Box>
            </HStack>

            {step === Step.initial && (
              <>
                <Text fontWeight="bold">
                  Please select from below the reasons your customers are not
                  paying on time, so we can support you to get this rectified.
                </Text>

                <RadioCardGroup
                  name="selectedType"
                  value={selectedType}
                  onChange={(event) => setSelectedType(event.target.value)}
                >
                  <RadioCard
                    compact
                    align="left"
                    value={InvoiceIssueType.dispute}
                  >
                    My customer has issues with the goods / services provided
                  </RadioCard>

                  <RadioCard
                    compact
                    align="left"
                    value={InvoiceIssueType.fictitious_invoice}
                  >
                    Items not delivered / services not completed
                  </RadioCard>

                  <RadioCard
                    compact
                    align="left"
                    value={InvoiceIssueType.invoice_change}
                  >
                    Error on original invoice
                  </RadioCard>

                  <RadioCard
                    compact
                    align="left"
                    value={PayerArrangementType.customer_promised_to_pay}
                  >
                    My customer has promised to pay
                  </RadioCard>

                  <RadioCard
                    compact
                    align="left"
                    value={PayerArrangementType.marmalade_can_contact}
                  >
                    Marmalade can contact my customer on my behalf
                  </RadioCard>

                  <RadioCard
                    compact
                    align="left"
                    value={InvoiceIssueType.supplier_accepted_liability_payable}
                  >
                    I&apos;ll settle this invoice on behalf of my customer
                  </RadioCard>
                </RadioCardGroup>

                <Box pt="xs">
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={handleClick}
                    isDisabled={!selectedType}
                  >
                    Confirm
                  </Button>
                </Box>
              </>
            )}

            {step === Step.details && (
              <PayerArrangementDetailsForm
                onSubmit={addPayerArrangment}
                onBack={() => setStep(Step.initial)}
                type={selectedType}
              />
            )}

            {step === Step.success && (
              <>
                <Text>
                  {selectedType === PayerArrangementType.marmalade_can_contact
                    ? 'Thanks for letting us know. Once we hear back from your cutomer, we will update you on the status of payment.'
                    : 'Thanks for letting us know, we’ll verify the information you provided.'}
                </Text>

                <Box pt="lg">
                  <Button variant="secondary" size="lg" onClick={handleClose}>
                    Done
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

AddOverdueDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  beforeClose: PropTypes.func,
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    contactName: PropTypes.string.isRequired,
    dueAmount: PropTypes.string.isRequired,
  }).isRequired,
}

AddOverdueDetailsModal.defaultProps = {
  beforeClose: null,
}

export default AddOverdueDetailsModal
