import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Flex,
  Link,
  ListItem,
  UnorderedList,
  Button,
} from '@chakra-ui/react'

import ConnectionError from './ConnectionErrorMessage'

const LearnMoreView = ({isLoading, startConnecting, tokenLoaded}) => {
  return (
    <Box>
      <Text textStyle="body-intro-medium" pb="md">
        Connect your bank account to Marmalade securely
      </Text>
      <Text pb="md">
        Connecting your bank account helps us verify business transactions,
        which will help us better support your business and enable smooth
        payment processes.
      </Text>

      <Accordion defaultIndex={[0]} mb="lg" allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text fontWeight="bold">
                How does bank connection data and verification work?
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text>
              We partnered with{' '}
              <Link
                color="primary.actionblue"
                href="https://www.yodlee.com"
                rel="nofollow"
                target="_blank"
                textDecoration="underline"
                _hover={{textDecoration: 'none'}}
              >
                Yodlee
              </Link>
              , a world leader in banking technology, to link your bank accounts
              securely. Marmalade does not store your login credentials, and all
              sensitive financial data is encrypted and protected by Yodlee.
              Yodlee is compliant with{' '}
              <Link
                color="primary.actionblue"
                href="https://treasury.gov.au/policy-topics/economy/consumer-data-right"
                rel="nofollow"
                target="_blank"
                textDecoration="underline"
                _hover={{textDecoration: 'none'}}
              >
                Australia&apos;s Consumer Data Right
              </Link>
              . You can learn more about Yodlee&apos;s security practices{' '}
              <Link
                color="primary.actionblue"
                href="https://www.yodlee.com/au/company/secure-data"
                rel="nofollow"
                target="_blank"
                textDecoration="underline"
                _hover={{textDecoration: 'none'}}
              >
                here
              </Link>
              .
            </Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <Text>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="bold">
                  How does Marmalade access and use my business transactions?
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Text>
          <AccordionPanel pb={4}>
            <Text>
              At Marmalade, we take your security seriously. We have partnered
              with Yodlee, a global leader in the secure hosting and encryption
              of financial data that uses bank-grade security and data
              encryption services. Yodlee is used by many major intentional
              banks and trusted by over 70 million users worldwide.
            </Text>

            <Text>It is important to note:</Text>
            <UnorderedList>
              <ListItem>
                Marmalade cannot make any changes to your financial accounts
              </ListItem>
              <ListItem>
                Marmalade cannot approve payments or fund transfers
              </ListItem>
              <ListItem>
                Marmalade does not store your login credentials
              </ListItem>
              <ListItem>
                We can only use your data to verify bank ownership and to
                understand how Marmalade can support your business.
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="bold">
                  What happens if I disconnect my bank account from Marmalade?
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              If you disconnect the bank connection, we will stop collecting
              data from your bank accounts, and upon written request, we will
              delete the data we collected from the accounts, unless the
              retention of this information is mandated by law.
            </Text>

            <Text>
              Once disconnected, we will be limited in our ability to support
              you with features like Cash-in.
            </Text>

            <Text>
              If you wish to disconnect your bank accounts from Marmalade,
              please contact us at{' '}
              <Link
                color="primary.actionblue"
                href="mailto:support@withmarmalade.com"
                textDecoration="underline"
                _hover={{textDecoration: 'none'}}
              >
                support@withmarmalade.com
              </Link>
              .
            </Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="bold">
                  Which bank account should I connect with Marmalade?
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              If the account you nominated to receive payments into is not your
              primary business transaction account, we&apos;ll need you to also
              connect your primary business transaction account.
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Flex mb="sm" justifyContent="center">
        {isLoading && (
          <Button variant="primary" isLoading minW="70%">
            Connect bank account now
          </Button>
        )}

        {!isLoading && tokenLoaded && (
          <Button variant="primary" onClick={startConnecting} minW="70%">
            Connect bank account now
          </Button>
        )}
      </Flex>

      {!isLoading && !tokenLoaded && <ConnectionError />}
    </Box>
  )
}

LearnMoreView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tokenLoaded: PropTypes.bool.isRequired,
  startConnecting: PropTypes.func.isRequired,
}

export default LearnMoreView
