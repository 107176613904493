import React from 'react'
import {Box, Link, Text} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

export const SupplierSignInOption = ({link}) => {
  const {t} = useTranslation()
  return (
    <SignInOption
      description={t('signInPage:description', {
        context: 'supplier_login',
      })}
      signInPath={link && '/supplier_logins/sign_in'}
      title={t('signInPage:title', {
        context: 'supplier_login',
      })}
    />
  )
}

SupplierSignInOption.propTypes = {
  link: PropTypes.bool,
}

SupplierSignInOption.defaultProps = {
  link: null,
}

export const CustomerSignInOption = ({link}) => {
  const {t} = useTranslation()

  return (
    <SignInOption
      description={t('signInPage:description', {
        context: 'customer_login',
      })}
      signInPath={link && '/customer_logins/sign_in'}
      title={t('signInPage:title', {
        context: 'customer_login',
      })}
    />
  )
}

CustomerSignInOption.propTypes = {
  link: PropTypes.bool,
}

CustomerSignInOption.defaultProps = {
  link: null,
}

const SignInOption = ({description, signInPath, title}) => {
  const card = (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      mb="sm"
      borderWidth="1px"
      borderRadius="md"
      backgroundColor="mld.neutral.900"
      color="mld.neutral.50"
      p="20px"
      _hover={{backgroundColor: 'mld.neutral.800'}}
    >
      <Box w="100%">
        <Text textStyle="headline3" pb="16px">
          {title}
        </Text>
        <Text textStyle="body-copy">{description}</Text>
      </Box>
    </Box>
  )

  if (signInPath) {
    return (
      <Link href={signInPath} display="block" _hover={{textDecoration: 'none'}}>
        {card}
      </Link>
    )
  }

  return card
}

SignInOption.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  signInPath: PropTypes.string,
}

SignInOption.defaultProps = {
  signInPath: '',
}
