import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text} from '@chakra-ui/react'
import {get} from 'lodash-es'
import {toLocalDate, toEpochMillisecond} from '../../../utils/formatter'
import Form from './Form'

const CustomerDetailsForm = ({
  selectedInvoice,
  customer,
  onClickSubmit,
  displayForm,
  abnRequired,
}) => {
  return (
    <>
      <Box display="flex" mb="md">
        <Box width="2xs">
          <Text textStyle="body-small" mb="xxs">
            Customer
          </Text>
          <Text textStyle="body-copy" fontWeight="bold">
            {get(customer, 'business_name')}
          </Text>
        </Box>
        <Box>
          <Text textStyle="body-small" mb="xxs">
            Due Date
          </Text>
          <Text textStyle="body-copy" fontWeight="bold">
            {toLocalDate(toEpochMillisecond(selectedInvoice.due_date))}
          </Text>
        </Box>
      </Box>
      {!displayForm && (
        <>
          <Box display="flex" mb="md">
            <Box width="2xs">
              <Text textStyle="body-small" mb="xxs">
                Invoice
              </Text>
              <Text textStyle="body-copy" fontWeight="bold">
                {selectedInvoice.number}
              </Text>
            </Box>
            <Box>
              <Text textStyle="body-small" mb="xxs">
                ABN
              </Text>
              <Text textStyle="body-copy" fontWeight="bold">
                {get(customer, 'abn')}
              </Text>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="md">
            <Box width="2xs">
              <Text textStyle="body-small" mb="xxs">
                Email
              </Text>
              <Text textStyle="body-copy" fontWeight="bold">
                {get(customer, 'email')}
              </Text>
            </Box>
          </Box>
        </>
      )}
      {displayForm && (
        <>
          <Text textStyle="body-copy" fontWeight="bold" mb="xxs">
            Details required
          </Text>
          {customer && (
            <Form
              onClickSubmit={onClickSubmit}
              customer={customer}
              abnRequired={abnRequired}
            />
          )}
        </>
      )}
    </>
  )
}

CustomerDetailsForm.defaultProps = {
  onClickSubmit: () => {},
  displayForm: false,
  abnRequired: false,
}

CustomerDetailsForm.propTypes = {
  selectedInvoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    due_date: PropTypes.number.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    businessName: PropTypes.string,
    abn: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  onClickSubmit: PropTypes.func,
  displayForm: PropTypes.bool,
  abnRequired: PropTypes.bool,
}

export default CustomerDetailsForm
