import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '../css/application.css'
import mixpanel from 'mixpanel-browser'
import ReactComponent from '../react'

import CustomerInformPaymentNotFoundScreen from '../react/screens/CustomerInformPaymentScreen/NotFound'
import CustomerInformPaymentScreen from '../react/screens/CustomerInformPaymentScreen'
import CustomerInvoiceScreen from '../react/screens/CustomerInvoiceScreen'
import CustomerPaymentScreen from '../react/screens/CustomerPaymentScreen'
import LoadingCustomerPaymentScreen from '../react/screens/LoadingCustomerPaymentScreen'
import PayerStatementScreen from '../react/screens/PayerStatementScreen'
import DashboardSupplierScreen from '../react/screens/DashboardScreen/Supplier'
import DashboardFailureScreen from '../react/screens/DashboardScreen/Failure'
import DisconnectionSupplierScreen from '../react/screens/DisconnectionScreen/Supplier'
import ResendSignUpConfirmationScreen from '../react/screens/ResendSignUpConfirmationScreen'
import ResendUnlockScreen from '../react/screens/ResendUnlockScreen'
import PayerStatementResentScreen from '../react/screens/PayerStatementResentScreen'
import ResetPasswordScreen from '../react/screens/ResetPasswordScreen'
import SignInScreen from '../react/screens/SignInScreen'
import SignInSelectScreen from '../react/screens/SignInSelectScreen'
import SignUpConfirmationScreen from '../react/screens/SignUpConfirmationScreen'
import SignUpContactUsScreen from '../react/screens/SignUpContactUsScreen'
import SignUpScreen from '../react/screens/SignUpScreen'
import NewSupplierCashInScreen from '../react/screens/NewSupplierCashInScreen'
import SupplierCustomersScreen from '../react/screens/SupplierCustomersScreen'
import SupplierCustomerScreen from '../react/screens/SupplierCustomerScreen'
import SupplierDirectPaymentsScreen from '../react/screens/SupplierDirectPaymentsScreen'
import SupplierInvoiceScreen from '../react/screens/SupplierInvoiceScreen'
import SupplierSettingsScreen from '../react/screens/SupplierSettingsScreen'
import SupplierPaymentsScreen from '../react/screens/SupplierPaymentsScreen'
import UpdatePasswordScreen from '../react/screens/UpdatePasswordScreen'
import {
  SupplierRequestClosureScreen,
  SupplierRequestClosureConfirmationScreen,
} from '../react/screens/SupplierRequestClosure'

import setDineroDefaults from '../utils/dineroDefaults'

const DEVELOPMENT = import.meta.env.MODE === 'development'

if (import.meta.env.VITE_MIXPANEL_TOKEN) {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {debug: DEVELOPMENT})
}

setDineroDefaults()

ReactComponent.setup({
  CustomerInformPaymentNotFoundScreen,
  CustomerInformPaymentScreen,
  CustomerInvoiceScreen,
  CustomerPaymentScreen,
  LoadingCustomerPaymentScreen,
  PayerStatementScreen,
  DashboardSupplierScreen,
  DashboardFailureScreen,
  DisconnectionSupplierScreen,
  ResendSignUpConfirmationScreen,
  ResendUnlockScreen,
  ResetPasswordScreen,
  PayerStatementResentScreen,
  SignInScreen,
  SignInSelectScreen,
  SignUpConfirmationScreen,
  SignUpContactUsScreen,
  SignUpScreen,
  NewSupplierCashInScreen,
  SupplierCustomersScreen,
  SupplierCustomerScreen,
  SupplierDirectPaymentsScreen,
  SupplierInvoiceScreen,
  SupplierRequestClosureScreen,
  SupplierRequestClosureConfirmationScreen,
  SupplierSettingsScreen,
  SupplierPaymentsScreen,
  UpdatePasswordScreen,
})
