import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerContent,
  Link,
  Button,
} from '@chakra-ui/react'
import {Alert} from '../../../components'

const BankAccountSettingDrawer = ({displayDrawer, onClickCancel}) => {
  return (
    <Drawer
      isOpen={displayDrawer}
      placement="right"
      size="md"
      onClose={() => {
        onClickCancel()
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="xs"
          >
            <Text flexGrow="1" fontSize="md" fontWeight="bold">
              Remittance Account
            </Text>
          </Box>
        </DrawerHeader>
        <DrawerBody overflowY="scroll">
          <Alert
            status="error"
            variant="left-accent"
            mb={4}
            message={
              <span>
                To protect our payments to you, bank account changes must be
                requested in writing by an authorised person from your business
                (i.e. the person who signed the Marmalade Supplier Agreement).
                <br />
                Please email Support at{' '}
                <Link
                  href="mailto:support@withmarmalade.com"
                  color="secondary.blue"
                >
                  support@withmarmalade.com
                </Link>{' '}
                to request a bank account change and allow 1-2 business days for
                the account change to be processed and verified.
              </span>
            }
          />
        </DrawerBody>
        <DrawerFooter>
          <Button
            variant="secondary"
            size="lg"
            mr="sm"
            onClick={() => {
              onClickCancel()
            }}
          >
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

BankAccountSettingDrawer.defaultProps = {
  displayDrawer: false,
  onClickCancel: () => {},
}

BankAccountSettingDrawer.propTypes = {
  displayDrawer: PropTypes.bool,
  onClickCancel: PropTypes.func,
}

export default BankAccountSettingDrawer
