import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {
  Box,
  CloseButton,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  Divider,
} from '@chakra-ui/react'
import {get} from 'lodash-es'

import {CopyButton, Skeleton, Input} from '../../components'
import CancelCashIn from './CancelCashIn'
import InvoiceTimeline from './InvoiceTimeline'
import ReportDirectPayment from './ReportDirectPayment'
import OverdueDetails from './OverdueDetails'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import AllocateCreditNotePanel from '../../components/AllocateCreditNotePanel'
import InvoiceDetails from '../../components/InvoiceDetails'

const InvoiceDrawer = ({isOpen, onClose, invoice, mutateList}) => {
  const paymentLink = `${window.location.origin}/pay/${invoice.xeroInvoiceUuid}`
  const {
    data: {features},
    loading: loadingFeatures,
  } = useFeatureFlags()

  const overdueInvoiceInfoEnabled = features && features.overdueInvoiceInfo
  const creditNotesEnabled = features && features.creditNotes
  const {data, error, mutate} = useSWR(`/api/invoices/${invoice.id}/timeline`)
  const loading = (!data && !error) || loadingFeatures

  const timeline = get(data, 'timeline')
  const canCancelCashIn = get(data, 'can_cancel_cash_in')
  const canReportDirectPayment = get(data, 'can_report_direct_payment')
  const overdueMessage = get(data, 'overdue_message')
  const cashedIn = get(data, 'cashed_in')

  return (
    <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
      <DrawerOverlay />

      <DrawerContent>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor="neutral.glitter"
            py="xs"
          >
            <Text fontSize="md">{invoice.number}</Text>

            <CloseButton onClick={onClose} />
          </Box>
        </DrawerHeader>

        <DrawerBody overflowY="scroll">
          <InvoiceDetails invoiceId={invoice.id} />

          <Divider mb="md" />

          {creditNotesEnabled && (
            <Box borderBottom="1px solid" borderColor="neutral.glitter" mb="sm">
              <Flex pb="sm" justify="space-between" align="center">
                <Text>Credit Note</Text>
                <AllocateCreditNotePanel
                  invoiceId={invoice.id}
                  onClose={onClose}
                />
              </Flex>
            </Box>
          )}

          <Box borderBottom="1px solid" borderColor="neutral.glitter" mb="sm">
            <Text textStyle="body-small" fontWeight="bold" mb="sm">
              Payment link
            </Text>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Input
                name="payment link"
                placeholder="Invoice payment link"
                value={paymentLink}
                overflowX="scroll"
                isReadOnly
              />

              <Box mb="sm">
                <CopyButton
                  ml="xs"
                  color="neutral.black"
                  label="Copy payment link"
                  content={paymentLink}
                />
              </Box>
            </Box>

            {overdueInvoiceInfoEnabled && invoice.overDue && (
              <OverdueDetails
                invoice={invoice}
                closeDrawer={onClose}
                mutateList={mutateList}
                overdueMessage={overdueMessage}
              />
            )}

            <ReportDirectPayment
              canReportDirectPayment={canReportDirectPayment}
              cashedIn={cashedIn}
              contactName={invoice.contactName}
              invoiceId={invoice.id}
              invoiceNumber={invoice.number}
              dueAmount={invoice.dueAmount}
              updateInvoiceTimeline={mutate}
            />
          </Box>

          <Skeleton isLoaded={!loading}>
            {error && (
              <Text mb="sm">
                Unable to fetch invoice timeline, please try again later.
              </Text>
            )}

            {canCancelCashIn && (
              <CancelCashIn
                invoiceNumber={invoice.number}
                xeroInvoiceUuid={invoice.xeroInvoiceUuid}
                updateInvoiceTimeline={mutate}
              />
            )}

            <InvoiceTimeline timeline={timeline} />
          </Skeleton>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

InvoiceDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mutateList: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    cashinStatus: PropTypes.bool.isRequired,
    xeroInvoiceUuid: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    contactName: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    dueAmount: PropTypes.string.isRequired,
    overDueDays: PropTypes.number.isRequired,
    overDue: PropTypes.bool.isRequired,
  }).isRequired,
}

export default InvoiceDrawer
