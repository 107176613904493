import React, {cloneElement} from 'react'
import PropTypes from 'prop-types'
import {Flex, Text, SimpleGrid, Box} from '@chakra-ui/react'

export const CardNumber = ({value}) => (
  <Flex
    backgroundColor="primary.yellow"
    borderRadius="full"
    mr="sm"
    minH="8"
    minW="8"
    justifyContent="center"
    alignItems="center"
  >
    <Text fontWeight="bold">{value}</Text>
  </Flex>
)

CardNumber.propTypes = {
  value: PropTypes.number.isRequired,
}

export const NumberedCard = ({children, number}) => {
  return (
    <Flex
      alignItems="center"
      borderColor="primary.actionblue"
      borderRadius="md"
      borderWidth="1px"
      py="xs"
      px="sm"
      justifyContent="left"
      w="100%"
      data-testid={`list-card-${number}`}
    >
      <CardNumber value={number} />
      <Box>{children}</Box>
    </Flex>
  )
}

NumberedCard.defaultProps = {
  number: null,
}

NumberedCard.propTypes = {
  children: PropTypes.node.isRequired,
  number: PropTypes.node,
}

export const NumberedCardList = ({columns, children, ...props}) => {
  return (
    <Box {...props}>
      <SimpleGrid gap="xs" columns={columns}>
        {React.Children.map(children, (child, i) => (
          <>{cloneElement(child, {number: i + 1})}</>
        ))}
      </SimpleGrid>
    </Box>
  )
}

NumberedCardList.defaultProps = {
  columns: 1,
}

NumberedCardList.propTypes = {
  children: PropTypes.node.isRequired,
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
}
