import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Text,
  UnorderedList,
  ListItem,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  CloseButton,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/react'
import {useTranslation, Trans} from 'react-i18next'
import Checkbox from '../../../../../components/Checkbox'
import oAuthLoginRedirect from '../../../../../../utils/oAuthLoginRedirect'

const AccountIntegrationConnect = ({accountingProvider}) => {
  const [isAgreedOnTerm, setIsAgreedOnTerm] = React.useState(false)
  const [displayTermsModal, toggleDisplayTermsModal] = React.useState(false)
  const {t} = useTranslation('onboarding')

  return (
    <>
      <Text
        color="mld.neutral.900"
        fontSize="48px"
        letterSpacing="-0.5"
        lineHeight="1"
        fontWeight="400"
        mb="md"
      >
        {t('onboarding:AccountIntegrationConnect.heading', {
          accountingProvider,
        })}
      </Text>

      <Text mb="md" textStyle="body-intro">
        <Trans
          t={t}
          i18nKey="onboarding:AccountIntegrationConnect.timeNeeded"
        />
      </Text>
      <Text mb="xs" textStyle="body-intro">
        <Trans t={t} i18nKey="onboarding:AccountIntegrationConnect.body" />
      </Text>
      <UnorderedList mb="md" ml="0" styleType="none">
        <ListItem
          textStyle="body-copy"
          display="flex"
          borderColor="mld.neutral.500"
          borderTopWidth="1px"
          borderBottomWidth="1px"
          py="sm"
        >
          <Box
            width="24px"
            height="24px"
            mr="md"
            borderRadius="full"
            backgroundColor="mld.neutral.1000"
            color="mld.neutral.50"
            textAlign="center"
          >
            1
          </Box>
          {t('onboarding:AccountIntegrationConnect.listItems.1')}
        </ListItem>
        <ListItem textStyle="body-copy" display="flex" py="sm">
          <Box
            width="24px"
            height="24px"
            mr="md"
            borderRadius="full"
            backgroundColor="mld.neutral.1000"
            color="mld.neutral.50"
            textAlign="center"
          >
            2
          </Box>
          {t('onboarding:AccountIntegrationConnect.listItems.2')}
        </ListItem>
        <ListItem
          textStyle="body-copy"
          display="flex"
          borderColor="mld.neutral.500"
          borderTopWidth="1px"
          borderBottomWidth="1px"
          py="sm"
        >
          <Box
            width="24px"
            height="24px"
            mr="md"
            borderRadius="full"
            backgroundColor="mld.neutral.1000"
            color="mld.neutral.50"
            textAlign="center"
          >
            3
          </Box>
          {t('onboarding:AccountIntegrationConnect.listItems.3')}
        </ListItem>
      </UnorderedList>

      <Box mb="md" display="flex" width="100%" justifyContent="space-between">
        <Checkbox
          name="acceptAccountingIntegration"
          value="accept-accounting-integration"
          data-testid="accept-accounting-integration"
          size="lg"
          variant="mld"
          onChange={() => setIsAgreedOnTerm(!isAgreedOnTerm)}
          mr="sm"
          flexGrow="0"
        >
          <Text textStyle="body-copy" flexGrow="1">
            <Trans
              t={t}
              i18nKey="onboarding:AccountIntegrationConnect.agreedOnTerm"
              values={{
                accountingProvider,
              }}
              components={{
                /* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */
                privacyPolicyLink: (
                  <a
                    href="https://www.withmarmalade.com.au/privacy-policy"
                    target="_blank"
                    style={{textDecoration: 'underline'}}
                    rel="noreferrer"
                  />
                ),
                termsOfUseLink: (
                  <Box
                    as="span"
                    textDecoration="underline"
                    onClick={() => toggleDisplayTermsModal(!displayTermsModal)}
                    _hover={{cursor: 'pointer'}}
                  />
                ),
              }}
            />
          </Text>
        </Checkbox>
      </Box>

      <Box mt={4}>
        <Popover trigger="hover" placement="bottom">
          <PopoverTrigger>
            <Button
              variant="primary"
              colorScheme="primary"
              data-testid="continue-button"
              size="lg"
              isDisabled={!isAgreedOnTerm}
              onClick={() => {
                oAuthLoginRedirect(accountingProvider)
              }}
            >
              {t('onboarding:AccountIntegrationConnect.submitButton', {
                accountingProvider,
              })}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            bg="mld.neutral.1000"
            width="fit-content"
            boxShadow="lg"
            p="xs"
          >
            <PopoverArrow bg="mld.neutral.1000" />
            <PopoverBody fontSize="xs" color="mld.neutral.50">
              <Text textStyle="body-small">
                {t('onboarding:AccountIntegrationConnect.submitButtonTooltip', {
                  accountingProvider,
                })}
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>

      <Modal
        isOpen={displayTermsModal}
        onClose={() => toggleDisplayTermsModal(false)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="lg" fontWeight="bold">
              {t('onboarding:AccountIntegrationConnect.terms.heading')}
            </Text>
            <CloseButton
              onClick={() => toggleDisplayTermsModal(false)}
              fontSize="10px"
              flexGrow="0"
              color="neutral.black"
              backgroundColor="transparent"
              _hover={{backgroundColor: 'neutral.smoke'}}
              _focus={{boxShadow: '0 0 0 3px rgba(73,70,233,0.25)'}}
            />
          </ModalHeader>
          <ModalBody>
            <Text mb="sm">
              {t('onboarding:AccountIntegrationConnect.terms.list.heading', {
                accountingProvider,
              })}
            </Text>
            <UnorderedList mb="sm">
              <ListItem mb="sm">
                {t('onboarding:AccountIntegrationConnect.terms.list.item1')}
              </ListItem>
              <ListItem mb="sm">
                {t('onboarding:AccountIntegrationConnect.terms.list.item2')}
              </ListItem>
            </UnorderedList>
            <Text mb="sm">
              {t('onboarding:AccountIntegrationConnect.terms.body1', {
                accountingProvider,
              })}
            </Text>
            <Text mb="sm">
              {t('onboarding:AccountIntegrationConnect.terms.body2')}
            </Text>
            <Text mb="sm">
              {t('onboarding:AccountIntegrationConnect.terms.body3')}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

AccountIntegrationConnect.propTypes = {
  accountingProvider: PropTypes.string.isRequired,
}

export default AccountIntegrationConnect
