import React from 'react'
import PropTypes from 'prop-types'
import useSWRImmutable from 'swr'
import {get, capitalize} from 'lodash-es'
import {
  Box,
  CloseButton,
  Collapse,
  Flex,
  Text,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  chakra,
  Tabs,
  TabList,
  Tab,
} from '@chakra-ui/react'
import {ChevronUpIcon, ChevronDownIcon, WarningIcon} from '@chakra-ui/icons'
import {useRails} from '../../contexts/rails'
import {fetcher} from '../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../api/serializer'
import RewardLevelBadge from './RewardLevelBadge'
import UpgradeInstructions from './UpgradeInstructions'
import RetainInstructions from './RetainInstructions'
import MonthlyStats from './MonthlyStats'
import {colors} from '../../theme/foundations/colors'

const SupplierRewardsModal = ({isOpen, onClose}) => {
  const {organisation} = useRails()
  const fixedPricingEnabled = get(
    organisation,
    'currentSupplier.fixedPricingEnabled',
    false,
  )
  const {data, isLoading} = useSWRImmutable(
    `/api/suppliers/${organisation.currentSupplier.id}/supplier_rewards_summary`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
      revalidateOnFocus: false,
    },
  )
  const {isOpen: statusFeeRateIsOpen, onToggle: statusFeeRateOnToggle} =
    useDisclosure()

  const [selectedLevelIndex, setSelectedLevelIndex] = React.useState(null)

  if (!data || isLoading) {
    return null
  }

  const currentLevelIndex = data.levels.findIndex(
    (level) => level.key === data.currentLevel,
  )
  const currentLevelData = data.levels[currentLevelIndex]

  if (!selectedLevelIndex) {
    setSelectedLevelIndex(
      data.levels[currentLevelIndex + 1]?.key
        ? currentLevelIndex + 1
        : currentLevelIndex,
    )
  }

  const selectedLevelData = data.levels[selectedLevelIndex]
  const previousLevel = data.levels[currentLevelIndex - 1]
  const pendingDowngrade = data.pendingDowngrade && previousLevel

  return (
    <Modal size="5xl" isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader pt="md">
          <Flex direction="column" gap="xs">
            <Text textStyle="headline5" textTransform="uppercase">
              Current status
            </Text>
            <Text
              textStyle="headline2"
              background={`mld.rewards.${data.currentLevel}`}
              bgClip="text"
            >
              {capitalize(data.currentLevel)}
            </Text>
          </Flex>
          <CloseButton
            position="absolute"
            top="0"
            right="0"
            mt="xs"
            mx="sm"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody pb="md">
          {pendingDowngrade && (
            <Flex
              bg="orange.50"
              borderRadius="lg"
              px="sm"
              py="xs"
              gap="xs"
              mb="sm"
            >
              <WarningIcon boxSize="4" my="auto" color="orange.500" />
              <Text textStyle="body-small" color="neutral.800">
                Your Marmalade scores dropped last month. If you do not meet all
                requirements, you will be downgraded from{' '}
                {capitalize(data.currentLevel)} to{' '}
                {capitalize(previousLevel.key)} status next month.
              </Text>
            </Flex>
          )}
          <Tabs
            index={selectedLevelIndex}
            onChange={setSelectedLevelIndex}
            align="center"
          >
            <TabList>
              {data.levels.map((levelData, index) => {
                const selected = index === selectedLevelIndex
                const disabled = index < currentLevelIndex

                const hoverSelectedState = {
                  boxShadow: `10px 5px 10px ${colors.mld.neutral[500]}`,
                  transform: 'scale(1.15, 1.15)',
                }

                return (
                  <Tab
                    key={levelData.key}
                    isDisabled={index < currentLevelIndex}
                    _selected={{
                      borderColor: 'mld.neutral.900',
                    }}
                    _disabled={{
                      cursor: 'not-allowed',
                    }}
                    _active={{}}
                    pb="md"
                    textAlign="left"
                  >
                    <RewardLevelBadge
                      level={levelData}
                      current={index === currentLevelIndex}
                      disabled={disabled}
                      _hover={!disabled && hoverSelectedState}
                      {...(selected ? hoverSelectedState : {})}
                      transition="all 0.2s ease-in-out"
                    />
                  </Tab>
                )
              })}
            </TabList>
          </Tabs>

          {selectedLevelIndex && !pendingDowngrade ? (
            <UpgradeInstructions
              selectedLevelData={selectedLevelData}
              maximumPrice={data.maximumPrice}
            />
          ) : (
            <RetainInstructions currentLevelData={currentLevelData} />
          )}

          <MonthlyStats
            currentIssuance={data.currentIssuance}
            currentPayments={data.currentPayments}
            currentResolution={data.currentResolution}
          />

          <Box>
            <Flex cursor="pointer" my="sm" onClick={statusFeeRateOnToggle}>
              <Text textStyle="body-small" my="auto">
                What is &apos;
                <i>{fixedPricingEnabled ? 'Status' : 'Status fee rate'}</i>
                &apos;?
              </Text>
              <Icon
                as={statusFeeRateIsOpen ? ChevronUpIcon : ChevronDownIcon}
                ml="xs"
                my="auto"
                h="24px"
                w="24px"
              />
            </Flex>
            <Collapse in={statusFeeRateIsOpen}>
              <Box>
                {fixedPricingEnabled ? (
                  <Text textStyle="body-copy">
                    Your status at Marmalade is determined by your Marmalade
                    scores, which include issuing invoices with Marmalade
                    payment details, getting paid into Marmalade bank account,
                    and resolving invoice issues within 15 days. You can
                    progress from Bronze to Silver, Silver to Gold, and Gold to
                    Platinum, unlocking more invoices for cash-in as you reach
                    higher status levels.
                  </Text>
                ) : (
                  <Text textStyle="body-copy">
                    Status fee rate is a component of your cash-in fee and is
                    applied to each invoice available for cash-in. When you add
                    the status fee rate to the customer fee rate, you get the
                    total cash-in fee rate for your invoice. The status fee rate
                    is influenced by your business&apos;s level of adoption,
                    while customer fee rate is unique to each customer and is
                    determined based on their payment behaviour.
                  </Text>
                )}
                {!fixedPricingEnabled && (
                  <Flex
                    p="xs"
                    mt="sm"
                    borderRadius="base"
                    border="1px solid"
                    borderColor="neutral.300"
                    backgroundColor="neutral.50"
                    width="fit-content"
                  >
                    <Text textStyle="body-small" fontWeight="bold">
                      Cash-in fee rate{' '}
                      <chakra.span fontWeight="normal" color="neutral.700">
                        ({data.eligibleFeeRange})
                      </chakra.span>{' '}
                      = Status fee rate + Customer fee rate
                    </Text>
                  </Flex>
                )}
              </Box>
            </Collapse>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

SupplierRewardsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SupplierRewardsModal
