import React from 'react'
import {capitalize} from 'lodash-es'
import {
  chakra,
  Box,
  Text,
  UnorderedList,
  ListItem,
  SimpleGrid,
} from '@chakra-ui/react'
import levelType from './levelType'
import SupplierRewardTierRequirements from '../SupplierRewardTierRequirements'

const RetainInstructions = ({currentLevelData}) => {
  return (
    <SimpleGrid
      columns={{lg: 2}}
      spacing="md"
      mt="xs"
      backgroundColor="neutral.200"
      borderRadius="md"
      p="sm"
    >
      <Box>
        <Text textStyle="body-copy" color="neutral.800" fontWeight="bold">
          &#128640; How to stay on{' '}
          <chakra.span textTransform="uppercase">
            {currentLevelData.key}
          </chakra.span>{' '}
          status:
        </Text>
        <Box backgroundColor="neutral.white" borderRadius="base" p="sm">
          <SupplierRewardTierRequirements
            requirements={currentLevelData.requirements}
            tierKey={currentLevelData.key}
          />
        </Box>
      </Box>
      <Box>
        <Text textStyle="body-copy" color="neutral.800" fontWeight="bold">
          &#10024; Why stay on {capitalize(currentLevelData.key)} status?
        </Text>
        <Box backgroundColor="neutral.white" borderRadius="base" p="sm">
          <UnorderedList spacing="xxs">
            <ListItem>
              <Text as="span" textStyle="body-small">
                Lowest fee possible
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>
    </SimpleGrid>
  )
}

RetainInstructions.propTypes = {
  currentLevelData: levelType.isRequired,
}

export default RetainInstructions
