import {Box, Skeleton, Text} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import useSWR from 'swr'

import SupplierLayout from '../../layouts/SupplierLayout'
import {useRails} from '../../contexts/rails'
import withProviders from '../../contexts/withProviders'
import withSWR from '../../contexts/withSWR'
import {fetcher} from '../../../api'
import {camelizeResult} from '../../../api/serializer'
import CustomerDetails from './CustomerDetails'
import Statements from './Statements'
import UnpaidInvoices from './UnpaidInvoices'

export const SupplierCustomerScreen = ({customerId}) => {
  const {organisation} = useRails()

  const {data, error, isLoading} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/customers/${customerId}`,
    fetcher,
    {
      use: [camelizeResult],
    },
  )

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/supplier_logins/sign_in'
  }
  if (error)
    return (
      <Text ml="md">Unable to fetch customer, please try again later.</Text>
    )
  if (isLoading) return <Skeleton height="220px" />

  const {customer} = data
  return (
    <SupplierLayout state="Customers">
      <Box display="flex" flexDirection="column" p="md">
        <Text fontSize="lg" fontWeight="bold">
          {customer?.contactName}
        </Text>
        <CustomerDetails customer={customer} />
        <Statements latestStatement={customer.latestStatement} />
        <UnpaidInvoices customerId={customerId} />
      </Box>
    </SupplierLayout>
  )
}

SupplierCustomerScreen.propTypes = {
  customerId: PropTypes.string.isRequired,
}

export default withProviders(withSWR(SupplierCustomerScreen))
