import React from 'react'
import useSWR, {mutate} from 'swr'
import {Skeleton, Box, Flex, Text, Button} from '@chakra-ui/react'
import {fetcher} from '../../../../api'
import DisplayNameSettingDrawer from './DisplayNameSettingDrawer'

const GeneralInformationSettings = () => {
  const [showDisplayNameSettings, setShowDisplayNameSettings] =
    React.useState(false)

  const {data: response, error} = useSWR('/api/suppliers/settings', fetcher, {
    refreshInterval: 0,
  })

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/supplier_logins/sign_in'
  }

  if (error && error.message !== 'Unauthorized') {
    return (
      <Box width="100%">
        <Text fontSize="sm" mb="sm" color="primary.errorred">
          Fail to retrieve supplier&apos;s settings.
        </Text>
      </Box>
    )
  }

  if (!response && !error) {
    return (
      <Box width="100%">
        {Array.from({length: 1}).map((_, i) => (
          <Skeleton key={`skeleton-${i + 1}`} height="20px" my="sm" />
        ))}
      </Box>
    )
  }

  return (
    response && (
      <>
        <Flex align="center" mb="sm" minHeight="40px">
          <Box width="320px">
            <Text>Business Name</Text>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="calc(100% - 320px)"
          >
            <Text fontWeight="bold">{response.data.supplier.display_name}</Text>
            <Button
              variant="secondary"
              onClick={() => setShowDisplayNameSettings(true)}
            >
              Change
            </Button>
          </Box>
        </Flex>
        <DisplayNameSettingDrawer
          supplierDisplayName={response.data.supplier.display_name}
          displayDrawer={showDisplayNameSettings}
          onClickCancel={() => setShowDisplayNameSettings(false)}
          onClickSave={() => {
            mutate('/api/suppliers/settings')
            setShowDisplayNameSettings(false)
          }}
        />
      </>
    )
  )
}

export default GeneralInformationSettings
