import {createMultiStyleConfigHelpers, theme} from '@chakra-ui/react'
import {colors} from '../foundations/colors'
import typography from '../foundations/typography'

const {defineMultiStyleConfig} = createMultiStyleConfigHelpers(['field'])

const disabled = {
  borderColor: colors.mld.neutral[700],
}

const hover = {
  borderColor: colors.mld.primary[200],
  _disabled: disabled,
}

const valid = {
  borderColor: colors.mld.success[700],
  _disabled: disabled,
}

const invalid = {
  borderColor: colors.mld.alert[700],
  boxShadow: 'none',
  _disabled: disabled,
}

export default defineMultiStyleConfig({
  variants: {
    outline: () => ({
      ...theme.components.Input.outline,
      field: {
        border: '1px solid',
        minH: '48px',
        borderRadius: 'md',
        borderColor: colors.mld.neutral[700],
        fontSize: typography.fontSizes.base,
        _hover: hover,
        _invalid: invalid,
        _valid: valid,
        _active: {
          borderColor: colors.mld.neutral[900],
        },
        _focus: {
          outline: 'none',
          borderColor: colors.mld.neutral[700],
          boxShadow: `${colors.mld.primary[200]} 0px 0px 5px`,
        },
        _autofill: {
          outline: 'none',
          backgroundColor: 'initial',
        },
        _dark: {
          fontColor: colors.mld.neutral[50],
          backgroundColor: 'initial',
          borderColor: colors.mld.neutral[500],
          _hover: hover,
          _invalid: invalid,
          _valid: valid,
        },
      },
    }),
  },
})
