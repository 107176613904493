import React from 'react'
import PropTypes from 'prop-types'
import {Box, Button, Flex, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ClockIcon, ArrowRightIcon, FolderIcon} from '../../../../../icons'

const TaskRequirements = () => {
  const {t} = useTranslation('onboarding')
  const requirements = [
    {icon: <ClockIcon my="auto" mr="xs" width="20px" height="20px" />, key: 1},
    {icon: <FolderIcon my="auto" mr="xs" width="20px" height="20px" />, key: 2},
  ]

  return (
    <Flex
      direction="column"
      gap="sm"
      backgroundColor="mld.neutral.100"
      borderRadius="base"
      p="sm"
    >
      {requirements.map(({icon, key}) => (
        <Flex key={`requirement-${key}`}>
          {icon}
          <Text
            textStyle="headline5"
            textTransform="uppercase"
            fontWeight="bold"
          >
            {t(
              `onboarding:AccountIntegrationWelcome.requirements.${key}.heading`,
            )}
            <Text as="span" textStyle="body-small" textTransform="none" ml="xs">
              {t(
                `onboarding:AccountIntegrationWelcome.requirements.${key}.body`,
              )}
            </Text>
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}

const AccountIntegrationWelcome = ({goToNextPage}) => {
  const {t} = useTranslation('onboarding')

  return (
    <Flex direction="column" gap="md">
      <Text
        color="mld.neutral.900"
        fontSize="48px"
        letterSpacing="-0.5"
        lineHeight="1"
        fontWeight="400"
        mb="xs"
      >
        {t('onboarding:AccountIntegrationWelcome.heading')}
      </Text>
      <Text textStyle="body-intro">
        {t('onboarding:AccountIntegrationWelcome.intro')}
      </Text>
      <Text textStyle="body-copy">
        {t('onboarding:AccountIntegrationWelcome.body')}
      </Text>
      <TaskRequirements />
      <Box>
        <Button
          variant="primary"
          colorScheme="primary"
          onClick={goToNextPage}
          data-testid="continue-button"
          type="submit"
          rightIcon={<ArrowRightIcon width="24px" height="24px" />}
        >
          {t('onboarding:AccountIntegrationWelcome.submitButton')}
        </Button>
      </Box>
    </Flex>
  )
}

AccountIntegrationWelcome.defaultProps = {
  goToNextPage: () => {},
}

AccountIntegrationWelcome.propTypes = {
  goToNextPage: PropTypes.func,
}

export default AccountIntegrationWelcome
