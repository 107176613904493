import React from 'react'
import PropTypes from 'prop-types'
import {
  Skeleton,
  Box,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerContent,
  Button,
} from '@chakra-ui/react'

import {DropdownSelect} from '../../../components'
import {useAuthProvider} from '../../../contexts/authentication'
import {useRails} from '../../../contexts/rails'
import {fetcher, setExpenseAccount} from '../../../../api'

const ExpenseAccountSettingDrawer = ({
  displayDrawer,
  expenseAccountId,
  expenseAccountName,
  expenseAccountCode,
  onClickSave,
  onClickCancel,
}) => {
  const expenseAccountLabel = expenseAccountCode
    ? `${expenseAccountName} (${expenseAccountCode})`
    : expenseAccountName
  const [expenseAccountList, setExpenseAccountList] = React.useState([])
  const [expenseAccountSelection, setExpenseAccountSelection] = React.useState({
    label: expenseAccountLabel,
    value: expenseAccountId,
  })
  const [expenseAccountError, setExpenseAccountError] = React.useState('')
  const [isSendingRequest, setIsSendingRequest] = React.useState(false)

  const {organisation} = useRails()
  const {authenticityToken, updateAuthenticityToken} = useAuthProvider()

  React.useEffect(() => {
    // Fetch list of available expense accounts
    if (displayDrawer) {
      fetcher(
        `/api/suppliers/${organisation.currentSupplier.id}/expense_accounts`,
      ).then((data) => {
        setExpenseAccountList(data.expense_accounts)
      })
    }
  }, [displayDrawer, organisation.currentSupplier.id])

  return (
    <Drawer
      isOpen={displayDrawer}
      placement="right"
      size="md"
      onClose={() => {
        setExpenseAccountSelection({
          label: expenseAccountLabel,
          value: expenseAccountId,
        })
        setExpenseAccountError('')
        onClickCancel()
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="xs"
          >
            <Text flexGrow="1" fontSize="md" fontWeight="bold">
              Expense Account
            </Text>
          </Box>
        </DrawerHeader>
        <DrawerBody overflowY="scroll">
          <Text mb="md">
            Changing the expense account will only apply to future payments
          </Text>
          {expenseAccountError && (
            <Text fontSize="sm" mb="sm" color="primary.errorred">
              Fail to update expense account: {expenseAccountError}
            </Text>
          )}
          {expenseAccountList.length > 0 ? (
            <Box mb={8}>
              <DropdownSelect
                options={expenseAccountList.map((account) => {
                  return {
                    label: account.Code
                      ? `${account.Name} (${account.Code})`
                      : account.Name,
                    value: account.AccountID,
                  }
                })}
                value={expenseAccountSelection}
                name="expenseAccount"
                placeholder="Select expense account"
                isClearable={false}
                isSearchable={false}
                onChange={(selection) =>
                  setExpenseAccountSelection({
                    label: selection.label,
                    value: selection.value,
                  })
                }
              />
            </Box>
          ) : (
            Array.from({length: 3}).map((_, i) => (
              <Skeleton key={`skeleton-${i + 1}`} mb="sm" height="32px" />
            ))
          )}
        </DrawerBody>
        <DrawerFooter>
          <Button
            variant="secondary"
            size="lg"
            mr="sm"
            onClick={() => {
              setExpenseAccountSelection({
                label: expenseAccountLabel,
                value: expenseAccountId,
              })
              setExpenseAccountError('')
              onClickCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            colorScheme="primary"
            size="lg"
            onClick={async () => {
              setIsSendingRequest(true)

              try {
                const {authenticity_token: token} = await setExpenseAccount({
                  accountId: expenseAccountSelection.value,
                  supplierId: organisation.currentSupplier.id,
                  authenticityToken,
                })

                updateAuthenticityToken(token)
                setIsSendingRequest(false)
                onClickSave({
                  expenseAccountName: expenseAccountSelection.label,
                  expenseAccountId: expenseAccountSelection.value,
                })
              } catch (e) {
                setIsSendingRequest(false)
                setExpenseAccountError(e.message)
              }
            }}
            isDisabled={expenseAccountSelection.value === expenseAccountId}
            isLoading={isSendingRequest}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

ExpenseAccountSettingDrawer.defaultProps = {
  displayDrawer: false,
  expenseAccountName: '',
  expenseAccountCode: '',
  expenseAccountId: '',
  onClickSave: () => {},
  onClickCancel: () => {},
}

ExpenseAccountSettingDrawer.propTypes = {
  displayDrawer: PropTypes.bool,
  expenseAccountName: PropTypes.string,
  expenseAccountCode: PropTypes.string,
  expenseAccountId: PropTypes.string,
  onClickSave: PropTypes.func,
  onClickCancel: PropTypes.func,
}

export default ExpenseAccountSettingDrawer
