import React from 'react'

import {Link, Stack, Text} from '@chakra-ui/react'

import Layout from './Layout'

const SupplierRequestClosureConfirmationScreen = () => {
  return (
    <Layout>
      <Stack spacing="sm">
        <Text as="h1" textStyle="headline1" fontSize="xl">
          We&apos;re sorry to see you go
        </Text>

        <Text>We&apos;ve received your request to close your account.</Text>

        <Text>
          If you decide to continue using Marmalade, you can{' '}
          <Link
            fontWeight="bold"
            href="https://www.withmarmalade.com.au/contact"
            target="_blank"
          >
            contact us
          </Link>{' '}
          to reopen your account.
        </Text>
      </Stack>
    </Layout>
  )
}

export default SupplierRequestClosureConfirmationScreen
