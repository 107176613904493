import React from 'react'
import PropTypes from 'prop-types'
import {Box} from '@chakra-ui/react'
import useSWRImmutable from 'swr/immutable'
import useYodlee from '~/react/hooks/useYodlee'
import {
  defaultHeaders,
  fetcher,
  logBankIntegrationEvent,
} from '../../../../../../api'
import {useRails} from '../../../../../contexts/rails'

const FastLinkWrapper = ({onSuccess, onFailure}) => {
  const {organisation, authenticityToken} = useRails()

  const logEvent = (eventType, eventBody) => {
    logBankIntegrationEvent({
      authenticityToken,
      eventType,
      eventBody,
      supplierId: organisation.currentSupplier.id,
    })
  }

  const {
    data: yodleeConnectionDetails,
    isLoading: loadingBankConnectionDetails,
  } = useSWRImmutable(
    `/api/suppliers/${organisation.currentSupplier.id}/banking_providers/connection_details`,
    fetcher,
  )

  const {yodleeConfigName, yodleeFastLinkUrl, yodleeToken} =
    yodleeConnectionDetails || {}

  const Yodlee = useYodlee({
    containerId: 'container-fastlink',
    fastLinkOptions: {
      fastLinkURL: yodleeFastLinkUrl,
      UserExperienceFlowType: 'Aggregation',
      configName: yodleeConfigName,
      token: {
        tokenType: 'AccessToken',
        tokenValue: yodleeToken,
      },
    },
    onError: (data) => {
      logEvent('onError', data)
    },
    onEvent: (data) => {
      logEvent('onEvent', data)
    },
    onSuccess: (data) => {
      logEvent('onSuccess', data)
    },
    onClose: (data) => {
      logEvent('onClose', data)
    },
    onExit: (data) => {
      logEvent('onExit', data)

      if (data?.sites?.filter((site) => site.status === 'SUCCESS').length > 0) {
        fetcher(
          `/api/suppliers/${organisation.currentSupplier.id}/banking_providers/connected`,
          {
            method: 'POST',
            headers: defaultHeaders({authenticityToken}),
          },
        )
        onSuccess(data)
      } else {
        fetcher(
          `/api/suppliers/${organisation.currentSupplier.id}/banking_providers/not_connected`,
          {
            method: 'POST',
            headers: defaultHeaders({authenticityToken}),
            body: JSON.stringify({
              sites_count: data?.sites?.length,
            }),
          },
        )
        onFailure(data)
      }
    },
  })

  if (!loadingBankConnectionDetails && Yodlee.ready && !Yodlee.active) {
    Yodlee.init()
  }

  return (
    <Box
      width={{base: '498px', '2xl': '676px'}}
      display={Yodlee.active ? 'block' : 'none'}
    >
      <Box id="container-fastlink" width="100%" />
    </Box>
  )
}

FastLinkWrapper.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
}

export default FastLinkWrapper
