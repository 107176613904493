import React from 'react'
import mixpanel from 'mixpanel-browser'
import PropTypes from 'prop-types'
import {get, capitalize} from 'lodash-es'
import useSWR from 'swr'
import {
  Box,
  Text,
  Flex,
  Tooltip,
  Icon,
  Divider,
  useOutsideClick,
  Button,
  Skeleton,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons'
import {fetcher} from '../../../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../../../api/serializer'
import {useRails} from '../../../../contexts/rails'
import CustomerRatingsPropType from '../../../../../utils/propTypes/customerRating'
import {StarIcon} from '../../../../icons'
import {useSupplierRewardModal} from '../../../../contexts/supplierRewardsModal'

const recordTooltipMixpanelEvent = () => {
  mixpanel.track('Tooltip Viewed: Fee breakdown')
}

const FeeBreakdown = ({
  id,
  customerRating,
  feeAmount,
  feeRate,
  showBreakdown,
  priceWithinMaximumLimit,
  bestPriceRate,
  maximumFeeRate,
  cashedIn,
}) => {
  const {t} = useTranslation()
  const ref = React.useRef()
  const [showDisplayBreakdown, setShowDisplayBreakdown] = React.useState(false)
  useOutsideClick({
    ref,
    handler: () => setShowDisplayBreakdown(false),
  })

  const {organisation} = useRails()
  const {onOpen} = useSupplierRewardModal()
  const openRewardsModal = (e) => {
    // stopping the click event from bubbling to the tooltip trigger and then reopening the tooltip
    e.stopPropagation()
    setShowDisplayBreakdown(false)
    onOpen()
  }

  const fixedPricingEnabled = get(
    organisation,
    'currentSupplier.fixedPricingEnabled',
    false,
  )
  const {data, isLoading} = useSWR(
    showDisplayBreakdown
      ? `/api/suppliers/${organisation.currentSupplier.id}/invoices/${id}/fee_breakdown`
      : null,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  if (!priceWithinMaximumLimit) {
    return <Text textStyle="body-small">Fee: Over {maximumFeeRate}</Text>
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      ref={ref}
      onClick={() => {
        recordTooltipMixpanelEvent()
        setShowDisplayBreakdown(!showDisplayBreakdown)
      }}
    >
      <Text
        cursor={showBreakdown && !fixedPricingEnabled ? 'pointer' : ''}
        wrap="nowrap"
      >
        <Text
          as="span"
          textStyle="body-small"
          color="grey.700"
          fontWeight="bold"
        >
          Fee:
        </Text>{' '}
        <Text as="span" textStyle="body-small">
          {feeAmount}
        </Text>{' '}
        <Text
          as="span"
          textStyle="body-small"
          fontWeight="bold"
          display={{base: 'none', md: 'inline'}}
        >
          ({feeRate})
        </Text>
        {showBreakdown && !fixedPricingEnabled && (
          <Tooltip
            isOpen={showDisplayBreakdown}
            pointerEvents="all"
            label={
              <Box bg="neutral.white" textStyle="body-copy">
                <Text textStyle="headline5" textTransform="uppercase" mb="xs">
                  Fee breakdown:
                </Text>
                <Flex mb="xs" px="xs" direction="column" gap="xs">
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Flex width="128px">
                      <Text>Status fee</Text>
                    </Flex>
                    <Skeleton isLoaded={!isLoading} minW="100px">
                      <Text textStyle="body-copy-medium">
                        {data && data.supplierFeeAmount.toFormat()}
                        <Text
                          as="span"
                          ml="xs"
                          color="grey.700"
                          textStyle="body-copy"
                        >
                          ({data && data.supplierFeeRate})
                        </Text>
                      </Text>
                    </Skeleton>
                  </Box>
                  {!cashedIn && (
                    <Skeleton isLoaded={!isLoading}>
                      <Flex textStyle="body-small">
                        <Box
                          width="20px"
                          height="20px"
                          borderRadius="base"
                          mr="xs"
                          alignSelf="flex-start"
                          background={`mld.rewards.${
                            data && data.supplierLevel.toLowerCase()
                          }`}
                        />
                        <Box>
                          <Text
                            textStyle="body-small-medium"
                            color="neutral.600"
                          >
                            {capitalize(data && data.supplierLevel)} status
                          </Text>
                          <Text color="neutral.600">
                            Fee rate {data && data.supplierFeeRate}
                          </Text>
                        </Box>
                      </Flex>
                    </Skeleton>
                  )}
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Flex width="128px">
                      <Text>Customer fee</Text>
                    </Flex>
                    <Skeleton isLoaded={!isLoading} minW="100px">
                      <Text textStyle="body-copy-medium">
                        {data && data.customerFeeAmount.toFormat()}
                        <Text
                          as="span"
                          ml="xs"
                          color="grey.700"
                          textStyle="body-copy"
                        >
                          ({data && data.customerFeeRate})
                        </Text>
                      </Text>
                    </Skeleton>
                  </Box>
                  {!cashedIn && (
                    <Skeleton isLoaded={!isLoading}>
                      <Box
                        bg="neutral.200"
                        px="xxs"
                        py="xxs"
                        mt="xxs"
                        borderRadius="sm"
                        display="flex"
                        alignItems="center"
                        width="fit-content"
                        aria-label={`${customerRating.score} out of 5 stars`}
                      >
                        {[...Array(5).keys()].map((value) => (
                          <StarIcon
                            key={value}
                            color={
                              value < customerRating.score
                                ? 'mld.primary.300'
                                : 'grey.500'
                            }
                            h="12px"
                            w="12px"
                            mr="xxs"
                          />
                        ))}
                      </Box>
                      <Text
                        textStyle="body-small"
                        color="neutral.600"
                        width="128px"
                      >
                        {t(
                          `cashinPage:customer_fee_range.${customerRating.score}`,
                          {
                            minFeeRange: customerRating.feeRangeMin,
                            maxFeeRange: customerRating.feeRangeMax,
                          },
                        )}
                      </Text>
                    </Skeleton>
                  )}

                  <Divider variant="dark" />

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    textStyle="body-copy"
                  >
                    <Text>Cash-in fee</Text>
                    <Text textStyle="body-copy-medium">
                      {feeAmount}
                      <Text
                        as="span"
                        ml="xs"
                        color="grey.700"
                        textStyle="body-copy"
                      >
                        ({feeRate})
                      </Text>
                    </Text>
                  </Box>
                </Flex>
                {!cashedIn && bestPriceRate && (
                  <Flex
                    textStyle="body-small"
                    bgColor="mld.success.50"
                    p="xs"
                    direction="column"
                  >
                    <Text>
                      Best Cash-in fee possible for invoices for this customer
                      is {bestPriceRate}
                    </Text>
                    <Button
                      variant="link"
                      textDecoration="underline"
                      alignSelf="start"
                      onClick={openRewardsModal}
                    >
                      See how
                    </Button>
                  </Flex>
                )}
              </Box>
            }
            w="md"
            p="sm"
            boxShadow="base"
            borderRadius="md"
            bg="neutral.white"
            color="black"
          >
            <Icon
              as={showDisplayBreakdown ? TriangleUpIcon : TriangleDownIcon}
              color="primary.actionblue"
              cursor="pointer"
              ml="xxs"
              h="8px"
              w="8px"
              data-testid="tooltip-icon"
            />
          </Tooltip>
        )}
      </Text>
    </Box>
  )
}

FeeBreakdown.defaultProps = {
  cashedIn: false,
  showBreakdown: false,
  bestPriceRate: null,
}

FeeBreakdown.propTypes = {
  id: PropTypes.string.isRequired,
  feeAmount: PropTypes.string.isRequired,
  feeRate: PropTypes.string.isRequired,
  customerRating: CustomerRatingsPropType.isRequired,
  showBreakdown: PropTypes.bool,
  priceWithinMaximumLimit: PropTypes.bool.isRequired,
  bestPriceRate: PropTypes.string,
  maximumFeeRate: PropTypes.string.isRequired,
  cashedIn: PropTypes.bool,
}

export default FeeBreakdown
