import {CookiesProvider} from 'react-cookie'
import {get, map} from 'lodash-es'
import {toast} from 'react-toastify'
import PropTypes from 'prop-types'
import React from 'react'

import {switchOrganisation} from '../../../api'
import {useRails} from '../../contexts/rails'
import {SupplierRewardsModalProvider} from '../../contexts/supplierRewardsModal'
import oAuthLoginRedirect from '../../../utils/oAuthLoginRedirect'
import {
  PaymentSettingsIcon,
  HomeIcon,
  CoinCashInIcon,
  CustomerIcon,
  WalletIcon,
  DocumentIcon,
} from '../../icons'
import SideNavLayout from '../SideNavLayout'
import {Announcements} from '../../components'

const SupplierLayout = ({children, state}) => {
  const {organisation, authenticityToken, accountingProvider} = useRails()

  const currentSupplier = {
    label: get(organisation, 'currentSupplier.name', ''),
    value: get(organisation, 'currentSupplier.id', ''),
  }
  const daysPastMostOverdueInvoiceDueDate = get(
    organisation,
    'currentSupplier.daysPastMostOverdueInvoiceDueDate',
    0,
  )

  const availableSupplierOptions = map(
    organisation.availableSuppliers,
    (supplier) => ({label: supplier.name, value: supplier.id}),
  )

  const creditNotesFeatureEnabled = get(
    organisation,
    'currentSupplier.creditNotesFeatureEnabled',
    false,
  )

  const newCreditNotes = get(
    organisation,
    'currentSupplier.newCreditNotes',
    false,
  )

  const handleOrganisationChange = ({value}) => {
    if (value === 'add-organisation') {
      oAuthLoginRedirect('Xero')
    } else {
      switchOrganisation({authenticityToken, supplierId: value})
        .then(() => {
          // Reload the page
          window.location.reload()
        })
        .catch(() => {
          toast.error(
            'Oops! Something went wrong when trying to select an organisation.',
          )
        })
    }
  }

  let invoicesUrl = '/invoices'
  if (daysPastMostOverdueInvoiceDueDate >= 25) {
    invoicesUrl =
      '/invoices?days_past_due_date=25&cashin_status=true&payment_status=unpaid'
  }
  if (daysPastMostOverdueInvoiceDueDate >= 33) {
    invoicesUrl =
      '/invoices?days_past_due_date=33&cashin_status=true&payment_status=unpaid'
  }

  const generalLinks = [
    {
      disabled: false,
      visible: true,
      label: 'Dashboard',
      icon: <HomeIcon />,
      href: '/dashboard',
    },
    {
      disabled: false,
      visible: true,
      label: 'Cash-in',
      icon: <CoinCashInIcon />,
      href: '/cashin',
    },
    {
      disabled: false,
      visible: true,
      label: 'Invoices',
      icon: <DocumentIcon />,
      tag: creditNotesFeatureEnabled && newCreditNotes ? 'New' : null,
      href: invoicesUrl,
    },
    {
      disabled: false,
      visible: true,
      label: 'Payments',
      icon: <WalletIcon />,
      href: '/payments',
    },
    {
      disabled: false,
      visible: true,
      label: 'Customers',
      icon: <CustomerIcon />,
      href: '/customers',
    },
  ]
    .filter(({visible}) => visible)
    .map((link) => ({...link, active: state === link.label}))

  const managementLinks = [
    {
      visible: true,
      disabled: false,
      label: 'Settings',
      icon: <PaymentSettingsIcon />,
      href: '/settings',
    },
  ]
    .filter(({visible}) => visible)
    .map((link) => ({...link, active: state === link.label}))

  const dropDownOptions =
    accountingProvider.name === 'Xero'
      ? availableSupplierOptions.concat({
          label: 'Add organisation',
          value: 'add-organisation',
        })
      : availableSupplierOptions

  const dropdownSelectProps = {
    name: 'organisation',
    placeholder: 'Supplier organisation',
    options: dropDownOptions,
    value: currentSupplier,
    onChange: handleOrganisationChange,
  }

  return (
    <CookiesProvider>
      <SupplierRewardsModalProvider>
        <SideNavLayout
          authenticityToken={authenticityToken}
          generalLinks={generalLinks}
          managementLinks={managementLinks}
          dropdownSelectProps={dropdownSelectProps}
          signOutAction="/supplier_logins/sign_out"
          displayRewardsModal
        >
          <Announcements />
          {children}
        </SideNavLayout>
      </SupplierRewardsModalProvider>
    </CookiesProvider>
  )
}

SupplierLayout.propTypes = {
  children: PropTypes.node.isRequired,
  state: PropTypes.string.isRequired,
}

export default SupplierLayout
