import React, {Suspense, lazy} from 'react'
import PropTypes from 'prop-types'
import {Box, Badge, Flex, Text, Radio, Spinner} from '@chakra-ui/react'

import {LogoSymbol} from '../Logo'
import PaymentDirectDebit from './PaymentDirectDebit'
import PaymentBankTransfer from './PaymentBankTransfer'
import {toDEformat} from '../../../utils/formatter'
import {BankIcon, CreditCardIcon} from '../../icons'
import Alert from '../Alert'

const PaymentCreditCard = lazy(() => import('./PaymentCreditCard'))

const PaymentOption = ({
  isSelected,
  isDisabled,
  onSelect,
  optionValue,
  logo,
  label,
  badgeText,
  children,
  alertTitle,
  alertMessage,
}) => {
  return (
    <Box py="md" borderBottom="1px solid" borderColor="neutral.glitter">
      <Radio
        name="payment"
        value={optionValue}
        width="100%"
        size="lg"
        isChecked={isSelected}
        isDisabled={isDisabled}
        onChange={onSelect}
      >
        <Box
          display="flex"
          alignItems="center"
          fontSize="18px"
          fontWeight="bold"
          ml="xs"
        >
          {logo}
          <Text as="span">{label}</Text>
          {badgeText && (
            <Badge
              bg="background.purple"
              borderRadius="md"
              fontWeight="normal"
              px="1"
              mx="md"
              textTransform="none"
            >
              {badgeText}
            </Badge>
          )}
        </Box>
      </Radio>
      {isDisabled && alertTitle && alertMessage && (
        <Alert
          status="warning"
          title={alertTitle}
          message={alertMessage}
          ml="md"
          mt="sm"
        />
      )}
      {isSelected && children}
    </Box>
  )
}

PaymentOption.defaultProps = {
  badgeText: '',
}

PaymentOption.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  optionValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  badgeText: PropTypes.string,
  logo: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  alertTitle: PropTypes.string.isRequired,
  alertMessage: PropTypes.string.isRequired,
}

const PaymentCore = ({
  onSubmitPayment,
  payerCreditCardSurcharged,
  creditCardSurchargePercentage,
  creditCardSurchargeFixedAmount,
  invoice,
  bankAccount,
  paymentOptions,
  errorMessage,
  isLoggedIn,
  paymentDetails,
  authenticityToken,
  pendingDirectDebit,
}) => {
  const [paymentOption, setPaymentOption] = React.useState('')
  const bankPaymentReference = toDEformat(
    `${invoice.supplierCode} ${invoice.number}`,
  )

  const paymentLogo = (paymentType) => {
    if (paymentType === 'directDebit') {
      return <LogoSymbol width="20px" height="18px" mr="sm" />
    }

    if (paymentType === 'bankTransfer') {
      return <BankIcon mr="sm" size="24px" />
    }
    if (paymentType === 'creditCard') {
      return <CreditCardIcon mr="sm" size="24px" />
    }

    return null
  }

  const labels = {
    directDebit: 'Direct Debit',
    bankTransfer: 'Bank transfer',
    creditCard: 'Pay by card',
  }
  const badgeTexts = {
    directDebit: '',
    bankTransfer: '',
    creditCard: '',
  }
  if (payerCreditCardSurcharged) {
    badgeTexts.creditCard = 'Card surcharge applies'
  }

  return (
    <>
      {pendingDirectDebit && (
        <Box py="md" borderBottom="1px solid" borderColor="neutral.glitter">
          A payment is already being processed for this invoice.
        </Box>
      )}
      {Object.keys(paymentOptions)
        .filter((paymentType) => paymentOptions[paymentType].visible)
        .map((paymentType) => (
          <PaymentOption
            key={paymentType}
            logo={paymentLogo(paymentType)}
            isSelected={paymentOption === paymentType}
            isDisabled={paymentOptions[paymentType].disabled}
            optionValue={paymentType}
            label={labels[paymentType]}
            badgeText={badgeTexts[paymentType]}
            onSelect={(e) => setPaymentOption(e.target.value)}
            alertTitle={paymentOptions[paymentType].alertTitle}
            alertMessage={paymentOptions[paymentType].alertMessage}
          >
            {paymentType === 'directDebit' && (
              <PaymentDirectDebit
                paymentAmount={invoice.dueAmount || '$ N/A'}
                onSubmitPayment={onSubmitPayment}
                errorMessage={errorMessage.directDebit || ''}
                isLoggedIn={isLoggedIn}
                paymentDetails={
                  (paymentDetails && paymentDetails.directDebit) || {
                    bsb: '',
                    accountNumber: '',
                  }
                }
              />
            )}
            {paymentType === 'bankTransfer' && (
              <PaymentBankTransfer
                {...bankAccount}
                paymentReference={bankPaymentReference}
              />
            )}
            {paymentType === 'creditCard' && (
              <Suspense
                fallback={
                  <Flex justify="center" align="center">
                    <Spinner />
                  </Flex>
                }
              >
                <PaymentCreditCard
                  payerCreditCardSurcharged={payerCreditCardSurcharged}
                  creditCardSurchargePercentage={creditCardSurchargePercentage}
                  creditCardSurchargeFixedAmount={
                    creditCardSurchargeFixedAmount
                  }
                  creditCardPaymentAmount={
                    invoice.creditCardPaymentAmount || '$ N/A'
                  }
                  dueAmount={invoice.dueAmount || '$ N/A'}
                  creditCardSurchargeAmount={
                    invoice.creditCardSurchargeAmount || '$ N/A'
                  }
                  onSubmitPayment={onSubmitPayment}
                  errorMessage={errorMessage.creditCard || ''}
                  isLoggedIn={isLoggedIn}
                  paymentDetails={
                    (paymentDetails && paymentDetails.creditCard) || {
                      name: '',
                      last4: '',
                      token: '',
                      brand: '',
                    }
                  }
                  authenticityToken={authenticityToken}
                  invoiceId={invoice.id}
                />
              </Suspense>
            )}
          </PaymentOption>
        ))}
    </>
  )
}

PaymentCore.defaultProps = {
  onSubmitPayment: () => {},
  paymentOptions: {
    directDebit: true,
    creditCard: true,
    bankTransfer: true,
  },
  errorMessage: {
    directDebit: '',
    creditCard: '',
  },
  paymentDetails: {
    directDebit: {bsb: '', accountNumber: ''},
    creditCard: {token: '', nameOnCard: '', last4Digit: '', brand: ''},
  },
  isLoggedIn: false,
}

const paymentOptionShape = PropTypes.shape({
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  alertTitle: PropTypes.string.isRequired,
  alertMessage: PropTypes.string.isRequired,
})

PaymentCore.propTypes = {
  onSubmitPayment: PropTypes.func,
  payerCreditCardSurcharged: PropTypes.bool.isRequired,
  creditCardSurchargePercentage: PropTypes.number.isRequired,
  creditCardSurchargeFixedAmount: PropTypes.string.isRequired,
  bankAccount: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    bsb: PropTypes.string.isRequired,
    payid: PropTypes.string,
  }).isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string,
    supplierName: PropTypes.string,
    supplierCode: PropTypes.string,
    number: PropTypes.string,
    amount: PropTypes.string,
    dueAmount: PropTypes.string,
    creditCardSurchargeAmount: PropTypes.string,
    creditCardPaymentAmount: PropTypes.string,
  }).isRequired,
  paymentOptions: PropTypes.shape({
    directDebit: paymentOptionShape,
    creditCard: paymentOptionShape,
    bankTransfer: paymentOptionShape,
  }),
  errorMessage: PropTypes.shape({
    directDebit: PropTypes.string,
    creditCard: PropTypes.string,
  }),
  paymentDetails: PropTypes.shape({
    directDebit: PropTypes.shape({
      bsb: PropTypes.string,
      accountNumber: PropTypes.string,
    }),
    creditCard: PropTypes.shape({
      token: PropTypes.string,
      last4Digit: PropTypes.string,
      nameOnCard: PropTypes.string,
      brand: PropTypes.string,
    }),
  }),
  isLoggedIn: PropTypes.bool,
  authenticityToken: PropTypes.string.isRequired,
  pendingDirectDebit: PropTypes.bool.isRequired,
}

export default PaymentCore
