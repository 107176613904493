import Alert from './alert'
import Badge from './badge'
import Button from './button'
import Tag from './tag'
import Input from './input'
import Checkbox from './checkbox'
import Radio from './radio'
import Table from './table'
import Link from './link'

export default {
  Checkbox,
  Radio,
  Alert,
  Tag,
  Button,
  Badge,
  Input,
  Table,
  Link,
  CloseButton: {
    defaultProps: {
      fontSize: '10px',
    },
  },
  Divider: {
    variants: {
      dark: {
        borderColor: 'grey.500',
        borderStyle: 'solid',
      },
    },
  },
  Tabs: {
    variants: {
      line: () => {
        return {
          tab: {
            color: 'mld.neutral.700',

            _selected: {
              color: 'mld.neutral.900',
              borderColor: 'mld.neutral.900',
              _hover: {
                color: 'mld.neutral.900',
                borderColor: 'mld.neutral.900',
              },
            },
            _active: {
              color: 'mld.primary.100',
              borderColor: 'mld.primary.100',
              _hover: {
                color: 'mld.primary.100',
                borderColor: 'mld.primary.100',
              },
            },
            _hover: {
              color: 'mld.neutral.900',
              borderColor: 'mld.primary.100',
            },
          },
        }
      },
      curved: (props) => {
        return {
          tablist: {
            borderBottom: '1px solid',
            borderColor: 'inherit',
            px: 'xs',
            gap: 'xs',
          },
          tab: {
            width: '226px',
            marginTop: 'sm',
            borderColor: 'mld.neutral.500',
            background: 'mld.neutral.200',
            borderTopRadius: 'md',
            position: 'relative',
            bottom: '-1px',
            borderWidth: '1px',
            borderBottomWidth: '1px',
            borderBlockEndColor: props.borderColor,
            color: 'mld.neutral.900',

            _selected: {
              background: 'neutral.white',
              borderWidth: '1px',
              borderColor: props.borderColor,
              color: 'mld.neutral.800',
              borderBlockEndColor: 'transparent',
              _hover: {
                background: 'neutral.white',
              },
            },
            _hover: {
              background: 'mld.gradients.100',
            },
          },
        }
      },
    },
  },
}
