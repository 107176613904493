import React from 'react'
import {Flex, Text, Button, Image, Box} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ArrowRightIcon} from '../../../../../icons'
import congratsImage from '../../../../../../../assets/images/congrats.svg'
import confettiImage from '../../../../../../../assets/images/confetti.svg'
import {useRails} from '../../../../../contexts/rails'
import {defaultHeaders, fetcher} from '../../../../../../api'

const Supported = () => {
  const {authenticityToken, organisation} = useRails()
  const {t} = useTranslation('onboarding')

  React.useEffect(() => {
    fetcher(
      `/api/suppliers/${organisation.currentSupplier.id}/acquisition_decision/move_to_dashboard`,
      {
        method: 'POST',
        headers: defaultHeaders({authenticityToken}),
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = () => {
    window.location.href = '/dashboard'
  }

  return (
    <Flex minH="100%" align="center" justify="center">
      <Flex m={{base: 'sm', lg: 'xl'}} flexDirection="column">
        <Box position="relative" pt="lg">
          <Image
            src={confettiImage}
            width="50%"
            height="100%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
          <Image src={congratsImage} mx="auto" mb={{base: 'md', lg: 'xl'}} />
        </Box>
        <Text
          maxWidth="450px"
          mx="auto"
          textStyle="headline2"
          textAlign="center"
        >
          {t('onboarding:AcquisitionDecisionSupported.heading')}
        </Text>

        <Text
          maxWidth="450px"
          mx="auto"
          textStyle="body-intro"
          mt="md"
          mb="lg"
          textAlign="center"
          color="mld.neutral.700"
        >
          {t('onboarding:AcquisitionDecisionSupported.body')}
        </Text>
        <Flex justifyContent="center">
          <Button
            variant="primary"
            onClick={onClick}
            colorScheme="primary"
            type="submit"
            data-testid="continue-button"
            size="lg"
            mt="sm"
          >
            {t('onboarding:AcquisitionDecisionSupported.submitButton')}
            <ArrowRightIcon width="24px" height="24px" ml="xs" />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Supported
