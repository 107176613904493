import {get} from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import useSWR from 'swr'
import {InfoOutlineIcon} from '@chakra-ui/icons'
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  Box,
  Text,
  HStack,
  Link,
  useDisclosure,
  Button,
  CloseButton,
} from '@chakra-ui/react'

import {fetcher} from '../../../api'
import {Skeleton} from '..'
import {toCurrency, toLocalDate} from '../../../utils/formatter'
import DataTable from '../DataTable'
import RemitManuallyModal from './RemitManuallyModal'

export const FAQ_URL =
  'https://marmalade.ladesk.com/715120-Settle-Outstanding-Funds-FAQs'

const columns = [
  {header: 'Customer', accessorKey: 'contact_name'},
  {header: 'Invoice number', accessorKey: 'number'},
  {header: 'Issue reported', accessorKey: 'issue_reported'},
  {header: 'Reported by', accessorKey: 'reported_by'},
  {
    header: 'Reported on',
    accessorKey: 'reported_on',
    cell: ({getValue}) => toLocalDate(getValue()),
  },
  {
    header: 'Amount you owe',
    accessorKey: 'reimbursement_amount.cents',
    cell: ({getValue}) => toCurrency(getValue()),
  },
]

const ReimbursementRequiredInvoicesModal = ({isOpen, onClose}) => {
  const remitManuallyDisclosure = useDisclosure()

  const remitManually = () => {
    onClose()
    remitManuallyDisclosure.onOpen()
  }

  const {data, error} = useSWR(
    // NOTE: only fetch when modal is open
    remitManuallyDisclosure.isOpen || isOpen
      ? '/api/reimbursement_required_invoices'
      : null,
    fetcher,
  )
  const loading = !data && !error

  const invoices = get(data, 'invoices')

  return (
    <>
      <RemitManuallyModal
        amount={get(data, 'metadata.total_reimbursement_amount.cents')}
        {...remitManuallyDisclosure}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="5xl" preserveScrollBarGap>
        <ModalOverlay zIndex="modal" />
        <ModalContent borderRadius="lg" py="sm" pb="0">
          <ModalHeader fontSize="base" pt="none" pb="xs">
            <HStack alignItems="center">
              <span>Balance you owe Marmalade</span>

              <Link
                ml="auto"
                fontSize="sm"
                href={FAQ_URL}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <InfoOutlineIcon mr="xs" />
                Learn more
              </Link>

              <CloseButton onClick={onClose} />
            </HStack>
          </ModalHeader>

          <ModalBody px="0" pb="0">
            <Box bg="mld.neutral.200" p="md" pt="sm" mb="sm">
              <Skeleton isLoaded={!loading}>
                <Text fontWeight="bold" fontSize="xl" mb="xs">
                  {toCurrency(
                    get(data, 'metadata.total_reimbursement_amount.cents'),
                  )}
                </Text>
              </Skeleton>

              <Button
                as="a"
                variant="primary"
                colorScheme="primary"
                mr="sm"
                href="/cashin"
              >
                Settle using Cash-in now
              </Button>

              <Button variant="tertiary" onClick={remitManually}>
                Remit manually
              </Button>
            </Box>

            <Box overflowY="scroll" whiteSpace="nowrap">
              <DataTable
                columns={columns}
                data={invoices}
                isLoading={loading}
                enableSorting
              />

              {error && (
                <Box px="md" pb="sm">
                  Unable to fetch invoices, please try again later.
                </Box>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

ReimbursementRequiredInvoicesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ReimbursementRequiredInvoicesModal
