import React from 'react'
import Welcome from './Welcome'
import FastLinkWrapper from './FastLinkWrapper'
import {AuthProvider} from '../../../../../contexts/authentication'
import {useRails} from '../../../../../contexts/rails'
import {defaultHeaders, fetcher} from '../../../../../../api'
import Success from './Success'
import NotConnected from './NotConnected'

const goToFastLink = (setCurrentStep) => () => setCurrentStep('fastLinkWrapper')
const onSuccess = (setCurrentStep) => () => setCurrentStep('success')
const onFailure = (setCurrentStep) => () => setCurrentStep('notConnected')

const BankAccountIntegration = () => {
  const {authenticityToken, organisation, supplierSetupTrackerState} =
    useRails()

  const initialStates = {
    bank_account_connection_begin: 'welcome',
    bank_account_connection_succeeded: 'success',
    bank_account_connection_not_connected: 'notConnected',
    bank_account_connection_failed: 'notConnected',
  }
  const initialState = initialStates[supplierSetupTrackerState] || 'welcome'
  const [currentStep, setCurrentStep] = React.useState(initialState)

  const markAccountIntegrationStepComplete = async () => {
    // Update the state as complete to move to the Pre Aquistition decision screen
    await fetcher(
      `/api/suppliers/${organisation.currentSupplier.id}/banking_providers/complete`,
      {
        method: 'POST',
        headers: defaultHeaders({authenticityToken}),
      },
    )
    window.location.href = '/dashboard'
  }

  return (
    <AuthProvider authenticityToken={authenticityToken}>
      {currentStep === 'welcome' && (
        <Welcome goToNextPage={goToFastLink(setCurrentStep)} />
      )}
      {currentStep === 'fastLinkWrapper' && (
        <FastLinkWrapper
          onSuccess={onSuccess(setCurrentStep)}
          onFailure={onFailure(setCurrentStep)}
        />
      )}
      {currentStep === 'success' && (
        <Success goToNextPage={markAccountIntegrationStepComplete} />
      )}
      {currentStep === 'notConnected' && (
        <NotConnected goToNextPage={markAccountIntegrationStepComplete} />
      )}
    </AuthProvider>
  )
}

export default BankAccountIntegration
