import React, {Fragment} from 'react'
import {css} from '@emotion/react'
import useSWR from 'swr'
import {
  Box,
  Table,
  Tooltip,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  CloseButton,
  useDisclosure,
  Button,
  IconButton,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import {get, map, groupBy} from 'lodash-es'
import {decamelizeKeys} from 'humps'
import SupplierLayout from '../../layouts/SupplierLayout'
import withProviders from '../../contexts/withProviders'
import withSWR from '../../contexts/withSWR'
import PaymentFilter from './PaymentFilter'
import {FilterIcon} from '../../icons'
import {
  toCurrency,
  toLocalTime,
  toLocalDate,
  toEpochMillisecond,
  toEpochSecond,
} from '../../../utils/formatter'
import {EmptyList, FilterEmptyList} from '../../components/EmptyList'
import {LoadingSkeleton} from '../../components/DataTable'
import {OldRow, OldCell, OldHeader} from '../../components/OldTable'
import Pagination from '../../components/Pagination'
import PaymentDrawer from './PaymentDrawer'
import PaymentStatusTag from './PaymentStatusTag'
import Feedback from '../../components/Feedback'

const SupplierPaymentsScreen = () => {
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = React.useState(false)
  const [filterOption, setFilterOption] = React.useState({})
  const activeFilterCount = Object.keys(filterOption).length
  const queryParams = {
    page,
    per_page: perPage,
    ...decamelizeKeys(filterOption),
    ...(filterOption.customer
      ? {customer_id: filterOption.customer.value}
      : {}),
    ...(filterOption.invoice ? {invoice_id: filterOption.invoice.value} : {}),
    ...(filterOption.dateFrom
      ? {date_from: toEpochSecond(filterOption.dateFrom)}
      : {}),
    ...(filterOption.dateTo
      ? {date_to: toEpochSecond(filterOption.dateTo)}
      : {}),
  }
  const {customer, ...filteredQueryParams} = queryParams
  const paymentsEndpoint = `/api/suppliers/payments?${new URLSearchParams(
    filteredQueryParams,
  ).toString()}`

  const {data, error} = useSWR(paymentsEndpoint)

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/supplier_logins/sign_in'
  }

  const totalCount = get(data, 'metadata.total_count', 0)
  const totalPages = get(data, 'metadata.total_pages', 0)

  const headers = [
    'Received At',
    'Customer',
    'Reference',
    'Status',
    'Amount',
    '',
  ]
  const mappedData =
    data && Array.isArray(data.payments)
      ? data.payments.map((payment) => ({
          ...payment,
          amount: payment.amount ? toCurrency(payment.amount.cents) : '$TBC',
        }))
      : []

  const groupedData = groupBy(mappedData, ({createdAt}) => {
    return toLocalDate(toEpochMillisecond(createdAt), {
      weekday: 'short',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  })

  const isLoading = !data && !error

  const noFilters = activeFilterCount === 0

  const noData = !mappedData.length

  const emptyList = !isLoading && noData && noFilters

  const emptyFilteredList = !isLoading && noData && !noFilters

  const dateRowStyleOverride = css({
    '& > td > div': {
      minHeight: '32px',
    },
  })

  const paymentDetailsDrawer = useDisclosure()

  const [selectedPaymentId, setSelectedPaymentId] = React.useState(null)
  const selectedPayment = mappedData.find(({id}) => id === selectedPaymentId)

  const showPayment = (paymentId) => {
    mixpanel.track('Line item clicked: Payments page')
    setSelectedPaymentId(paymentId)
    paymentDetailsDrawer.onOpen()
  }
  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen)
  }

  const handleClickFilterPayments = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen)
  }

  return (
    <SupplierLayout state="Payments">
      <Box display="flex" flexDirection="column" height="100vh">
        {selectedPayment && (
          <PaymentDrawer
            payment={selectedPayment}
            isOpen={paymentDetailsDrawer.isOpen}
            onClose={paymentDetailsDrawer.onClose}
          />
        )}
        <Box display="flex" px="md" pt="sm" pb="xs">
          <Box width="50%">
            <Text fontSize="26px" fontWeight="bold">
              Payments
            </Text>
            <Feedback px="none" />
          </Box>
          <Box
            width="50%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {Boolean(activeFilterCount) && (
              <Text
                fontWeight="bold"
                color="neutral.white"
                backgroundColor="primary.actionblue"
                borderRadius="full"
                height="25px"
                width="25px"
                textAlign="center"
              >
                {activeFilterCount}
              </Text>
            )}
            <Text ml="xs" color="neutral.80">
              Filter Payments
            </Text>
            <IconButton
              variant="tertiary"
              onClick={handleClickFilterPayments}
              isRound
              fontSize="lg"
              ml="xs"
              aria-label="payment filter"
            >
              <FilterIcon />
            </IconButton>
          </Box>
        </Box>
        <Box flexGrow="1" overflow="scroll" whiteSpace="nowrap">
          {error &&
            error.message !== 'Unauthorized' &&
            error.name !== 'APINetworkError' && (
              <Text ml="md">Error in fetching payments</Text>
            )}

          <Table>
            <thead>
              <tr>
                {headers.map((header) => (
                  <OldHeader key={header}>{header}</OldHeader>
                ))}
              </tr>
            </thead>

            {isLoading && <LoadingSkeleton columns={headers.length} />}

            {mappedData.length > 0 && (
              <tbody>
                {map(groupedData, (payments, paymentDate) => (
                  <Fragment key={paymentDate}>
                    <OldRow bg="grey.100" css={dateRowStyleOverride}>
                      <OldCell colSpan={headers.length}>{paymentDate}</OldCell>
                    </OldRow>

                    {payments.map((payment) => (
                      <OldRow
                        key={payment.id}
                        onClick={() => showPayment(payment.id)}
                        applyHoverStyles
                      >
                        <OldCell>
                          {toLocalTime(toEpochMillisecond(payment.createdAt))}
                        </OldCell>
                        <OldCell>
                          {payment.customerName ? (
                            <Text>{payment.customerName}</Text>
                          ) : (
                            <Text>-</Text>
                          )}
                        </OldCell>
                        <OldCell>
                          <Tooltip
                            label={payment.reference}
                            zIndex="tooltip"
                            placement="top"
                          >
                            <Text
                              maxWidth="300px"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {payment.reference}
                            </Text>
                          </Tooltip>
                        </OldCell>
                        <OldCell fontWeight="bold">
                          <PaymentStatusTag payment={payment} />
                        </OldCell>
                        <OldCell color="mld.success.800">
                          +{payment.amount}
                        </OldCell>
                        <OldCell>
                          <Button
                            variant="secondary"
                            onClick={() => showPayment(payment.id)}
                            height="8"
                            data-testid="view_details_btn"
                          >
                            View details
                          </Button>
                        </OldCell>
                      </OldRow>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            )}
          </Table>

          {emptyList && <EmptyList resourceName="payments" />}

          {emptyFilteredList && <FilterEmptyList resourceName="payments" />}

          {mappedData.length > 0 && (
            <Box px="md" mb="md" position="sticky" left="0">
              <Pagination
                totalPages={totalPages}
                currentPage={page}
                totalCount={totalCount}
                setPerPage={setPerPage}
                perPage={perPage}
                onPageChange={setPage}
              />
            </Box>
          )}
        </Box>
        <Drawer
          isOpen={isFilterDrawerOpen}
          placement="right"
          size="md"
          onClose={toggleFilterDrawer}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py="xs"
              >
                <Text flexGrow="1" fontSize="md">
                  Filter
                </Text>
                <CloseButton
                  onClick={toggleFilterDrawer}
                  fontSize="10px"
                  flexGrow="0"
                  color="neutral.black"
                  backgroundColor="transparent"
                  _hover={{backgroundColor: 'neutral.smoke'}}
                  _focus={{boxShadow: '0 0 0 3px rgba(73,70,233,0.25)'}}
                  tabIndex="-1"
                />
              </Box>
            </DrawerHeader>
            <DrawerBody overflowY="scroll">
              <PaymentFilter
                setIsFilterDrawerOpen={setIsFilterDrawerOpen}
                setFilterOption={setFilterOption}
                filterOption={filterOption}
                maxAmountRange={
                  (data && data.maximum_amount_cents) || 1_000_000_00
                }
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </SupplierLayout>
  )
}

export default withProviders(withSWR(SupplierPaymentsScreen))
