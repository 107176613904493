import React from 'react'
import useSWR from 'swr'
import PropTypes from 'prop-types'
import {camelizeKeys} from 'humps'
import {useTranslation} from 'react-i18next'
import {
  Text,
  Button,
  Flex,
  Box,
  Skeleton,
  Card,
  Stack,
  CardBody,
} from '@chakra-ui/react'
import {CheckIcon} from '@chakra-ui/icons'
import {useRails} from '../../../../../contexts/rails'
import {fetcher} from '../../../../../../api'
import {ArrowRightIcon} from '../../../../../icons'

const Success = ({goToNextPage}) => {
  const {t} = useTranslation('onboarding')
  const i18nKeyPrefix = 'onboarding:BankAccountIntegration.successScreen'
  const {organisation} = useRails()
  const BANK_ACCOUNT_ENDPOINT = `/api/suppliers/${organisation.currentSupplier.id}/banking_providers/bank_accounts`
  const {data, isLoading} = useSWR(BANK_ACCOUNT_ENDPOINT, fetcher)
  const {accounts: providerAccounts} = camelizeKeys(data || {})
  const bankIntegrationConnected = !!(
    providerAccounts && providerAccounts.length
  )
  const bankAccounts = bankIntegrationConnected
    ? providerAccounts.flatMap(
        (providerAccount) => providerAccount.bankAccounts,
      )
    : []

  return (
    <Flex direction="column" gap="md">
      <Text
        color="mld.neutral.900"
        fontSize="48px"
        letterSpacing="1"
        lineHeight="1"
        fontWeight="400"
      >
        {t(`${i18nKeyPrefix}.title`)}
      </Text>
      <Text textStyle="body-intro">{t(`${i18nKeyPrefix}.message`)}</Text>
      <Flex direction="column" gap="xs">
        <Text textStyle="header5" textTransform="uppercase" pb="xs">
          {t(`${i18nKeyPrefix}.accountsSubtitle`)}
        </Text>
        {isLoading || bankAccounts.length === 0 ? (
          <Stack gap="sm">
            <Skeleton height="64px" />
            <Skeleton height="64px" />
            <Skeleton height="64px" />
          </Stack>
        ) : (
          <>
            {bankAccounts.map(({accountName, accountNumber}) => {
              return (
                <Card
                  key={accountNumber}
                  variant="outline"
                  size="sm"
                  borderRadius="lg"
                >
                  <CardBody>
                    <Flex justifyContent="space-between" align="center">
                      <Stack>
                        <Text textStyle="body-small-medium">{accountName}</Text>
                        <Text textStyle="body-small" color="mld.neutral.700">
                          {t(`${i18nKeyPrefix}.accountItemNumber`, {
                            accountNumber,
                          })}
                        </Text>
                      </Stack>
                      <Flex
                        width="24px"
                        height="24px"
                        borderRadius="full"
                        bg="mld.primary.200"
                        justify="center"
                        align="center"
                      >
                        <CheckIcon />
                      </Flex>
                    </Flex>
                  </CardBody>
                </Card>
              )
            })}
            <Text textStyle="body-detail" textAlign="end">
              {t(`${i18nKeyPrefix}.accountItemsFooter`)}
            </Text>
          </>
        )}
      </Flex>
      <Box>
        <Button
          variant="primary"
          colorScheme="primary"
          size="lg"
          onClick={goToNextPage}
        >
          {t(`${i18nKeyPrefix}.ctaButton`)}
          <ArrowRightIcon width="24px" height="24px" ml="xs" />
        </Button>
      </Box>
    </Flex>
  )
}

Success.propTypes = {
  goToNextPage: PropTypes.func.isRequired,
}

export default Success
