import React from 'react'
import PropTypes from 'prop-types'
import {Text, Image, InputGroup, InputLeftAddon} from '@chakra-ui/react'
import {Input} from '..'
import AustralianFlag from '../../../../assets/images/flags/au.svg'

const PhoneNumberInput = ({name, errorMessage, ...props}) => {
  return (
    <InputGroup>
      <InputLeftAddon height="48px" width="100px" bgColor="mld.neutral.500">
        <Image src={AustralianFlag} />
        <Text ml={2}>+61</Text>
      </InputLeftAddon>
      <Input
        name={name}
        errorMessage={errorMessage}
        placeholder="Phone number"
        type="number"
        borderLeftRadius="none"
        {...props}
      />
    </InputGroup>
  )
}

PhoneNumberInput.defaultProps = {
  name: '',
  errorMessage: '',
}

PhoneNumberInput.propTypes = {
  name: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default PhoneNumberInput
