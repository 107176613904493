import PropTypes from 'prop-types'
import mixpanel from 'mixpanel-browser'
import React, {useEffect, useCallback} from 'react'
import {
  Box,
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import {ArrowBackIcon} from '@chakra-ui/icons'

import useYodlee from '~/react/hooks/useYodlee'
import {fetcher, defaultHeaders} from '../../../api/fetcher'
import {useRails} from '../../contexts/rails'
import {useAuthProvider} from '../../contexts/authentication'
import FailureView from './Views/FailureView'
import InitialView from './Views/InitialView'
import SuccessView from './Views/SuccessView'
import LearnMoreView from './Views/LearnMoreView'
import {logBankIntegrationEvent} from '../../../api'

const Step = {
  initial: 'initial',
  learnMore: 'learn',
  connecting: 'connecting',
  completed: 'completed',
}

const Result = {
  success: 'success',
  failure: 'failure',
}

const recordMixpanelEvent = (result) => {
  mixpanel.track('Bank Data Form Submit Attempt', {result})
}

const BankIntegrationModal = ({
  isOpen,
  onClose,
  openOnLearnMore,
  initialStep,
  loadingConfig,
  yodleeFastLinkUrl,
  yodleeConfigName,
  yodleeToken,
  yodleeFlow,
  providerAccountId,
  displaySuccessView,
}) => {
  const {organisation} = useRails()
  const {authenticityToken} = useAuthProvider()
  const [currentStep, setCurrentStep] = React.useState(initialStep)
  const [result, setResult] = React.useState(null)
  const [previousOpenState, setPreviousOpenState] = React.useState(isOpen)

  const logEvent = (eventType, eventBody) => {
    logBankIntegrationEvent({
      authenticityToken,
      eventType,
      eventBody,
      supplierId: organisation.currentSupplier.id,
    })
  }

  const Yodlee = useYodlee({
    containerId: 'container-fastlink',
    fastLinkOptions: {
      fastLinkURL: yodleeFastLinkUrl,
      providerAccountId,
      UserExperienceFlowType: 'Aggregation',
      flow: yodleeFlow,
      configName: yodleeConfigName,
      token: {
        tokenType: 'AccessToken',
        tokenValue: yodleeToken,
      },
    },
    onError: (data) => {
      setResult(Result.failure)
      logEvent('onError', data)
    },
    onEvent: (data) => {
      logEvent('onEvent', data)
    },
    onSuccess: (data) => {
      logEvent('onSuccess', data)
    },
    onClose: (data) => {
      logEvent('onClose', data)
    },
    onExit: (data) => {
      logEvent('onExit', data)
      if (data?.sites?.filter((site) => site.status === 'SUCCESS').length > 0) {
        recordMixpanelEvent(true)
        setResult(Result.success)
        fetcher(
          `/api/suppliers/${organisation.currentSupplier.id}/banking_providers/connected`,
          {
            method: 'POST',
            headers: defaultHeaders({authenticityToken}),
          },
        )
      } else {
        recordMixpanelEvent(false)
        setResult(Result.failure)
      }
      setCurrentStep(Step.completed)
    },
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetModal = useCallback(() => {
    setResult(null)
    setCurrentStep(Step.initial)
    Yodlee.reset()
  })

  const handleClose = useCallback(() => {
    onClose()
    resetModal()
  }, [onClose, resetModal])

  const startConnecting = () => {
    if (Yodlee.ready && !Yodlee.active) {
      setCurrentStep(Step.connecting)
      Yodlee.init()
    }
  }

  useEffect(() => {
    if (!previousOpenState && isOpen && openOnLearnMore) {
      setCurrentStep(Step.learnMore)
    }
    if (previousOpenState !== isOpen) {
      setPreviousOpenState(isOpen)
    }
  }, [previousOpenState, isOpen, openOnLearnMore])

  useEffect(() => {
    if (
      currentStep === Step.completed &&
      (!result || (result === Result.success && !displaySuccessView))
    )
      handleClose()
  }, [currentStep, handleClose, result, displaySuccessView])

  if (
    currentStep === Step.connecting &&
    initialStep === Step.connecting &&
    yodleeFastLinkUrl
  ) {
    startConnecting()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      onClose={handleClose}
      closeOnOverlayClick={false}
      preserveScrollBarGap
    >
      <ModalOverlay zIndex="modal" />
      <ModalContent borderRadius="lg" py="sm">
        <ModalHeader fontSize="lg" py={Yodlee.active ? 0 : 'sm'}>
          <Stack
            isInline
            justifyContent={
              !Yodlee.active && currentStep === Step.failure
                ? 'space-between'
                : 'right'
            }
          >
            {!Yodlee.active && currentStep === Step.failure && (
              <Button
                _hover={{textDecoration: 'none'}}
                alignItems="center"
                display="inline-flex"
                fontWeight="bold"
                onClick={resetModal}
                variant="link"
              >
                <ArrowBackIcon mr="xs" />
                Back
              </Button>
            )}
            {!Yodlee.active && (
              <CloseButton
                onClick={handleClose}
                data-testid="close-modal-button"
                size="md"
                pb={0}
              />
            )}
          </Stack>
        </ModalHeader>

        <ModalBody px={Yodlee.active ? 0 : 'sm'}>
          <Box display={Yodlee.active ? 'block' : 'none'}>
            <Box id="container-fastlink" />
          </Box>

          <Stack spacing="sm" px="sm" display="flex" justifyContent="center">
            {currentStep === Step.initial && !Yodlee.active && (
              <InitialView
                isLoading={loadingConfig}
                startConnecting={startConnecting}
                showLearnMore={() => setCurrentStep(Step.learnMore)}
                tokenLoaded={!!yodleeToken}
              />
            )}

            {currentStep === Step.learnMore && !Yodlee.active && (
              <LearnMoreView
                isLoading={loadingConfig}
                startConnecting={startConnecting}
                tokenLoaded={!!yodleeToken}
              />
            )}

            {!Yodlee.active &&
              currentStep === Step.completed &&
              result === Result.success && (
                <SuccessView onClose={handleClose} />
              )}

            {!Yodlee.active &&
              currentStep === Step.completed &&
              result === Result.failure && (
                <FailureView
                  onClose={handleClose}
                  startConnecting={startConnecting}
                />
              )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

BankIntegrationModal.defaultProps = {
  loadingConfig: false,
  yodleeToken: '',
  yodleeFastLinkUrl: '',
  yodleeConfigName: '',
  providerAccountId: '',
  yodleeFlow: '',
  initialStep: Step.initial,
  displaySuccessView: true,
}

BankIntegrationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openOnLearnMore: PropTypes.bool.isRequired,
  loadingConfig: PropTypes.bool,
  yodleeToken: PropTypes.string,
  yodleeFastLinkUrl: PropTypes.string,
  yodleeConfigName: PropTypes.string,
  yodleeFlow: PropTypes.string,
  providerAccountId: PropTypes.string,
  initialStep: PropTypes.string,
  displaySuccessView: PropTypes.bool,
}

export default BankIntegrationModal
