import React from 'react'
import {
  Flex,
  Text,
  Image,
  Stack,
  ListItem,
  UnorderedList,
  Progress,
  Box,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import useSWR from 'swr'
import animatedClock from '../../../../../../../assets/images/animated_clock.svg'
import {useRails} from '../../../../../contexts/rails'
import {
  camelizeResult,
  dineroMoneyObjects,
} from '../../../../../../api/serializer'
import {fetcher} from '../../../../../../api'

const Processing = () => {
  const {organisation} = useRails()
  const {t} = useTranslation('onboarding')

  const {data, isLoading} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/acquisition_decision`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
      refreshInterval: 5000,
    },
  )
  const {
    meta: {progressPercentage},
  } = data || {}

  return (
    <Flex
      gap={{base: 'sm', md: 'md', lg: 'lg'}}
      minH="100%"
      flexDirection="column"
      justify="center"
      align="center"
    >
      <Box minW="90px" minH="90px" pt="md">
        <Image
          src={animatedClock}
          minW="90px"
          minH="90px"
          height="20vh"
          maxWidth="190px"
          maxHeight="190px"
        />
      </Box>
      <Text maxWidth="450px" textStyle="headline2" textAlign="center">
        {t('onboarding:AcquisitionDecisionProcessing.heading')}
      </Text>

      <UnorderedList px="md">
        <ListItem>
          <Text>
            {t('onboarding:AcquisitionDecisionProcessing.list.item1')}
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            {t('onboarding:AcquisitionDecisionProcessing.list.item2')}
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            {t('onboarding:AcquisitionDecisionProcessing.list.item3')}
          </Text>
        </ListItem>
      </UnorderedList>
      <Stack gap="sm" width="100%" maxWidth="376px" py="md" px="md">
        <Progress
          size="xs"
          isIndeterminate={isLoading}
          value={progressPercentage}
          colorScheme="mld.progress.primary"
          bg="mld.neutral.800"
          sx={{
            '& > div:first-child': {
              transitionProperty: 'width',
            },
          }}
        />
        {progressPercentage && (
          <Text
            textStyle="headline5"
            color="mld.neutral.200"
            textAlign="center"
          >
            {`${progressPercentage}%`}
          </Text>
        )}
      </Stack>
    </Flex>
  )
}

export default Processing
