import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Box, Text, Image} from '@chakra-ui/react'
import {Screen, LogoHorizontal} from '../../../components'
import XeroSmall from '../../../../../assets/images/xero_small.png'
import QuickbooksSmall from '../../../../../assets/images/quickbooks_small.png'
import MyobSmall from '../../../../../assets/images/myob-logo.svg'
import oAuthLoginRedirect from '../../../../utils/oAuthLoginRedirect'

const AccountingProviderReconnect = ({supplierNames, accountingProvider}) => {
  const accountingImage = () => {
    let image
    switch (accountingProvider) {
      case 'MYOB':
        image = MyobSmall
        break
      case 'Quickbooks':
        image = QuickbooksSmall
        break
      case 'Xero':
        image = XeroSmall
        break
      default:
        image = XeroSmall
    }
    return image
  }

  return (
    <Screen>
      <Flex align="center" justify="center">
        <Box w="50%">
          <LogoHorizontal width={380} />
          <Text fontSize="md" mb="sm">
            We can&apos;t connect to your {accountingProvider} account,{' '}
            {supplierNames} :(
          </Text>
          <Text mb="xs">To continue using Marmalade,</Text>
          <Text mb="xs">
            please reconnect your {accountingProvider} account.
          </Text>

          <Box
            borderColor="neutral.10"
            borderWidth="1px"
            display="inline-flex"
            alignItems="center"
            w="100%"
            h="4.6rem"
            px={4}
            py={2}
            mb={3}
            opacity={1}
            borderRadius="lg"
            as="button"
            onClick={() => {
              oAuthLoginRedirect(accountingProvider)
            }}
          >
            <Image
              src={accountingImage()}
              w="40px"
              h="40px"
              alt="xero"
              mr={3}
            />
            <Text>Connect to {accountingProvider}</Text>
          </Box>
        </Box>
      </Flex>
    </Screen>
  )
}

AccountingProviderReconnect.propTypes = {
  supplierNames: PropTypes.string.isRequired,
  accountingProvider: PropTypes.string.isRequired,
}

export default AccountingProviderReconnect
