import React from 'react'
import PropTypes from 'prop-types'
import {Box, useTheme} from '@chakra-ui/react'
import {ChevronDownIcon} from '@chakra-ui/icons'
import Select, {createFilter, components} from 'react-select'
import AsyncSelect from 'react-select/async'
import {VariableSizeList as List} from 'react-window'
import {ArrowDownIcon} from '../../icons/ArrowDownIcon'

const VirtualizedMenuList = ({options, children, maxHeight}) => {
  const GROUP_HEADER_HEIGHT = 38
  const OPTION_HEIGHT = 45

  function getOptionSize(option) {
    if (option.options) {
      return option.options.length * OPTION_HEIGHT + GROUP_HEADER_HEIGHT
    }

    return OPTION_HEIGHT
  }

  function getItemSize(i) {
    return getOptionSize(options[i])
  }

  const totalHeight = options.reduce((height, option) => {
    return height + getOptionSize(option)
  }, 0)

  const estimatedItemSize = totalHeight / options.length

  return Array.isArray(children) ? (
    <List
      height={Math.min(totalHeight, maxHeight) + 5}
      itemCount={children.length}
      // eslint-disable-next-line react/jsx-no-bind
      itemSize={getItemSize}
      estimatedItemSize={estimatedItemSize}
      zIndex="dropdown"
    >
      {({style, index}) => <div style={style}>{children[index]}</div>}
    </List>
  ) : (
    <div>{children}</div>
  )
}

VirtualizedMenuList.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxHeight: PropTypes.number.isRequired,
}

const DropdownIndicator = ({selectProps, variant}) => {
  const theme = useTheme()
  let idleColorState = theme.colors.neutral['80']
  let focusedBorderColor = theme.colors.primary.actionblue
  const rightPadding = '12px'
  if (
    variant === 'sidenav' ||
    variant === 'onboardingSidenav' ||
    variant === 'acquisitionDecision'
  ) {
    idleColorState = theme.colors.mld.sideNav.color
    focusedBorderColor = theme.colors.mld.sideNav.color
  }

  return (
    <Box
      color={selectProps.menuIsOpen ? focusedBorderColor : idleColorState}
      paddingRight={rightPadding}
    >
      <Box transform={selectProps.menuIsOpen ? 'rotate(180deg)' : ''}>
        {variant === 'onboardingSidenav' ? (
          <ArrowDownIcon h="24px" w="24px" />
        ) : (
          <ChevronDownIcon
            h="24px"
            w="24px"
            color={variant === 'mld' ? 'black' : ''}
          />
        )}
      </Box>
    </Box>
  )
}

DropdownIndicator.defaultProps = {
  variant: 'primary',
}

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool.isRequired,
  }).isRequired,
  variant: PropTypes.oneOf(['primary', 'sidenav', 'onboardingSidenav', 'mld']),
}

const DropdownStyles = (variant) => {
  const theme = useTheme()

  return {
    menu: (styles) => {
      let {borderColor} = styles
      if (variant === 'sidenav' || variant === 'onboardingSidenav') {
        borderColor = theme.colors.mld.neutral['800']
      }
      if (variant === 'mld') {
        borderColor = theme.colors.mld.neutral['700']
      }

      return {
        ...styles,
        padding: '0 8px',
        border: `1px solid ${borderColor}`,
        boxShadow: 'none',
        zIndex: theme.zIndices.dropdown,
      }
    },
    valueContainer: (styles) => {
      const padding = '0 14px'
      let textTransform = ''
      if (variant === 'onboardingSidenav') {
        textTransform = 'uppercase'
      }
      return {
        ...styles,
        padding,
        textTransform,
      }
    },
    noOptionsMessage: (styles) => {
      return {
        ...styles,
        textAlign: 'left',
      }
    },
    option: (styles, {isDisabled}) => {
      return {
        ...styles,
        borderRadius: '5px',
        color: theme.colors.neutral.black,
        backgroundColor: 'transparent',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        opacity: isDisabled ? 0.5 : 1,
        ':hover': {
          backgroundColor: isDisabled ? 'transparent' : '#F4F4F5',
        },
      }
    },
    control: (styles, {isFocused}) => {
      let idleBorderColor = styles.borderColor
      let focusedBorderColor = theme.colors.primary.actionblue
      let focusedBoxShadow = '0 0 0 3px rgba(73, 70, 233, 0.2)'
      let hoverBorderColor = theme.colors.primary.actionblue
      let hoverBackgroundColor = ''
      let {backgroundColor} = styles
      let {borderWidth} = styles

      if (variant === 'sidenav' || variant === 'onboardingSidenav') {
        focusedBorderColor = theme.colors.mld.neutral['700']
        idleBorderColor = theme.colors.mld.neutral['800']
        focusedBoxShadow = `0 0 0 3px ${theme.colors.mld.neutral['700']}50`
        hoverBorderColor = theme.colors.mld.sideNav.btnHoverBg
        hoverBackgroundColor = theme.colors.mld.sideNav.btnHoverBg
        backgroundColor = theme.colors.mld.sideNav.background
        borderWidth = '0'
      }
      if (variant === 'acquisitionDecision') {
        idleBorderColor = 'transparent'
        backgroundColor = 'transparent'
        focusedBorderColor = theme.colors.mld.neutral['700']
        focusedBoxShadow = `0 0 0 3px ${theme.colors.mld.neutral['700']}50`
        hoverBorderColor = theme.colors.mld.sideNav.btnHoverBg
      }
      if (variant === 'mld') {
        focusedBorderColor = theme.colors.mld.neutral['700']
        focusedBoxShadow = `0 0 0 3px ${theme.colors.mld.primary['100']}50`
        hoverBorderColor = theme.colors.mld.neutral['700']
      }

      return {
        ...styles,
        borderRadius: '6px',
        height: variant === 'sort' ? 'auto' : '48px',
        minWidth: variant === 'sort' ? '200px' : 'auto',
        boxShadow: isFocused ? focusedBoxShadow : styles.boxShadow,
        borderColor: isFocused ? focusedBorderColor : idleBorderColor,
        backgroundColor,
        borderWidth,
        ':hover': {
          ...styles[':hover'],
          backgroundColor: hoverBackgroundColor,
          borderColor: hoverBorderColor,
        },
      }
    },
    indicatorSeparator: (styles) => {
      return {
        ...styles,
        backgroundColor: 'transparent',
      }
    },
    singleValue: (styles) => {
      let {color} = styles
      if (
        variant === 'sidenav' ||
        variant === 'onboardingSidenav' ||
        variant === 'acquisitionDecision'
      ) {
        color = theme.colors.mld.sideNav.color
      }
      return {
        ...styles,
        color,
      }
    },
  }
}

DropdownStyles.defaultProps = {
  variant: 'primary',
}

DropdownStyles.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'sidenav',
    'onboardingSidenav',
    'mld',
    'acquisitionDecision',
  ]),
}

export const AsyncDropdownSelect = ({
  value,
  loadOptions,
  onChange,
  placeholder,
  name,
  isClearable,
  noOptionsMessage,
  variant,
  virtualized,
}) => {
  return (
    <AsyncSelect
      styles={DropdownStyles(variant)}
      components={{
        MenuList: virtualized ? VirtualizedMenuList : components.MenuList,
        // eslint-disable-next-line react/no-unstable-nested-components
        DropdownIndicator: (props) => (
          <DropdownIndicator {...props} variant={variant} />
        ),
      }}
      loadOptions={loadOptions}
      classNamePrefix={`dd-select-${name}`}
      isClearable={isClearable}
      noOptionsMessage={noOptionsMessage}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  )
}

const DropdownSelect = ({
  value,
  options,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  name,
  isSearchable,
  isClearable,
  variant,
  virtualized,
}) => {
  return (
    <Select
      styles={DropdownStyles(variant)}
      components={{
        MenuList: virtualized ? VirtualizedMenuList : components.MenuList,
        // eslint-disable-next-line react/no-unstable-nested-components
        DropdownIndicator: (props) => (
          <DropdownIndicator {...props} variant={variant} />
        ),
      }}
      classNamePrefix={`dd-select-${name}`}
      filterOption={createFilter({ignoreAccents: false})}
      options={options}
      isOptionDisabled={(option) => option.isDisabled}
      isSearchable={isSearchable}
      isClearable={isClearable}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}

AsyncDropdownSelect.defaultProps = {
  value: undefined,
  loadOptions: () => {},
  onChange: () => {},
  noOptionsMessage: () => {
    return 'Start typing to search...'
  },
  isClearable: true,
  variant: 'primary',
  virtualized: false,
}

AsyncDropdownSelect.propTypes = {
  loadOptions: PropTypes.func,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func,
  noOptionsMessage: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'sort', 'sidenav']),
  virtualized: PropTypes.bool,
}

DropdownSelect.defaultProps = {
  options: [],
  value: undefined,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  isSearchable: true,
  isClearable: true,
  variant: 'primary',
  virtualized: false,
}

const optionShape = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

  value: PropTypes.string.isRequired,
})

DropdownSelect.propTypes = {
  options: PropTypes.arrayOf(optionShape),
  value: optionShape,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'sidenav',
    'onboardingSidenav',
    'mld',
    'acquisitionDecision',
  ]),
  virtualized: PropTypes.bool,
}

export default DropdownSelect
