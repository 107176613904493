import {theme} from '@chakra-ui/react'
import {colorMode, colors} from '../foundations/colors'

const sharedProps = {
  textStyle: 'button',
  fontWeight: 'bold',
  borderRadius: 'full',
}

const focusShadow = `0 0 0 3px ${colors.mld.primary[200]}80`

export default {
  baseStyle: {
    fontWeight: 'normal',
  },
  sizes: {
    lg: {fontSize: 'base'},
    md: {fontSize: 'sm', px: 5},
    sm: {fontSize: 'sm', px: 4},
    xs: {px: 3},
  },
  variants: {
    primary: (props) => {
      const {colorScheme: c} = props

      const colorScheme = ['primary', 'danger', 'black', 'white'].includes(c)
        ? c
        : 'black'

      const colorProps = {
        primary: {
          bg: 'mld.primary.200',
          color: 'mld.neutral.900',
          hoverBg: 'mld.primary.70',
          activeBg: 'mld.primary.400',
          disabledBg: 'mld.neutral.200',
          disabledColor: 'mld.neutral.700',
        },
        danger: {
          bg: 'mld.alert.700',
          color: 'mld.neutral.50',
          hoverBg: 'mld.alert.800',
          activeBg: 'mld.alert.900',
          disabledBg: colorMode('mld.neutral.700', 'mld.neutral.200')(props),
          disabledColor: colorMode('mld.neutral.800', 'mld.neutral.700')(props),
        },
        black: {
          bg: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
          color: colorMode('mld.neutral.50', 'mld.neutral.900')(props),
          hoverBg: colorMode('mld.neutral.800', 'mld.neutral.500')(props),
          activeBg: colorMode('mld.neutral.1000', 'mld.neutral.100')(props),
          disabledBg: colorMode('mld.neutral.200', 'mld.neutral.700')(props),
          disabledColor: colorMode('mld.neutral.700', 'mld.neutral.800')(props),
        },
        white: {
          bg: colorMode('mld.neutral.50', 'mld.neutral.900')(props),
          color: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
          hoverBg: colorMode('mld.neutral.500', 'mld.neutral.800')(props),
          activeBg: colorMode('mld.neutral.100', 'mld.neutral.1000')(props),
          disabledBg: colorMode('mld.neutral.700', 'mld.neutral.200')(props),
          disabledColor: colorMode('mld.neutral.800', 'mld.neutral.700')(props),
        },
      }[colorScheme]

      return {
        ...sharedProps,
        bg: colorProps.bg,
        color: colorProps.color,
        _focusVisible: {
          boxShadow: focusShadow,
        },
        _hover: {
          bg: colorProps.hoverBg,
          _disabled: {
            bg: colorProps.disabledBg,
          },
        },
        _active: {
          bg: colorProps.activeBg,
        },
        _disabled: {
          bg: colorProps.disabledBg,
          color: colorProps.disabledColor,
        },
      }
    },
    secondary: (props) => ({
      ...sharedProps,
      // Secondary extends the default outline variant
      ...theme.components.Button.variants.outline(props),
      bg: colorMode('mld.neutral.50', 'mld.neutral.900')(props),
      color: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
      borderColor: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
      _focusVisible: {
        boxShadow: focusShadow,
      },
      _hover: {
        bg: 'mld.primary.200',
        color: 'mld.neutral.900',
        _disabled: {
          bg: 'mld.neutral.200',
        },
      },
      _active: {
        bg: 'mld.primary.400',
        color: 'mld.neutral.900',
      },
      _disabled: {
        bg: colorMode('mld.neutral.50', 'mld.neutral.900')(props),
        color: 'mld.neutral.700',
      },
    }),
    tertiary: (props) => ({
      ...sharedProps,
      // Tertiary extends the default ghost variant
      ...theme.components.Button.variants.ghost(props),
      color: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
      _focusVisible: {
        boxShadow: focusShadow,
      },
      _hover: {
        bg: colorMode('mld.neutral.200', 'mld.neutral.800')(props),
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: colorMode('mld.neutral.500', 'mld.neutral.1000')(props),
      },
      _disabled: {
        color: 'mld.neutral.700',
      },
    }),
  },
}
