import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text, Image, Button, CloseButton} from '@chakra-ui/react'
import {TickedOutlineIcon} from '../../icons'
import {toCurrency} from '../../../utils/formatter'
import ThumbsUp from '../../../../assets/images/thumbs_up.svg'
import CustomerDetailsForm from '../CustomerDetailsForm'

export const SuccessScreen = ({selectedInvoice, customer, handleClose}) => {
  return (
    <>
      <Box
        fontSize="lg"
        px="0"
        mx="sm"
        borderBottom="1px solid"
        borderColor="neutral.glitter"
      >
        <CloseButton onClick={handleClose} margin="0 0 0 auto" fontSize="sm" />
        <Text
          textStyle="headline3"
          fontWeight="bold"
          width="full"
          textAlign="center"
          py="sm"
        >
          Invoice reissued!
        </Text>
      </Box>

      <Box px="sm">
        <Image
          maxWidth="150px"
          margin="0 auto"
          my="sm"
          borderTopRadius="lg"
          src={ThumbsUp}
        />
        <Box
          display="flex"
          backgroundColor="background.green"
          p="sm"
          my="sm"
          borderRadius="lg"
        >
          <TickedOutlineIcon
            mr="xs"
            my="auto"
            color="secondary.green"
            fontSize="md"
          />
          <Text textStyle="body-copy" data-testid="reissuance-success-info">
            This invoice is reissued and sent to the customer with Marmalade
            payment details.
          </Text>
        </Box>
        <Box
          border="1px solid"
          borderColor="neutral.glitter"
          p="md"
          my="sm"
          borderRadius="lg"
        >
          {customer && (
            <CustomerDetailsForm
              selectedInvoice={selectedInvoice}
              customer={customer}
              displayForm={false}
            />
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            pt="md"
            borderTop="1px solid"
            borderColor="neutral.glitter"
          >
            <Text width="25%" />
            <Text textStyle="body-small" color="grey.700">
              Invoice total
            </Text>
            <Text textStyle="headline3" text-align="right" fontWeight="bold">
              {toCurrency(selectedInvoice.due_amount.cents)}
            </Text>
          </Box>
        </Box>
        <Box margin="0 auto" width="fit-content" mb="md">
          <Button variant="secondary" onClick={handleClose}>
            Done
          </Button>
        </Box>
      </Box>
    </>
  )
}

SuccessScreen.defaultProps = {
  handleClose: () => {},
  customer: null,
}

SuccessScreen.propTypes = {
  selectedInvoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    due_amount: PropTypes.shape({
      cents: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    number: PropTypes.string.isRequired,
    due_date: PropTypes.number.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    businessName: PropTypes.string,
    abn: PropTypes.string,
    email: PropTypes.string,
  }),
  handleClose: PropTypes.func,
}

export default SuccessScreen
