import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {get} from 'lodash-es'
import pluralize from 'pluralize'
import {Box, Button, Divider, Flex, Skeleton, Text} from '@chakra-ui/react'
import {fetcher} from '../../../../../../api'
import {
  camelizeResult,
  dineroMoneyObjects,
} from '../../../../../../api/serializer'
import {useRails} from '../../../../../contexts/rails'
import IconTooltip from '../../../../../components/IconTooltip'

const ValueCard = ({title, tooltip, count, amount, dataTestId}) => {
  return (
    <Box minWidth="172px">
      <Flex alignItems="center">
        <Text fontSize="sm">{title}</Text>
        <IconTooltip iconSize="1em">{tooltip}</IconTooltip>
      </Flex>
      <Text fontSize="lg" fontWeight="bold" data-testid={dataTestId}>
        {amount}
      </Text>
      <Text fontSize="sm" fontWeight="bold" color="grey.700">
        {count} {pluralize('invoice', count)}
      </Text>
    </Box>
  )
}

ValueCard.defaultProps = {
  count: null,
  amount: '',
}

ValueCard.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  count: PropTypes.number,
  amount: PropTypes.string,
}

const UnpaidInvoicesCard = () => {
  const {organisation} = useRails()
  const {isLoading, data} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/unpaid_invoices_summary`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const {eligibleCount, conditionallyEligibleCount} = data || {}
  const hasInvoicesToCashin = !!(eligibleCount || conditionallyEligibleCount)

  return (
    <Box
      display="flex"
      flexDirection="column"
      bg="neutral.white"
      p="md"
      border="1px solid"
      borderColor="neutral.glitter"
      borderRadius="lg"
      data-testid="unpaid-invoices-card"
    >
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        mb="sm"
      >
        <Text fontSize="md" fontWeight="bold">
          Unpaid invoices
        </Text>
        <Skeleton isLoaded={!isLoading}>
          <Button as="a" href="/cashin" variant="secondary" size="sm">
            {hasInvoicesToCashin ? 'Cash-in now' : 'View Cash-in page'}
          </Button>
        </Skeleton>
      </Box>

      <Box
        bg="neutral.100"
        borderRadius="lg"
        p="20px"
        display="flex"
        flexDirection={['column', 'row']}
      >
        <ValueCard
          title="Total unpaid"
          tooltip="The sum of all unpaid invoices for your business."
          dataTestId="total-unpaid-amount"
          amount={get(data, 'totalAmount')?.toFormat('$0,0')}
          count={get(data, 'totalCount')}
        />
        <Divider
          orientation={['horizontal', 'vertical']}
          height="auto"
          mx={[0, 'sm']}
          my={['sm', 0]}
          borderWidth={['1px 0 0 0', '0 0 0 1px']}
          borderColor="black"
        />
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap"
          rowGap="sm"
        >
          <ValueCard
            title="Available now"
            tooltip="Unpaid invoices amount that is available for you to cash-in."
            dataTestId="eligible-amount"
            amount={get(data, 'eligibleAmount')?.toFormat('$0,0')}
            count={eligibleCount}
          />
          <ValueCard
            title="Conditionally available"
            tooltip="Unpaid invoice amount that is available for you to cash-in by either reissuing or providing customer information."
            dataTestId="conditionally-available-amount"
            amount={get(data, 'conditionallyEligibleAmount')?.toFormat('$0,0')}
            count={conditionallyEligibleCount}
          />
          <ValueCard
            title="Not available"
            tooltip="Unpaid invoices amount that is not available to cash-in due to reasons detailed on the Cash-in page."
            dataTestId="ineligible-amount"
            amount={get(data, 'ineligibleAmount')?.toFormat('$0,0')}
            count={get(data, 'ineligibleCount')}
          />
          <ValueCard
            title="Cashed-in"
            tooltip="Unpaid invoices that have been cashed-in."
            dataTestId="cashed-in-amount"
            amount={get(data, 'cashedInAmount')?.toFormat('$0,0')}
            count={get(data, 'cashedInCount')}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default UnpaidInvoicesCard
