import React from 'react'
import PropTypes from 'prop-types'
import {
  CloseButton,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import LearnMoreView from '../../../../../components/AddSupplierBankAccountIntegrationModal/Views/LearnMoreView'

const LearnMoreModal = ({isOpen, onClose, onBankConnectionModalOpen}) => {
  const startConnecting = () => {
    onClose()
    onBankConnectionModalOpen()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      onClose={onClose}
      closeOnOverlayClick={false}
      preserveScrollBarGap
    >
      <ModalOverlay zIndex="modal" />
      <ModalContent borderRadius="lg" py="sm">
        <ModalHeader fontSize="lg" py="sm">
          <HStack justifyContent="space-between">
            <Text>Learn more about bank connection</Text>
            <CloseButton
              onClick={onClose}
              data-testid="close-button"
              size="md"
              pb={0}
            />
          </HStack>
        </ModalHeader>
        <ModalBody px="sm">
          <LearnMoreView
            isLoading={false}
            startConnecting={startConnecting}
            tokenLoaded
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

LearnMoreModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onBankConnectionModalOpen: () => {},
}

LearnMoreModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onBankConnectionModalOpen: PropTypes.func,
}

export default LearnMoreModal
