/** @jsx jsx */
/* eslint-disable-next-line */
import {css, jsx} from '@emotion/react'

/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

const Screen = (props) => (
  <div
    css={css`
      max-width: 72rem;
      margin: 0 auto;
      padding: 0 20px;
    `}
  >
    {props.children}
  </div>
)

export default Screen
