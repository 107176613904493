import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text, Image, VisuallyHidden, Button} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import quickbooksLogo from '../../../../../../../assets/images/quickbooks-logo.svg'
import myobLogo from '../../../../../../../assets/images/myob-logo.svg'
import xeroLogo from '../../../../../../../assets/images/xero-logo.svg'
import {RadioCard, RadioCardGroup} from '../../../../../components'
import {ArrowRightIcon} from '../../../../../icons'

const AccountIntegrationSelector = ({
  goToNextPage,
  setAccountingProvider,
  accountingProvider,
}) => {
  const {t} = useTranslation('onboarding')

  return (
    <>
      <Text
        color="mld.neutral.900"
        fontSize="48px"
        letterSpacing="-0.5"
        lineHeight="1"
        fontWeight="400"
        mb="md"
      >
        {t('onboarding:AccountIntegrationSelector.heading')}
      </Text>

      <Text textStyle="body-intro" my="sm">
        {t('onboarding:AccountIntegrationSelector.body')}
      </Text>

      <Box mb="xs" width={{base: 'full', lg: 'fit-content'}}>
        <RadioCardGroup
          value={accountingProvider}
          onChange={(event) => setAccountingProvider(event.target.value)}
          columns={[1, 2]}
        >
          <RadioCard
            value="Xero"
            opacity={
              accountingProvider && accountingProvider !== 'Xero' ? 0.5 : 1
            }
            width={{base: 'auto', lg: '224px'}}
            checkedBorderColor="mld.neutral.900"
            checkedBorderWidth="2px"
            checkedBackgroundColor="white"
            focusShadow="0 0 0 3px #F75E15"
            height="120px"
            borderRadius="0"
          >
            <Image src={xeroLogo} width="80px" alt="xero" />
            <VisuallyHidden>Xero</VisuallyHidden>
          </RadioCard>

          <RadioCard
            value="MYOB"
            opacity={
              accountingProvider && accountingProvider !== 'MYOB' ? 0.5 : 1
            }
            width={{base: 'auto', lg: '224px'}}
            checkedBorderColor="mld.neutral.900"
            checkedBorderWidth="2px"
            checkedBackgroundColor="white"
            focusShadow="0 0 0 3px #F75E15"
            height="120px"
            borderRadius="0"
          >
            <Image src={myobLogo} width="136px" alt="MYOB" />
            <VisuallyHidden>MYOB</VisuallyHidden>
          </RadioCard>

          <RadioCard
            value="Quickbooks"
            opacity={
              accountingProvider && accountingProvider !== 'Quickbooks'
                ? 0.5
                : 1
            }
            width={{base: 'auto', lg: '224px'}}
            checkedBorderColor="mld.neutral.900"
            checkedBorderWidth="2px"
            checkedBackgroundColor="white"
            focusShadow="0 0 0 3px #F75E15"
            height="120px"
            borderRadius="0"
          >
            <Image
              src={quickbooksLogo}
              alt="quickbooks"
              width="174px"
              height="48px"
            />
            <VisuallyHidden>Quickbooks</VisuallyHidden>
          </RadioCard>
        </RadioCardGroup>
      </Box>
      <Text textStyle="body-detail">
        {t('onboarding:AccountIntegrationSelector.otherCaption')}
      </Text>

      <Button
        isDisabled={!accountingProvider}
        variant="primary"
        colorScheme="primary"
        onClick={goToNextPage}
        data-testid="continue-button"
        mt="md"
        type="submit"
        rightIcon={<ArrowRightIcon width="24px" height="24px" />}
      >
        {t('onboarding:AccountIntegrationSelector.submitButton')}
      </Button>
    </>
  )
}

AccountIntegrationSelector.defaultProps = {
  accountingProvider: '',
  goToNextPage: () => {},
  setAccountingProvider: () => {},
}

AccountIntegrationSelector.propTypes = {
  accountingProvider: PropTypes.string,
  goToNextPage: PropTypes.func,
  setAccountingProvider: PropTypes.func,
}

export default AccountIntegrationSelector
