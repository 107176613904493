import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text} from '@chakra-ui/react'

import CopyButton from '../CopyButton'
import {Card} from '../Card'

export const BankAccountCard = ({
  accountName,
  accountNumber,
  bsb,
  afterCopy,
  ...props
}) => (
  <Card display="flex" whiteSpace="nowrap" {...props}>
    <Box flexGrow="1" minWidth="0px">
      <Text fontSize="sm" color="neutral.60">
        Account name
      </Text>

      <Text fontWeight="bold" whiteSpace="normal">
        {accountName}
      </Text>

      <Box display="flex" flexWrap="wrap">
        <Box mt="xs" flexBasis="50%">
          <Text fontSize="sm" color="neutral.60">
            BSB
          </Text>

          <Text fontWeight="bold">{bsb}</Text>
        </Box>

        <Box mt="xs" flexBasis="50%">
          <Text fontSize="sm" color="neutral.60">
            Account&nbsp;number
          </Text>

          <Text fontWeight="bold">{accountNumber}</Text>
        </Box>
      </Box>
    </Box>

    <Box mx="sm" mt="xs">
      <CopyButton
        color="primary.500"
        label="Copy account details"
        content={`Account name\n${accountName}\n\nBSB\n${bsb}\n\nAccount number\n${accountNumber}`}
        afterCopy={afterCopy}
      />
    </Box>
  </Card>
)

BankAccountCard.defaultProps = {
  afterCopy: () => {},
}

BankAccountCard.propTypes = {
  accountName: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  bsb: PropTypes.string.isRequired,
  afterCopy: PropTypes.func,
}

export const PayidCard = ({payid, ...props}) => (
  <Card display="flex" alignItems="center" whiteSpace="nowrap" {...props}>
    <Box flexGrow="1">
      <Text fontSize="sm" color="neutral.60">
        PayID
      </Text>

      <Text fontWeight="bold">{payid}</Text>
    </Box>

    <Box mx="sm">
      <CopyButton label="Copy PayID" content={`PayID\n${payid}`} />
    </Box>
  </Card>
)

PayidCard.propTypes = {
  payid: PropTypes.string.isRequired,
}
