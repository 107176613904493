import {colors} from '../foundations/colors'

export default {
  variants: {
    primary: {
      textStyle: 'body-small',
      color: colors.mld.neutral[900],
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      textDecorationColor: colors.mld.neutral[900],
      _hover: {
        textDecorationColor: colors.mld.primary[100],
      },
      _focus: {
        textDecorationColor: colors.mld.primary[100],
      },
      _active: {
        color: colors.mld.primary[100],
        textDecorationColor: colors.mld.primary[100],
      },
    },
  },
}
