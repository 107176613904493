import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CloseButton,
  CheckboxGroup,
  Stack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  ButtonGroup,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Button,
} from '@chakra-ui/react'
import Checkbox from '../../../components/Checkbox'
import {DatePicker, Input} from '../../../components'
import {toCurrency} from '../../../../utils/formatter'
import CustomerFilter from '../../../components/CustomerFilter'
import InvoiceFilter from '../../../components/InvoiceFilter'

const initialFilterOptionState = {
  customer: undefined,
  invoice: undefined,
  dueAmount: {
    from: 0,
    to: 0,
    fromError: '',
    toError: '',
  },
  issueDate: {
    from: 0,
    to: 0,
  },
  dueDate: {
    from: 0,
    to: 0,
  },
  daysPastDueDate: {
    value: undefined,
  },
  cashinStatus: [],
  paymentStatus: [],
  dueDateStatus: [],
}

const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED'
const INVOICE_SELECTED = 'INVOICE_SELECTED'
const ISSUE_DATE_UPDATED = 'ISSUE_DATE_UPDATED'
const DUE_DATE_UPDATED = 'DUE_DATE_UPDATED'
const FROM_DUE_AMOUNT_UPDATED = 'FROM_DUE_AMOUNT_UPDATED'
const TO_DUE_AMOUNT_UPDATED = 'TO_DUE_AMOUNT_UPDATED'
const DAYS_PAST_DUE_DATE_UPDATED = 'DAYS_PAST_DUE_DATE_UPDATED'
const CASHIN_UPDATED = 'CASHIN_UPDATED'
const PAYMENT_STATUS_UPDATED = 'PAYMENT_STATUS_UPDATED'
const DUEDATE_STATUS_UPDATED = 'DUEDATE_STATUS_UPDATED'
const RESET = 'RESET'

const filterOptionReducer = (state, action) => {
  let fromError = ''
  let toError = ''
  const {payload, type} = action
  switch (type) {
    case CUSTOMER_SELECTED:
      return {...state, customer: payload.customer}
    case INVOICE_SELECTED:
      return {...state, invoice: payload.invoice}
    case ISSUE_DATE_UPDATED:
      return {...state, issueDate: {...state.issueDate, ...payload.issueDate}}
    case DUE_DATE_UPDATED:
      return {...state, dueDate: {...state.dueDate, ...payload.dueDate}}
    case FROM_DUE_AMOUNT_UPDATED:
      fromError =
        payload.dueAmount.from > state.dueAmount.to
          ? 'Min amount is greater than Max amount'
          : ''

      return {
        ...state,
        dueAmount: {
          ...state.dueAmount,
          ...payload.dueAmount,
          fromError,
          toError: '',
        },
      }
    case TO_DUE_AMOUNT_UPDATED:
      toError =
        payload.dueAmount.to < state.dueAmount.from
          ? 'Max amount is less than Min amount'
          : ''

      return {
        ...state,
        dueAmount: {
          ...state.dueAmount,
          ...payload.dueAmount,
          fromError: '',
          toError,
        },
      }
    case DAYS_PAST_DUE_DATE_UPDATED:
      return {...state, daysPastDueDate: payload.daysPastDueDate}
    case CASHIN_UPDATED:
      return {...state, cashinStatus: payload.cashinStatus}
    case PAYMENT_STATUS_UPDATED:
      return {...state, paymentStatus: payload.paymentStatus}
    case DUEDATE_STATUS_UPDATED:
      return {...state, dueDateStatus: payload.dueDateStatus}
    case RESET: {
      return initialFilterOptionState
    }
    default:
      return state
  }
}

const initStateFn = (state) => ({...initialFilterOptionState, ...state})

const InvoiceFilterOptions = ({
  filterOption,
  onClickApply,
  onClickReset,
  isOpen,
  onClose,
}) => {
  const [state, dispatch] = React.useReducer(
    filterOptionReducer,
    filterOption,
    initStateFn,
  )

  return (
    <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
      <DrawerOverlay />

      <DrawerContent data-testid="section-filters">
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="xs"
          >
            <Text fontSize="md">Filter invoices</Text>

            <CloseButton onClick={onClose} tabIndex="-1" />
          </Box>
        </DrawerHeader>

        <DrawerBody overflowY="scroll">
          <Box mb="sm">
            <CustomerFilter
              value={state.customer}
              onChange={(value) =>
                dispatch({type: CUSTOMER_SELECTED, payload: {customer: value}})
              }
            />
          </Box>

          <Box mb="sm">
            <InvoiceFilter
              value={state.invoice}
              onChange={(value) =>
                dispatch({type: INVOICE_SELECTED, payload: {invoice: value}})
              }
            />
          </Box>

          <Text mb="sm" color="neutral.80" fontWeight="bold">
            Issue date
          </Text>

          <Box
            display="flex"
            justifyContent="space-between"
            data-testid="filter-issuedate-range"
          >
            <DatePicker
              selectedDate={state.issueDate.from}
              placeholder="Date from"
              maxDate={state.issueDate.to}
              onChange={(value) =>
                dispatch({
                  type: ISSUE_DATE_UPDATED,
                  payload: {issueDate: {from: (value && value.getTime()) || 0}},
                })
              }
            />
            <DatePicker
              selectedDate={state.issueDate.to}
              placeholder="Date to"
              minDate={state.issueDate.from}
              onChange={(value) =>
                dispatch({
                  type: ISSUE_DATE_UPDATED,
                  payload: {issueDate: {to: (value && value.getTime()) || 0}},
                })
              }
            />
          </Box>

          <Text mb="sm" color="neutral.80" fontWeight="bold">
            Cash-in
          </Text>

          <CheckboxGroup
            name="cashin_status"
            defaultValue={state.cashinStatus}
            onChange={(value) =>
              dispatch({type: CASHIN_UPDATED, payload: {cashinStatus: value}})
            }
          >
            <Stack mb="md" data-testid="filter-cashin-status">
              <Checkbox value="false">
                <Text>Not cashed-in</Text>
              </Checkbox>
              <Checkbox value="true">
                <Text>Cashed-in</Text>
              </Checkbox>
            </Stack>
          </CheckboxGroup>

          <Text mb="sm" color="neutral.80" fontWeight="bold">
            Payment status
          </Text>

          <CheckboxGroup
            name="payment_status"
            defaultValue={state.paymentStatus}
            onChange={(value) =>
              dispatch({
                type: PAYMENT_STATUS_UPDATED,
                payload: {paymentStatus: value},
              })
            }
          >
            <Stack mb="md" data-testid="filter-payment-status">
              <Checkbox value="paid">
                <Text>Paid</Text>
              </Checkbox>
              <Checkbox value="partially_paid">
                <Text>Partially Paid</Text>
              </Checkbox>
              <Checkbox value="unpaid">
                <Text>Unpaid</Text>
              </Checkbox>
              <Checkbox value="pending">
                <Text>Pending</Text>
              </Checkbox>
            </Stack>
          </CheckboxGroup>

          <Text mb="sm" color="neutral.80" fontWeight="bold">
            Due date
          </Text>

          <CheckboxGroup
            name="duedate_status"
            defaultValue={state.dueDateStatus}
            onChange={(value) =>
              dispatch({
                type: DUEDATE_STATUS_UPDATED,
                payload: {dueDateStatus: value},
              })
            }
          >
            <Stack mb="md" data-testid="filter-duedate-status">
              <Checkbox value="overdue">
                <Text>Overdue</Text>
              </Checkbox>
            </Stack>
          </CheckboxGroup>

          <Flex align="center" justify="space-between">
            <Text mb="sm" color="neutral.80" fontWeight="bold">
              Days past due date
            </Text>
            <NumberInput
              name="daysPastDueDate"
              placeholder="Days"
              maxW="50%"
              min={1}
              value={state.daysPastDueDate.value}
              onChange={(value) => {
                const formatToDays = parseInt(value, 10)
                dispatch({
                  type: DAYS_PAST_DUE_DATE_UPDATED,
                  payload: {
                    daysPastDueDate: {value: formatToDays || undefined},
                  },
                })
              }}
            >
              <NumberInputField data-testid="filter-days-past-due-date" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Flex>

          <Text mb="sm" color="neutral.80" fontWeight="bold">
            Custom due date range
          </Text>
          <Box
            display="flex"
            justifyContent="space-between"
            data-testid="filter-duedate-range"
          >
            <DatePicker
              selectedDate={state.dueDate.from}
              placeholder="Date from"
              maxDate={state.dueDate.to}
              onChange={(value) =>
                dispatch({
                  type: DUE_DATE_UPDATED,
                  payload: {dueDate: {from: (value && value.getTime()) || 0}},
                })
              }
            />
            <DatePicker
              selectedDate={state.dueDate.to}
              placeholder="Date to"
              minDate={state.dueDate.from}
              onChange={(value) =>
                dispatch({
                  type: DUE_DATE_UPDATED,
                  payload: {dueDate: {to: (value && value.getTime()) || 0}},
                })
              }
            />
          </Box>
          <Text mb="sm" color="neutral.80" fontWeight="bold">
            Due amount
          </Text>

          <Box
            display="flex"
            justifyContent={{base: 'flex-start', sm: 'space-between'}}
            flexDirection={{base: 'column', sm: 'row'}}
          >
            <Box maxWidth={{base: '100%', sm: '150px'}}>
              <Input
                name="minInvoiceAmount"
                placeholder="Min amount"
                value={toCurrency(state.dueAmount.from)}
                errorMessage={state.dueAmount.fromError}
                onChange={(event) => {
                  const formatToCents = parseInt(
                    event.target.value.replace(/\D/g, ''),
                    10,
                  )

                  if (!Number.isNaN(formatToCents)) {
                    dispatch({
                      type: FROM_DUE_AMOUNT_UPDATED,
                      payload: {dueAmount: {from: formatToCents}},
                    })
                  }
                }}
              />
            </Box>

            <Box maxWidth={{base: '100%', sm: '150px'}}>
              <Input
                name="maxInvoiceAmount"
                placeholder="Max amount"
                value={toCurrency(state.dueAmount.to)}
                errorMessage={state.dueAmount.toError}
                onChange={(event) => {
                  const formatToCents = parseInt(
                    event.target.value.replace(/\D/g, ''),
                    10,
                  )

                  if (!Number.isNaN(formatToCents)) {
                    dispatch({
                      type: TO_DUE_AMOUNT_UPDATED,
                      payload: {dueAmount: {to: formatToCents}},
                    })
                  }
                }}
              />
            </Box>
          </Box>
        </DrawerBody>

        <DrawerFooter
          justifyContent="flex-start"
          boxShadow="0px 9px 32px rgba(23, 27, 30, 0.15)"
        >
          <ButtonGroup>
            <Button
              variant="primary"
              onClick={() => {
                onClickApply(state)
              }}
              size="lg"
            >
              Apply
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                dispatch({type: RESET})

                // Trigger update
                onClickReset(initialFilterOptionState)
              }}
              size="lg"
            >
              Clear all filters
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

InvoiceFilterOptions.defaultProps = {
  onClickApply: () => {},
  onClickReset: () => {},
  filterOption: {},
}

InvoiceFilterOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickApply: PropTypes.func,
  onClickReset: PropTypes.func,
  filterOption: PropTypes.shape({
    customer: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
    invoice: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
    dueAmount: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
    issueDate: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
    dueDate: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
    daysPastDueDate: PropTypes.shape({
      value: PropTypes.number,
    }),
    cashinStatus: PropTypes.arrayOf(PropTypes.oneOf(['true', 'false'])),
    paymentStatus: PropTypes.arrayOf(
      PropTypes.oneOf(['paid', 'partially_paid', 'pending', 'unpaid']),
    ),
    dueDateStatus: PropTypes.arrayOf(PropTypes.oneOf(['overdue'])),
  }),
}

export default InvoiceFilterOptions
