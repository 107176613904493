import React from 'react'
import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import {Text, Button} from '@chakra-ui/react'
import {
  EmptyListContainer,
  EmptyListHeaderTag,
} from '../../../../components/EmptyList'

const EmptyState = ({tabName, messageContext, messageCount, onClick}) => {
  return (
    <EmptyListContainer px="none">
      <EmptyListHeaderTag text="Check back soon" />
      <Text textStyle="body-small" my="xs" textAlign="center">
        <Trans
          i18nKey={`cashinPage:${tabName}.emptyContentMessage`}
          values={{count: messageCount, context: messageContext}}
        />
      </Text>
      {onClick && (
        <Button variant="secondary" onClick={onClick}>
          <Trans
            i18nKey={`cashinPage:${tabName}.emptyContentLink`}
            values={{context: messageContext}}
          />
        </Button>
      )}
    </EmptyListContainer>
  )
}

EmptyState.defaultProps = {
  messageCount: null,
  messageContext: 'noInvoices',
  onClick: null,
}

EmptyState.propTypes = {
  tabName: PropTypes.string.isRequired,
  messageContext: PropTypes.string,
  messageCount: PropTypes.number,
  onClick: PropTypes.func,
}

export default EmptyState
