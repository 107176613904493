/** @jsx jsx */
import React from 'react'
import {jsx} from '@emotion/react'
import PropTypes from 'prop-types'
import {sum, times, random, map, groupBy} from 'lodash-es'
import {AnimatePresence, motion} from 'framer-motion'
import {
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tfoot,
  useTheme,
  chakra,
} from '@chakra-ui/react'
import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons'
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'
import {columnsPropType, sortByOptionsPropType} from './propTypes'

const TableRow = ({data, onClickRow}) => {
  const onClickRowHandler = React.useCallback(() => {
    // Use original data supplied instead of displayed data
    if (onClickRow) onClickRow(data.original)
  }, [data, onClickRow])

  return (
    <Tr
      as={motion.tr}
      layout
      animate="open"
      exit="collapsed"
      variants={{
        open: {opacity: 1, height: 'auto'},
        collapsed: {opacity: 0, height: 0},
      }}
      transition={{duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98]}}
      onClick={onClickRowHandler}
      _hover={
        Boolean(onClickRow) && {
          backgroundColor: 'neutral.smoke',
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: 'neutral.glitter',
          cursor: 'pointer',
        }
      }
    >
      {data.getVisibleCells().map((cell) => {
        // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
        const {meta} = cell.column.columnDef
        return (
          <Td
            key={[data.original.id, cell.id].join('_')}
            isNumeric={meta?.isNumeric}
            display={{
              base: 'none',
              [cell.column.columnDef.hideAtBreakpoint || 'base']: 'table-cell',
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        )
      })}
    </Tr>
  )
}

TableRow.defaultProps = {
  onClickRow: undefined,
}

TableRow.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
      PropTypes.func,
    ]),
  ).isRequired,
  onClickRow: PropTypes.func,
}

const DataTable = ({
  data,
  columns,
  onClickRow,
  isLoading,
  enableSorting,
  onSort,
  sortByValue,
  manualSorting,
  chakraTableProps,
  groupByValue,
}) => {
  const [sorting, setSorting] = React.useState(sortByValue)

  // Memoize the table columns updating only when the columns themselves change.
  const tableColumns = React.useMemo(() => columns, [columns])
  const columnVisibility = {}
  tableColumns.forEach((column) => {
    columnVisibility[column.accessorKey] = !column.hidden
  })

  const {getHeaderGroups, getFooterGroups, getRowModel} = useReactTable({
    columns: tableColumns,
    data,
    manualSorting,
    enableSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      columnVisibility,
    },
  })

  React.useEffect(() => {
    onSort(sorting)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting])

  const {rows} = getRowModel()

  const groupedRows =
    rows && groupByValue
      ? groupBy(rows, (row) => row.getValue(groupByValue))
      : {}

  const headerGroups = getHeaderGroups()
  const footerGroups = getFooterGroups()

  const columnsLength = sum(
    headerGroups.map((headerGroup) => headerGroup.headers.length),
  )

  return (
    <Table {...chakraTableProps}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const {column} = header
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const {meta} = column.columnDef
              return (
                <Th
                  key={header.id}
                  colSpan={header.colSpan}
                  onClick={column.getToggleSortingHandler()}
                  isNumeric={meta?.isNumeric}
                  color={column.getIsSorted() ? 'neutral.black' : 'neutral.60'}
                  _hover={
                    column.getCanSort() && {
                      cursor: 'pointer',
                      color: 'neutral.black',
                    }
                  }
                >
                  {flexRender(column.columnDef.header, header.getContext())}
                  {column.getCanSort() && (
                    <chakra.span
                      pl="4"
                      visibility={column.getIsSorted() ? 'visible' : 'hidden'}
                    >
                      {column.getIsSorted() === 'desc' ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )}
                    </chakra.span>
                  )}
                </Th>
              )
            })}
          </Tr>
        ))}
      </Thead>

      {isLoading && <LoadingSkeleton columns={columnsLength} />}

      {rows.length > 0 && (
        <Tbody data-testid="table-body">
          <AnimatePresence>
            {Object.keys(groupedRows).length > 0 && (
              <>
                {map(groupedRows, (groupRows, groupValue) => (
                  <>
                    <Tr backgroundColor="neutral.200">
                      <Td
                        colspan={columnsLength}
                        py="xs"
                        fontSize="sm"
                        fontWeight="bold"
                        color="neutral.800"
                      >
                        {groupValue}
                      </Td>
                    </Tr>
                    {groupRows.map((row) => (
                      <TableRow
                        key={row.original.id || row.id}
                        data={row}
                        onClickRow={onClickRow}
                      />
                    ))}
                  </>
                ))}
              </>
            )}

            {Object.keys(groupedRows).length === 0 && (
              <>
                {rows.map((row) => (
                  <TableRow
                    key={row.original.id || row.id}
                    data={row}
                    onClickRow={onClickRow}
                  />
                ))}
              </>
            )}
          </AnimatePresence>
        </Tbody>
      )}

      <Tfoot data-testid="table-foot">
        {footerGroups.map(
          (footerGroup) =>
            !!footerGroup.headers.filter(
              (header) => header.column.columnDef.footer,
            ).length && (
              <Tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </Th>
                ))}
              </Tr>
            ),
        )}
      </Tfoot>
    </Table>
  )
}

DataTable.defaultProps = {
  data: [],
  onClickRow: undefined,
  onSort: () => {},
  isLoading: false,
  sortByValue: [],
  manualSorting: false,
  enableSorting: false,
  chakraTableProps: {},
  groupByValue: undefined,
}

DataTable.propTypes = {
  chakraTableProps: PropTypes.shape({
    size: PropTypes.string,
    variant: PropTypes.string,
  }),
  columns: columnsPropType.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
      PropTypes.bool,
      PropTypes.func,
    ]),
  ),
  onClickRow: PropTypes.func,
  isLoading: PropTypes.bool,
  enableSorting: PropTypes.bool,
  onSort: PropTypes.func,
  manualSorting: PropTypes.bool,
  sortByValue: sortByOptionsPropType,
  groupByValue: PropTypes.string,
}

export const LoadingSkeleton = ({rows, columns}) => {
  const theme = useTheme()

  return (
    <Tbody>
      {times(rows, (row) => (
        <Tr key={row}>
          {times(columns, (cell) => (
            <Td key={cell}>
              <Skeleton
                borderRadius="md"
                height="24px"
                width={random(0.5, 0.8)}
                startColor={theme.colors.skeleton.light}
                endColor={theme.colors.skeleton.dark}
              />
            </Td>
          ))}
        </Tr>
      ))}
    </Tbody>
  )
}

LoadingSkeleton.defaultProps = {
  rows: 10,
}

LoadingSkeleton.propTypes = {
  columns: PropTypes.number.isRequired,
  rows: PropTypes.number,
}

export default DataTable
