import React from 'react'
import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import dayjs from 'dayjs'
import {get} from 'lodash-es'
import pluralize from 'pluralize'
import {
  Box,
  Text,
  Flex,
  Link,
  UnorderedList,
  ListItem,
  Skeleton,
  Divider,
  Tag,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react'
import useSWR from 'swr'
import {ExternalLinkIcon} from '@chakra-ui/icons'
import {t} from 'i18next'
import {useRails} from '../../contexts/rails'
import {camelizeResult, dineroMoneyObjects} from '../../../api/serializer'
import {AltCheckIcon, StarIcon, SmallInverseTickedIcon} from '../../icons'
import CustomerRatingsPropType from '../../../utils/propTypes/customerRating'
import {toEpochMillisecond, toLocalDate} from '../../../utils/formatter'
import DineroPropShape from '../../../utils/propTypes/dinero'

const DetailSection = ({title, children}) => (
  <Flex
    direction="column"
    backgroundColor="neutral.100"
    p="sm"
    borderRadius="md"
    mb="md"
  >
    <Text textStyle="body-copy" color="neutral.700" fontWeight="bold" pb="sm">
      {title}
    </Text>
    {children}
  </Flex>
)

DetailSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export const DisplayInvoiceDetails = ({
  isLoading,
  showCashinStats,
  invoice,
  customerRating,
}) => {
  const {organisation} = useRails()

  const fixedPricingEnabled = get(
    organisation,
    'currentSupplier.fixedPricingEnabled',
    false,
  )

  const issueDate = new Date(toEpochMillisecond(invoice.issueDate || 0))
  const dueDate = new Date(toEpochMillisecond(invoice.dueDate || 0))
  const paymentTerms = dayjs(dueDate).diff(issueDate, 'day')

  const onInvoicesPage = window.location.toString().match(/\/invoices/)

  return (
    <>
      <DetailSection title="Customer">
        <Skeleton isLoaded={!isLoading}>
          <Text textStyle="body-copy" fontWeight="bold" color="grey.900">
            <Link
              href={`/customers/${invoice.customerId}`}
              isExternal
              color="primary.actionblue"
            >
              {invoice.contactName} <ExternalLinkIcon mx="2px" />
            </Link>
          </Text>
          {customerRating && (
            <>
              <Box
                bg="neutral.200"
                px="xxs"
                py="2px"
                mt="xxs"
                borderRadius="md"
                display="flex"
                alignItems="center"
                width="fit-content"
                aria-label={t('cashinPage:customerRatingScore', {
                  score: customerRating.score,
                })}
              >
                {[...Array(5).keys()].map((value) => (
                  <StarIcon
                    key={value}
                    color={
                      customerRating.score > value
                        ? 'secondary.yellow'
                        : 'grey.500'
                    }
                    h="12px"
                    w="12px"
                    mr="xxs"
                  />
                ))}
              </Box>
              <Text textStyle="body-detail" mt="sm">
                <Trans
                  i18nKey={`cashinPage:customer_rating_reasons.${customerRating.topReason.key}`}
                  values={{...customerRating.topReason.values}}
                />
              </Text>
              <Text textStyle="body-small" fontWeight="bold" mt="sm">
                {t(
                  'cashinPage:customer_how_to_improve.title',
                  fixedPricingEnabled ? {context: 'fixedPricing'} : {},
                )}
              </Text>
              <UnorderedList>
                <ListItem textStyle="body-small">
                  <Trans
                    i18nKey={`cashinPage:customer_how_to_improve.${customerRating.topReason.key}`}
                  />
                </ListItem>
              </UnorderedList>
            </>
          )}
        </Skeleton>
      </DetailSection>

      <DetailSection title="Invoice">
        <Flex justifyContent="space-between" mb="sm">
          <Box>
            <Text textStyle="body-small" color="grey.700" mb="xxs">
              {t('invoiceDetails.issueDateLabel')}
            </Text>
            <Skeleton isLoaded={!isLoading}>
              <Text textStyle="body-small" color="grey.900" fontWeight="bold">
                {!isLoading &&
                  invoice.issueDate &&
                  toLocalDate(toEpochMillisecond(invoice.issueDate), {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })}
              </Text>
            </Skeleton>
          </Box>
          <Box>
            <Text
              textStyle="body-small"
              color="grey.700"
              textAlign="right"
              mb="xxs"
            >
              {t('invoiceDetails.dueDateLabel')}
            </Text>
            <Skeleton isLoaded={!isLoading}>
              <Text
                textStyle="body-small"
                color="grey.900"
                fontWeight="bold"
                textAlign="right"
              >
                {!isLoading &&
                  invoice.dueDate &&
                  toLocalDate(toEpochMillisecond(invoice.dueDate), {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })}
              </Text>
            </Skeleton>
          </Box>
        </Flex>

        <Flex justifyContent="space-between">
          <Box>
            <Text textStyle="body-small" color="grey.700" mb="xxs">
              {t('invoiceDetails.invoiceNumberLabel')}
            </Text>
            <Skeleton isLoaded={!isLoading}>
              {onInvoicesPage ? (
                <Text textStyle="body-small" fontWeight="bold">
                  {invoice.number}
                </Text>
              ) : (
                <Link
                  href={`/invoices?invoice_id=${invoice.id}`}
                  isExternal
                  color="primary.actionblue"
                  textStyles="body-small"
                  fontWeight="bold"
                >
                  {invoice.number} <ExternalLinkIcon mx="2px" />
                </Link>
              )}
            </Skeleton>
          </Box>
          <Box>
            <Text
              textStyle="body-small"
              color="grey.700"
              textAlign="right"
              mb="xxs"
            >
              {t('invoiceDetails.paymentTermLabel')}
            </Text>
            <Skeleton isLoaded={!isLoading}>
              <Text
                textStyle="body-small"
                color="grey.900"
                fontWeight="bold"
                textAlign="right"
              >
                {paymentTerms} {pluralize('day', paymentTerms)}
              </Text>
            </Skeleton>
          </Box>
        </Flex>
      </DetailSection>

      {showCashinStats && (
        <Flex direction="column" pb="md" gap="md">
          <Flex justify="space-between">
            <Text textStyle="body-small">
              {t('invoiceDetails.cashinLabel')}
            </Text>
            <Skeleton isLoaded={!isLoading}>
              <Box display="flex" alignItems="center">
                {invoice.accelerated && (
                  <Tag variant="primary" colorScheme="primary">
                    <TagLeftIcon
                      boxSize="12px"
                      as={SmallInverseTickedIcon}
                      mr="1"
                    />
                    <TagLabel>
                      {t('invoiceDetails.cashinValue', {
                        context: invoice.accelerated?.toString(),
                      })}
                    </TagLabel>
                  </Tag>
                )}
              </Box>
            </Skeleton>
          </Flex>
          <Flex justify="space-between">
            <Text textStyle="body-small">{t('invoiceDetails.totalLabel')}</Text>
            <Skeleton isLoaded={!isLoading}>
              <Text textStyle="body-small" fontWeight="bold">
                {invoice.total && invoice.total.toFormat()}
              </Text>
            </Skeleton>
          </Flex>
          {invoice.creditNoteApplications &&
            invoice.creditNoteApplications.map((creditNoteApplication) => (
              <Flex key={creditNoteApplication.id} justify="space-between">
                <Flex align="center" gap="xxs">
                  <AltCheckIcon color="secondary.green" />
                  <Text textStyle="body-small" color="grey.700">
                    {t('invoiceDetails.appliedCreditNoteLabel', {
                      creditNoteNumber: creditNoteApplication.creditNoteNumber,
                    })}
                  </Text>
                </Flex>
                <Skeleton isLoaded={!isLoading}>
                  <Text textStyle="body-small" fontWeight="bold">
                    {creditNoteApplication.amount &&
                      creditNoteApplication.amount.toFormat()}
                  </Text>
                </Skeleton>
              </Flex>
            ))}

          {invoice.dueAmount && !invoice.dueAmount.isZero() && (
            <>
              <Divider />
              <Flex justify="space-between">
                <Text textStyle="body-small">
                  {t('invoiceDetails.dueAmountLabel')}
                </Text>
                <Skeleton isLoaded={!isLoading}>
                  <Text textStyle="body-small" fontWeight="bold">
                    {invoice.dueAmount && invoice.dueAmount.toFormat()}
                  </Text>
                </Skeleton>
              </Flex>
            </>
          )}
        </Flex>
      )}
    </>
  )
}

DisplayInvoiceDetails.defaultProps = {
  isLoading: false,
  showCashinStats: false,
  customerRating: undefined,
}

DisplayInvoiceDetails.propTypes = {
  isLoading: PropTypes.bool,
  showCashinStats: PropTypes.bool,
  customerRating: CustomerRatingsPropType,
  invoice: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      customerId: PropTypes.string.isRequired,
      contactName: PropTypes.string.isRequired,
      issueDate: PropTypes.number.isRequired,
      dueDate: PropTypes.number.isRequired,
      accelerated: PropTypes.bool,
      total: DineroPropShape,
      dueAmount: DineroPropShape,
      creditNoteApplications: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          amount: DineroPropShape,
          issueDate: PropTypes.number.isRequired,
          status: PropTypes.string.isRequired,
          creditNoteNumber: PropTypes.string.isRequired,
        }),
      ),
    }),
  ]).isRequired,
}

const InvoiceDetails = ({invoiceId}) => {
  const {organisation} = useRails()
  const {data, isLoading} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/invoices/${invoiceId}`,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )
  const {invoice = {}} = data || {}

  return (
    <DisplayInvoiceDetails
      isLoading={isLoading}
      invoice={invoice}
      showCashinStats
    />
  )
}

InvoiceDetails.propTypes = {
  invoiceId: PropTypes.string.isRequired,
}

export default InvoiceDetails
