import React from 'react'
import useSWR from 'swr'
import {Box, Button, Text} from '@chakra-ui/react'
import {t} from 'i18next'
import {Trans} from 'react-i18next'
import mixpanel from 'mixpanel-browser'
import {camelizeResult, dineroMoneyObjects} from '../../../api/serializer'
import {useRails} from '../../contexts/rails'
import {Alert} from '../../components'

const DEFAULT_ITEMS_SHOWN = 3

const CreditNotesBanner = ({...props}) => {
  const {organisation} = useRails()

  const [dismissed, setDismissed] = React.useState(false)
  const [showingMore, setShowingMore] = React.useState(false)
  const toggleShowingMore = () => {
    setShowingMore(!showingMore)
  }

  const supplierId = organisation.currentSupplier.id

  const {data, isLoading} = useSWR(
    `/api/suppliers/${supplierId}/credit_notes`,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const {creditNotes = []} = data || {}

  React.useEffect(() => {
    if (!isLoading && creditNotes.length > 0 && !dismissed) {
      mixpanel.track('Credit note loaded in supplier invoice screen via banner')
    }
  }, [isLoading, creditNotes.length, dismissed])

  if (isLoading || creditNotes.length === 0 || dismissed) {
    return <Box data-testid="creditnotes-banner" />
  }

  return (
    <Alert
      data-testid="creditnotes-banner"
      isDismissable
      onClose={setDismissed}
      status="info"
      title={t('invoicesPage:creditNotesBanner.recentCreditNotesTitle', {
        count: creditNotes.length,
      })}
      message={
        <Box width="100%" mr="lg" whiteSpace="break-spaces">
          <Box width="auto" pl="md" pb="sm">
            {creditNotes
              .slice(0, showingMore ? creditNotes.length : DEFAULT_ITEMS_SHOWN)
              .map((creditNote) => (
                <Text key={creditNote.id}>
                  {creditNote.creditNoteNumber} ({creditNote.amount.toFormat()})
                </Text>
              ))}

            {!showingMore && creditNotes.length > DEFAULT_ITEMS_SHOWN && (
              <Button variant="secondary" onClick={toggleShowingMore} mt="xs">
                Show more
              </Button>
            )}
          </Box>

          <Text>
            <Trans
              t={t}
              i18nKey="invoicesPage:creditNotesBanner.instructions"
              components={{
                span: <Text as="span" fontWeight="bold" />,
              }}
            />
          </Text>
        </Box>
      }
      {...props}
    />
  )
}

export default CreditNotesBanner
