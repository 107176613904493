/** @jsx jsx */
import React from 'react'
import {jsx} from '@emotion/react'
import PropTypes from 'prop-types'
import {sum, times, random, map, groupBy} from 'lodash-es'
import {AnimatePresence, motion} from 'framer-motion'
import {Skeleton, Grid, GridItem, useTheme, chakra} from '@chakra-ui/react'
import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons'
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'
import {columnsPropType, sortByOptionsPropType} from './propTypes'

const TableStyles = (variant) => {
  return {
    table: {},
    tr: {
      borderBottom: '1px solid',
      borderColor: 'grey.300',
    },
    td: {
      px: variant === 'cashIn' ? '0' : '8',
      py: {base: '4', md: '7'},
      my: 'auto',
      textAlign: 'start',
      fontSize: 'base',
    },
    th: {
      py: '4',
      px: variant === 'cashIn' ? '0' : '8',
      my: 'auto',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      fontSize: 'sm',
      lineHeight: '3',
    },
  }
}

const TableRow = ({data, onClickRow, variant}) => {
  const onClickRowHandler = React.useCallback(() => {
    // Use original data supplied instead of displayed data
    if (onClickRow) onClickRow(data.original)
  }, [data, onClickRow])
  const cells = data.getVisibleCells()
  const unwrappedCells = cells.filter((cell) => !cell.column.columnDef.wrap)
  const gridLength = sum(
    unwrappedCells.map((cell) => cell.column.columnDef.colSpan || 1),
  )

  return (
    <Grid
      as={motion.div}
      role="row"
      templateColumns={`repeat(${gridLength}, 1fr)`}
      layout
      animate="open"
      exit="collapsed"
      variants={{
        open: {opacity: 1, height: 'auto'},
        collapsed: {opacity: 0, height: 0},
      }}
      transition={{duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98]}}
      onClick={onClickRowHandler}
      {...TableStyles(variant).tr}
      _hover={
        Boolean(onClickRow) && {
          backgroundColor: 'neutral.smoke',
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: 'neutral.glitter',
          cursor: 'pointer',
        }
      }
    >
      {cells.map((cell) => {
        // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
        const {meta, colSpan} = cell.column.columnDef
        return (
          <GridItem
            role="cell"
            key={[data.original.id, cell.id].join('_')}
            isNumeric={meta?.isNumeric}
            colSpan={colSpan || 1}
            {...TableStyles(variant).td}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </GridItem>
        )
      })}
    </Grid>
  )
}

TableRow.defaultProps = {
  onClickRow: undefined,
  variant: 'default',
}

TableRow.propTypes = {
  variant: PropTypes.string,
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
      PropTypes.func,
    ]),
  ).isRequired,
  onClickRow: PropTypes.func,
}

const ResponsiveDataTable = ({
  data,
  columns,
  onClickRow,
  isLoading,
  enableSorting,
  onSort,
  sortByValue,
  manualSorting,
  variant,
  groupByValue,
}) => {
  const [sorting, setSorting] = React.useState(sortByValue)

  // Memoize the table columns updating only when the columns themselves change.
  const tableColumns = React.useMemo(() => columns, [columns])
  const columnVisibility = {}
  tableColumns.forEach((column) => {
    columnVisibility[column.accessorKey] = !column.hidden
  })

  const {getHeaderGroups, getFooterGroups, getRowModel} = useReactTable({
    columns: tableColumns,
    data,
    manualSorting,
    enableSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      columnVisibility,
    },
  })

  React.useEffect(() => {
    onSort(sorting)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting])

  const {rows} = getRowModel()

  const groupedRows =
    rows && groupByValue
      ? groupBy(rows, (row) => row.getValue(groupByValue))
      : {}

  const headerGroups = getHeaderGroups()
  const footerGroups = getFooterGroups()

  return (
    <Grid role="table" {...TableStyles(variant).table}>
      <Grid role="rowgroup">
        {headerGroups.map((headerGroup) => {
          const headers = headerGroup.headers.filter(
            (header) => !header.column.columnDef.wrap,
          )
          const gridLength = sum(
            headers.map((header) => header.column.columnDef.colSpan || 1),
          )

          return (
            <Grid
              key={headerGroup.id}
              role="row"
              templateColumns={`repeat(${gridLength}, 1fr)`}
              borderBottom="2px solid"
              {...TableStyles(variant).tr}
            >
              {headers.map((header) => {
                const {column} = header
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const {meta, colSpan} = column.columnDef
                return (
                  <GridItem
                    key={header.id}
                    role="columnheader"
                    colSpan={colSpan || 1}
                    onClick={column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    color={
                      column.getIsSorted() ? 'neutral.black' : 'neutral.60'
                    }
                    _hover={
                      column.getCanSort() && {
                        cursor: 'pointer',
                        color: 'neutral.black',
                      }
                    }
                    {...TableStyles(variant).th}
                  >
                    {flexRender(column.columnDef.header, header.getContext())}
                    {column.getCanSort() && (
                      <chakra.span
                        pl="4"
                        visibility={column.getIsSorted() ? 'visible' : 'hidden'}
                      >
                        {column.getIsSorted() === 'desc' ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )}
                      </chakra.span>
                    )}
                  </GridItem>
                )
              })}
            </Grid>
          )
        })}
      </Grid>

      {isLoading && <LoadingSkeleton columns={columns} variant={variant} />}

      {rows.length > 0 && (
        <Grid role="rowgroup">
          <AnimatePresence>
            {Object.keys(groupedRows).length > 0 && (
              <>
                {map(groupedRows, (groupRows, groupValue) => (
                  <>
                    <Grid
                      role="row"
                      backgroundColor="grey.100"
                      {...TableStyles(variant).tr}
                    >
                      <GridItem
                        role="cell"
                        colSpan={sum(
                          headerGroups.map(
                            (headerGroup) => headerGroup.headers.length,
                          ),
                        )}
                        {...TableStyles(variant).td}
                        py="xs"
                        ml="xs"
                        fontSize="sm"
                        fontWeight="bold"
                        color="neutral.800"
                      >
                        {groupValue}
                      </GridItem>
                    </Grid>
                    {groupRows.map((row) => (
                      <TableRow
                        key={row.original.id || row.id}
                        variant={variant}
                        data={row}
                        onClickRow={onClickRow}
                      />
                    ))}
                  </>
                ))}
              </>
            )}

            {Object.keys(groupedRows).length === 0 && (
              <>
                {rows.map((row) => (
                  <TableRow
                    key={row.original.id || row.id}
                    variant={variant}
                    data={row}
                    onClickRow={onClickRow}
                  />
                ))}
              </>
            )}
          </AnimatePresence>
        </Grid>
      )}

      <Grid role="rowgroup">
        {footerGroups.map(
          (footerGroup) =>
            !!footerGroup.headers.filter(
              (header) => header.column.columnDef.footer,
            ).length && (
              <Grid
                key={footerGroup.id}
                role="row"
                templateColumns={`repeat(${footerGroup.headers.length}, 1fr)`}
                borderBottom="1px solid"
                {...TableStyles(variant).tr}
              >
                {footerGroup.headers.map((header) => (
                  <GridItem
                    key={header.id}
                    role="columnheader"
                    colSpan={header.colSpan}
                    {...TableStyles(variant).td}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </GridItem>
                ))}
              </Grid>
            ),
        )}
      </Grid>
    </Grid>
  )
}

ResponsiveDataTable.defaultProps = {
  data: [],
  onClickRow: undefined,
  onSort: () => {},
  isLoading: false,
  sortByValue: [],
  manualSorting: false,
  enableSorting: false,
  chakraTableProps: {},
  groupByValue: undefined,
  variant: 'default',
}

ResponsiveDataTable.propTypes = {
  variant: PropTypes.string,
  chakraTableProps: PropTypes.shape({
    size: PropTypes.string,
    variant: PropTypes.string,
  }),
  columns: columnsPropType.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
      PropTypes.bool,
      PropTypes.func,
    ]),
  ),
  onClickRow: PropTypes.func,
  isLoading: PropTypes.bool,
  enableSorting: PropTypes.bool,
  onSort: PropTypes.func,
  manualSorting: PropTypes.bool,
  sortByValue: sortByOptionsPropType,
  groupByValue: PropTypes.string,
}

export const LoadingSkeleton = ({rows, columns, variant}) => {
  const theme = useTheme()

  const visibleColumns = columns.filter((column) => !column.hidden)
  const unwrappedColumns = visibleColumns.filter((column) => !column.wrap)
  const gridLength = sum(unwrappedColumns.map((column) => column.colSpan || 1))

  return (
    <Grid role="rowgroup">
      {times(rows, (row) => (
        <Grid
          role="row"
          key={row}
          templateColumns={`repeat(${gridLength}, 1fr)`}
          {...TableStyles(variant).tr}
        >
          {visibleColumns.map((column) => (
            <GridItem
              role="cell"
              key={`${column.accessorKey}-${row}`}
              colSpan={column.colSpan || 1}
              {...TableStyles(variant).td}
            >
              <Skeleton
                borderRadius="md"
                height="24px"
                width={random(0.5, 0.8)}
                startColor={theme.colors.skeleton.light}
                endColor={theme.colors.skeleton.dark}
              />
            </GridItem>
          ))}
        </Grid>
      ))}
    </Grid>
  )
}

LoadingSkeleton.defaultProps = {
  rows: 10,
  variant: 'default',
}

LoadingSkeleton.propTypes = {
  columns: columnsPropType.isRequired,
  rows: PropTypes.number,
  variant: PropTypes.string,
}

export default ResponsiveDataTable
