import React from 'react'
import useSWR, {mutate} from 'swr'
import {Skeleton, Box, Flex, Text, Button} from '@chakra-ui/react'

import {useRails} from '../../../contexts/rails'
import {fetcher} from '../../../../api'
import BankAccountSettingDrawer from './BankAccountSettingDrawer'
import ExpenseAccountSettingDrawer from './ExpenseAccountSettingDrawer'

const AccountingProviderSettings = () => {
  const {accountingProvider} = useRails()
  const [showBankAccountSettings, setShowBankAccountSettings] =
    React.useState(false)
  const [showExpenseAccountSettings, setShowExpenseAccountSettings] =
    React.useState(false)

  const {data: response, error} = useSWR('/api/suppliers/settings', fetcher, {
    refreshInterval: 0,
  })

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/supplier_logins/sign_in'
  }

  if (error && error.message !== 'Unauthorized') {
    return (
      <Box width="100%">
        <Text fontSize="sm" mb="sm" color="primary.errorred">
          Fail to retrieve supplier&apos;s {accountingProvider.name} settings.
        </Text>
      </Box>
    )
  }

  if (!response && !error) {
    return (
      <Box width="100%">
        {Array.from({length: 3}).map((_, i) => (
          <Skeleton key={`skeleton-${i + 1}`} height="20px" my="sm" />
        ))}
      </Box>
    )
  }

  const {
    expense_account_id: expenseAccountId,
    expense_account_name: expenseAccountName,
    expense_account_code: expenseAccountCode,
    bank_account_number: bankAccountNumber,
  } = response.data.xero

  const expenseAccountLabel = expenseAccountCode
    ? `${expenseAccountName} (${expenseAccountCode})`
    : expenseAccountName

  return (
    response && (
      <>
        <Flex align="center" mb="sm" minHeight="40px">
          <Box width="320px">
            <Text>Bank account for remittance</Text>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="calc(100% - 320px)"
          >
            <Text fontWeight="bold">{bankAccountNumber}</Text>
            <Button
              variant="secondary"
              onClick={() => setShowBankAccountSettings(true)}
            >
              Change
            </Button>
          </Box>
        </Flex>
        <Flex align="center" mb="sm" minHeight="40px">
          <Box width="320px">
            <Text>Expense account for fees</Text>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="calc(100% - 320px)"
          >
            <Text fontWeight="bold">{expenseAccountLabel}</Text>
            <Button
              variant="secondary"
              onClick={() => setShowExpenseAccountSettings(true)}
            >
              Change
            </Button>
          </Box>
        </Flex>
        <BankAccountSettingDrawer
          displayDrawer={showBankAccountSettings}
          onClickCancel={() => setShowBankAccountSettings(false)}
        />
        <ExpenseAccountSettingDrawer
          expenseAccountName={expenseAccountName}
          expenseAccountCode={expenseAccountCode}
          expenseAccountId={expenseAccountId}
          displayDrawer={showExpenseAccountSettings}
          onClickCancel={() => setShowExpenseAccountSettings(false)}
          onClickSave={() => {
            mutate('/api/suppliers/settings')
            setShowExpenseAccountSettings(false)
          }}
        />
      </>
    )
  )
}

export default AccountingProviderSettings
