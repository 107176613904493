import React from 'react'
import PropTypes from 'prop-types'
import {
  Image,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  CloseButton,
} from '@chakra-ui/react'
import modalImg from '../../../../../assets/images/modal_image.png'

const OverdueInvoicesModal = ({allowClose, disableOverdueInvoicesModal}) => {
  const {isOpen, onClose: closeModal} = useDisclosure({defaultIsOpen: true})

  const onClose = () => {
    if (allowClose) {
      closeModal()
      disableOverdueInvoicesModal()
    }
  }
  const showInvoices = () => {
    window.location.href =
      '/invoices?days_past_due_date=33&cashin_status=true&payment_status=unpaid'
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
      <ModalOverlay zIndex="600" />
      <ModalContent
        borderRadius="lg"
        zIndex="600"
        containerProps={{zIndex: '600'}}
      >
        <ModalHeader fontSize="base" p="0">
          {allowClose && (
            <CloseButton
              position="absolute"
              top="0"
              right="0"
              mt="xs"
              mx="sm"
              onClick={onClose}
            />
          )}
        </ModalHeader>
        <ModalBody px="lg" py="md">
          <Image
            borderTopRadius="lg"
            width="100%"
            src={modalImg}
            alt="Overdue invoice example"
            pb="sm"
          />
          <Text as="h3" textStyle="headline3" fontWeight="bold" pb="md">
            Please provide some additional information on your cashed-in
            invoices
          </Text>
          <Text textStyle="body-copy" pb="md">
            Help us understand why your customers are not paying on time so we
            can work towards a quick resolution.
          </Text>
          <Button variant="primary" onClick={showInvoices}>
            View overdue cashed-in invoices
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

OverdueInvoicesModal.defaultProps = {
  disableOverdueInvoicesModal: () => {},
}

OverdueInvoicesModal.propTypes = {
  disableOverdueInvoicesModal: PropTypes.func,
  allowClose: PropTypes.bool.isRequired,
}

export default OverdueInvoicesModal
