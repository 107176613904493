import React from 'react'
import propTypes from 'prop-types'
import {Box} from '@chakra-ui/react'

const LogoSymbol = ({width, height, ...props}) => (
  <Box
    as="svg"
    width={width}
    height={height}
    viewBox="0 0 188 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#1A1A1A"
      d="M57.712 144.046c0 1.542-1.081 2.792-2.415 2.792H3.383c-1.334 0-2.415-1.25-2.415-2.792v-7.368c0-1.251.721-2.342 1.751-2.723 22.292-8.218 38.437-32.365 38.437-60.873 0-28.509-16.145-52.655-38.437-60.874-1.03-.38-1.75-1.472-1.75-2.722v-6.48c0-1.542 1.08-2.792 2.414-2.792h51.914c1.334 0 2.415 1.25 2.415 2.793v141.039Zm65.072 0c0 1.542-1.081 2.792-2.415 2.792H68.455c-1.334 0-2.415-1.25-2.415-2.792v-7.368c0-1.251.721-2.342 1.751-2.723 22.292-8.218 38.437-32.365 38.437-60.873 0-28.509-16.145-52.655-38.437-60.874-1.03-.38-1.75-1.472-1.75-2.722v-6.48c0-1.542 1.08-2.792 2.414-2.792h51.914c1.334 0 2.415 1.25 2.415 2.793v141.039Zm62.646 2.792c1.334 0 2.415-1.25 2.415-2.792V3.006c0-1.542-1.081-2.792-2.415-2.792h-51.914c-1.333 0-2.414 1.25-2.414 2.793v6.479c0 1.25.721 2.342 1.75 2.722 22.292 8.219 38.437 32.365 38.437 60.874 0 28.508-16.145 52.655-38.437 60.873-1.029.381-1.75 1.472-1.75 2.723v7.368c0 1.542 1.081 2.792 2.414 2.792h51.914Z"
    />
  </Box>
)

LogoSymbol.defaultProps = {
  width: 188,
  height: 148,
}

LogoSymbol.propTypes = {
  width: propTypes.number,
  height: propTypes.number,
}

export default LogoSymbol
