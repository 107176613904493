/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import mixpanel from 'mixpanel-browser'
import {jsx, css} from '@emotion/react'
import {
  Box,
  Button,
  ButtonGroup,
  CheckboxGroup,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import {Range} from 'rc-slider'
import Checkbox from '../../components/Checkbox'
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-slider/assets/index.css'

import {Input} from '../../components'
import CustomerFilter from '../../components/CustomerFilter'
import InvoiceFilter from '../../components/InvoiceFilter'
import {toCurrency} from '../../../utils/formatter'

const PaymentFilter = ({
  setIsFilterDrawerOpen,
  filterOption,
  setFilterOption,
  minAmountRange,
  maxAmountRange,
}) => {
  const theme = useTheme()
  const [selectedCustomer, setSelectedCustomer] = React.useState(
    filterOption.customer,
  )
  const [selectedInvoice, setSelectedInvoice] = React.useState(
    filterOption.invoice,
  )
  const [centsFrom, setCentsFrom] = React.useState(filterOption.centsFrom || 0)
  const [centsTo, setCentsTo] = React.useState(filterOption.centsTo || 0)
  const [dateFrom, setDateFrom] = React.useState(filterOption.dateFrom || 0)
  const [dateTo, setDateTo] = React.useState(filterOption.dateTo || 0)
  const [paymentReceived, setPaymentReceived] = React.useState(
    filterOption.paymentReceived || false,
  )
  const [paymentMatchedToInvoice, setPaymentMatchedToInvoice] = React.useState(
    filterOption.paymentMatchedToInvoice || false,
  )
  const [paymentNotMatchedToInvoice, setPaymentNotMatchedToInvoice] =
    React.useState(filterOption.paymentNotMatchedToInvoice || false)
  const [paidOutToYourAccount, setPaidOutToYourAccount] = React.useState(
    filterOption.paidOutToYourAccount || false,
  )

  const handleClickApplyFilter = (values) => {
    const filterOptionState = {}

    Object.keys(values).forEach((param) => {
      if (values[param]) {
        filterOptionState[param] = values[param]
      }
    })

    setIsFilterDrawerOpen(false)
    setFilterOption(filterOptionState)
    mixpanel.track('Filter applied: Payments page', {
      filters: Object.keys(filterOptionState),
    })
  }

  const handleClickResetFilter = () => {
    setIsFilterDrawerOpen(false)
    setFilterOption({})
  }

  return (
    <>
      <Box width="100%" mb="sm">
        <CustomerFilter
          value={selectedCustomer}
          onChange={setSelectedCustomer}
        />
      </Box>
      <Box width="100%" mb="sm">
        <InvoiceFilter value={selectedInvoice} onChange={setSelectedInvoice} />
      </Box>
      <Text mb="sm" color="neutral.80" fontWeight="bold">
        Date range
      </Text>
      <Box
        width="100%"
        css={css`
          .selected-date {
            background-color: ${theme.colors.primary.actionblue};
            color: ${theme.colors.neutral.white};
          }
        `}
        display="flex"
        justifyContent="space-between"
        data-testid="filter-date-range"
      >
        <DatePicker
          selected={dateFrom && new Date(dateFrom)}
          customInput={<Input width="100%" />}
          onChange={(value) => {
            setDateFrom((value && value.getTime()) || 0)
          }}
          dateFormat="dd/MM/yyyy"
          placeholderText="Date from"
          dayClassName={(date) =>
            date.getTime() === dateFrom ? 'selected-date' : ''
          }
          maxDate={dateTo && new Date(dateTo)}
        />
        <DatePicker
          selected={dateTo && new Date(dateTo)}
          customInput={<Input width="100%" />}
          onChange={(value) => setDateTo((value && value.getTime()) || 0)}
          dateFormat="dd/MM/yyyy"
          placeholderText="Date to"
          dayClassName={(date) =>
            date.getTime() === dateTo ? 'selected-date' : ''
          }
          minDate={dateFrom && new Date(dateFrom)}
        />
      </Box>
      <Text mb="sm" color="neutral.80" fontWeight="bold">
        Amount
      </Text>
      <Box
        display="flex"
        justifyContent={{base: 'flex-start', sm: 'space-between'}}
        flexDirection={{base: 'column', sm: 'row'}}
      >
        <Box maxWidth={{base: '100%', sm: '150px'}}>
          <Input
            name="minInvoiceAmount"
            placeholder="Min amount"
            value={toCurrency(centsFrom)}
            onChange={(event) => {
              const formatToCents = parseInt(
                event.target.value.replace(/\D/g, ''),
                10,
              )

              if (!Number.isNaN(formatToCents)) {
                setCentsFrom(formatToCents)
              }
            }}
          />
        </Box>
        <Box maxWidth={{base: '100%', sm: '150px'}}>
          <Input
            name="maxInvoiceAmount"
            placeholder="Max amount"
            value={toCurrency(centsTo)}
            onChange={(event) => {
              const formatToCents = parseInt(
                event.target.value.replace(/\D/g, ''),
                10,
              )

              if (!Number.isNaN(formatToCents)) {
                setCentsTo(formatToCents)
              }
            }}
          />
        </Box>
      </Box>
      <Box width="100%" mb="md">
        <Range
          trackStyle={[{backgroundColor: theme.colors.primary.actionblue}]}
          railStyle={{backgroundColor: theme.colors.neutral.glitter}}
          handleStyle={[
            {
              border: `2px solid ${theme.colors.primary.actionblue}`,
              boxShadow: 'none',
            },
            {
              border: `2px solid ${theme.colors.primary.actionblue}`,
              boxShadow: 'none',
            },
          ]}
          min={minAmountRange}
          max={maxAmountRange}
          value={[centsFrom, centsTo]}
          allowCross={false}
          onChange={(value) => {
            const [amountFrom, amountTo] = value
            setCentsFrom(amountFrom)
            setCentsTo(amountTo)
          }}
        />
      </Box>
      <Text mb="sm" color="neutral.80" fontWeight="bold">
        Status
      </Text>
      <CheckboxGroup
        name="paymentStatus"
        onChange={(values) => {
          setPaymentReceived(values.includes('paymentReceived'))
          setPaymentMatchedToInvoice(values.includes('paymentMatchedToInvoice'))
          setPaymentNotMatchedToInvoice(
            values.includes('paymentNotMatchedToInvoice'),
          )
          setPaidOutToYourAccount(values.includes('paidOutToYourAccount'))
        }}
        defaultValue={[
          paymentReceived ? 'paymentReceived' : '',
          paymentMatchedToInvoice ? 'paymentMatchedToInvoice' : '',
          paymentNotMatchedToInvoice ? 'paymentNotMatchedToInvoice' : '',
          paidOutToYourAccount ? 'paidOutToYourAccount' : '',
        ]}
      >
        <Stack mb="md" data-testid="filter-payment-status">
          <Checkbox value="paymentReceived">
            <Text>Payment received</Text>
          </Checkbox>
          <Checkbox value="paymentMatchedToInvoice">
            <Text>Payment matched to invoice(s)</Text>
          </Checkbox>
          <Checkbox value="paymentNotMatchedToInvoice">
            <Text>Payment not matched to invoice(s)</Text>
          </Checkbox>
          <Checkbox value="paidOutToYourAccount">
            <Text>Paid out to your account</Text>
          </Checkbox>
        </Stack>
      </CheckboxGroup>
      <ButtonGroup spacing="sm" mt="md" mb="sm">
        <Button
          variant="primary"
          onClick={() => {
            handleClickApplyFilter({
              customer: selectedCustomer,
              invoice: selectedInvoice,
              dateFrom,
              dateTo,
              centsFrom,
              centsTo,
              paymentReceived,
              paymentMatchedToInvoice,
              paymentNotMatchedToInvoice,
              paidOutToYourAccount,
            })
          }}
        >
          Apply
        </Button>
        <Button variant="secondary" onClick={handleClickResetFilter}>
          Reset
        </Button>
      </ButtonGroup>
    </>
  )
}

PaymentFilter.defaultProps = {
  setIsFilterDrawerOpen: () => {},
  setFilterOption: () => {},
  filterOption: {},
  minAmountRange: 0,
  maxAmountRange: 1_000_000_00,
}

PaymentFilter.propTypes = {
  setIsFilterDrawerOpen: PropTypes.func,
  setFilterOption: PropTypes.func,
  filterOption: PropTypes.shape({
    customer: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    invoice: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    centsFrom: PropTypes.number,
    centsTo: PropTypes.number,
    dateFrom: PropTypes.number,
    dateTo: PropTypes.number,
    paymentReceived: PropTypes.bool,
    paymentMatchedToInvoice: PropTypes.bool,
    paymentNotMatchedToInvoice: PropTypes.bool,
    paidOutToYourAccount: PropTypes.bool,
  }),
  minAmountRange: PropTypes.number,
  maxAmountRange: PropTypes.number,
}

export default PaymentFilter
