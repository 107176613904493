import React from 'react'
import {Flex, Text, Image, Box} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import unsupportedImage from '../../../../../../../assets/images/unsupported.svg'

const Unsupported = () => {
  const {t} = useTranslation('onboarding')

  return (
    <Flex minH="100%" align="center" justify="center" flexDirection="column">
      <Box minW="90px" minH="90px" mb="sm">
        <Image
          src={unsupportedImage}
          minW="90px"
          minH="90px"
          height="20vh"
          maxWidth="270px"
          maxHeight="270px"
        />
      </Box>
      {/* <Image src={unsupportedImage} mb="sm" /> */}
      <Text maxWidth="450px" mx="auto" textStyle="headline2" textAlign="center">
        {t('onboarding:AcquisitionDecisionUnsupported.heading')}
      </Text>

      <Text maxWidth="450px" textStyle="body-intro" mt="md" textAlign="center">
        {t('onboarding:AcquisitionDecisionUnsupported.body')}
      </Text>
    </Flex>
  )
}

export default Unsupported
